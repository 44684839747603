export const makeInputUpperCase = (keyword, event) => {
	const input = keyword.current
	const start = input.selectionStart
	const end = input.selectionEnd
	setTimeout(() => {
		input.setSelectionRange(start, end)
	}, 0)
	return event.target.value.toUpperCase()
}

export const reviewsPriority = {
	3: '',
	2: 'urgentReviewsCompleted',
	1: 'mediumReviewsCompleted',
	0: ''
}

// Function to get 'action_id' from URL

export const getActionIdFromURL = () => {
	const params = new URLSearchParams(window.location.search)
	return params.get('action_id')
}

export function base64ToFile(base64String, fileName) {
	const [contentType, base64EncodedData] = base64String.split(';base64,')
	const decodedData = atob(base64EncodedData)
	const arrayBuffer = new ArrayBuffer(decodedData.length)
	const uint8Array = new Uint8Array(arrayBuffer)
	for (let i = 0; i < decodedData.length; i++) {
		uint8Array[i] = decodedData.charCodeAt(i)
	}
	const blob = new Blob([uint8Array], { type: contentType })
	const file = new File([blob], fileName, { type: contentType })
	return file
}

export function getCookie(name) {
	let cookieArray = document.cookie.split(';')
	for (let i = 0; i < cookieArray.length; i++) {
		let cookiePair = cookieArray[i].split('=')
		if (name === cookiePair[0].trim()) {
			return decodeURIComponent(cookiePair[1])
		}
	}
	return null
}

export function consoleLog(message, optionalVar) {
	// Check if logging is enabled based on the environment variable
	if (process.env.REACT_APP_IS_LOGGING_ON === 'true') {
		// Log both message and optionalVar if optionalVar is provided
		if (typeof optionalVar !== 'undefined') {
			console.log(message, optionalVar)
		} else {
			// Log only the message if optionalVar is not provided
			console.log(message)
		}
	}
}
