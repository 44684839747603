import styled from '@emotion/styled/macro'

export const LoginFooterLandingCon = styled.div`
	background-image: url(${(props) => props.background});
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	height: 95px;
	// position: fixed;
	color: white;
	bottom: 0px;
	z-index: 100;

	.links-container {
		display: flex;
		padding-top: 0.886%;
		width: 100%;
		justify-content: center;
		padding-left: 1.5%;
	}

	.links-container {
		color: white;
	}
	a.link-item,
	button.link-item {
		color: white;
		text-decoration: none;
	}
	.login-copyrights {
		justify-content: center;
		padding-top: 0.886%;
	}

	.socialContainer {
		position: 'absolute';
		paddingtop: '2%';
		paddingleft: '9%';
		align-items: center;
	}
	.social-icons {
		display: flex;
		justify-content: center;
	}
	.social-icons .twitter-icon {
		margin: 4px 15px 0px 20px;
	}
	.social-icons .linkedin-icon {
		font-size: 22px;
	}
	@media screen and (min-width: 1024px) {
		.social-icons .twitter-icon {
			width: 27px;
		}
		.social-icons .fb-icon {
			width: 13px;
		}
		.social-icons .linkedin-icon {
			font-size: 29px;
		}
	}

	.cursor {
		cursor: pointer;
	}
`
