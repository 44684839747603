import { Fragment, useEffect, useState } from 'react'
import Accordion from '@mui/material/Accordion'
// import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import { QnaAccordionContainer } from './qnaAccordionSkin'
import useDeleteNote from '../../hooks/useDeleteNote'
import useAuth from 'hooks/useAuth'
import { useQuery } from 'react-query'
import { getQALeft } from 'api/qna'

const QnaAccordion = ({
	QAExpBookTitle,
	nQAexpBook,
	expanded,
	setExpanded,
	open,
	indexChange
}) => {
	const {
		auth: { token }
	} = useAuth()
	const { noteDeted } = useDeleteNote()

	const { data, refetch } = useQuery('qna-list', () => getQALeft(token), {
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		retry: false
	})

	const [nQAexpBook1, setNQAexpBook] = useState(data)

	useEffect(() => {
		if (noteDeted) {
			refetch()
			setNQAexpBook(data)
		} else {
		}
	}, [noteDeted, refetch, data])

	return (
		<Fragment>
			{QAExpBookTitle.map((book, index) => (
				<QnaAccordionContainer key={index}>
					<Accordion
						className="accordion"
						sx={{
							m: 0,
							flexDirection: 'column',
							background: `${index === expanded ? '#D9E0F2' : 'transparent'}`,
							color: `${index === expanded ? '#000' : 'white'}`,
							border: 'none',
							boxShadow: 'none'
						}}
						key={index}
						disableGutters={true}
						onClick={() => setExpanded(index)}
					>
						<AccordionSummary
							sx={{
								flex: 1,
								padding: '0 3.8%',
								margin: 'unset',
								background: `${
									index === expanded ? '#D9E0F2 !important' : 'transparent'
								}`
							}}
							className="accordion_summary"
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<Typography className="h8 book_title">
								{book} ({nQAexpBook1.nQAexpBook[index]})
							</Typography>
						</AccordionSummary>

						{/* {expanded === index && open && (
              <AccordionDetails
                sx={{
                  background: '#374254',
                  color: 'white',
                }}
              >
                <ul>
                  <li className="h9">0. Introduction</li>
                  <li className="h9">1. Sorpen</li>
                  <li className="h9">2. The hand of God 137</li>
                  <li className="h9">3. One</li>
                  <li className="h9">4. Two</li>
                  <li className="h9">5. Three</li>
                  <li className="h9">6. Four</li>
                  <li className="h9">7. Five</li>
                  <li className="h9">8. Six</li>
                </ul>
              </AccordionDetails>
            )} */}
					</Accordion>
				</QnaAccordionContainer>
			))}
		</Fragment>
	)
}

export default QnaAccordion
