import { useParams } from 'react-router-dom'

import CreateNewPssword from '../../components/CreateNewPassword'
import LoginFooter from '../../components/LoginFooter'

const ResetPasswordPage = () => {
	const { id, token } = useParams()

	return (
		<div
			style={{
				minHeight: '100vh',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between'
			}}
			data-testid="reset-password-container"
		>
			<CreateNewPssword id={id} token={token} />
			<LoginFooter />
		</div>
	)
}

export default ResetPasswordPage
