import styled from '@emotion/styled/macro'

// App Skin Changed

export const AppContainer = styled.div`
	/* font weights (not depending on media queries) */
	/*font-family: "Segoe UI"!important;*/
	.h1,
	.h2,
	.h4,
	.h5,
	.h6,
	.h8b,
	.h10 {
		font-weight: bold;
	}
	.h3,
	.h7,
	.h8,
	.h9,
	.h11 {
		font-weight: normal;
	}

	@media screen and (min-width: 1920px) {
		.h1 {
			font-size: 72px;
			font-weight: bold;
		}
		.h2 {
			font-size: 60px;
		}
		.h3 {
			font-size: 60px;
		}
		.h4 {
			font-size: 35px;
		}
		.h5 {
			font-size: 32px;
		}
		.h6 {
			font-size: 25px;
		}
		.h7 {
			font-size: 25px;
		}
		.h8,
		.h8b {
			font-size: 21px;
		}
		.h9 {
			font-size: 18px;
		}
		.h10 {
			font-size: 16px;
		}
		.h11 {
			font-size: 16px;
		}
	}

	@media screen and (max-width: 1919px) {
		.h1 {
			font-size: 63px;
			font-weight: bold;
		}
		.h2 {
			font-size: 52px;
		}
		.h3 {
			font-size: 52px;
		}
		.h4 {
			font-size: 30px;
		}
		.h5 {
			font-size: 28px;
		}
		.h6 {
			font-size: 21px;
		}
		.h7 {
			font-size: 21px;
		}
		.h8,
		.h8b {
			font-size: 18px;
		}
		.h9 {
			font-size: 15px;
		}
		.h10 {
			font-size: 14px;
		}
		.h11 {
			font-size: 14px;
		}
	}

	@media screen and (max-width: 1679px) {
		.h1 {
			font-size: 60px;
		}
		.h2 {
			font-size: 50px;
		}
		.h3 {
			font-size: 50px;
		}
		.h4 {
			font-size: 29px;
		}
		.h5 {
			font-size: 26px;
		}
		.h6 {
			font-size: 20px;
		}
		.h7 {
			font-size: 20px;
		}
		.h8,
		.h8b {
			font-size: 17px;
		}
		.h9 {
			font-size: 15px;
		}
		.h10 {
			font-size: 13px;
		}
		.h11 {
			font-size: 13px;
		}
	}

	/*@media screen and (max-width: 1599px) and (max-height: 900px)*/
	@media screen and (max-width: 1599px) {
		.h1 {
			font-size: 54px;
		}
		.h2 {
			font-size: 45px;
		}
		.h3 {
			font-size: 45px;
		}
		.h4 {
			font-size: 26px;
		}
		.h5 {
			font-size: 24px;
		}
		.h6 {
			font-size: 18px;
		}
		.h7 {
			font-size: 18px;
		}
		.h8,
		.h8b {
			font-size: 15px;
		}
		.h9 {
			font-size: 13px;
		}
		.h10 {
			font-size: 12px;
		}
		.h11 {
			font-size: 12px;
		}
	}

	@media screen and (max-width: 1439px) {
		.h1 {
			font-size: 52px;
		}
		.h2 {
			font-size: 43px;
		}
		.h3 {
			font-size: 43px;
		}
		.h4 {
			font-size: 25px;
		}
		.h5 {
			font-size: 23px;
		}
		.h6 {
			font-size: 18px;
		}
		.h7 {
			font-size: 18px;
		}
		.h8,
		.h8b {
			font-size: 15px;
		}
		.h9 {
			font-size: 13px;
		}
		.h10 {
			font-size: 11px;
		}
		.h11 {
			font-size: 11px;
		}
	}

	/*@media screen and (max-width: 1440px) and (max-height: 1050px)*/
	@media screen and (max-width: 1399px) {
		.h1 {
			font-size: 51px;
		}
		.h2 {
			font-size: 42px;
		}
		.h3 {
			font-size: 42px;
		}
		.h4 {
			font-size: 24px;
		}
		.h5 {
			font-size: 22px;
		}
		.h6 {
			font-size: 17px;
		}
		.h7 {
			font-size: 17px;
		}
		.h8,
		.h8b {
			font-size: 14px;
		}
		.h9 {
			font-size: 12px;
		}
		.h10 {
			font-size: 11px;
		}
		.h11 {
			font-size: 11px;
		}
	}

	@media screen and (max-width: 1365px) {
		.h1 {
			font-size: 51px;
		}
		.h2 {
			font-size: 42px;
		}
		.h3 {
			font-size: 42px;
		}
		.h4 {
			font-size: 24px;
		}
		.h5 {
			font-size: 22px;
		}
		.h6 {
			font-size: 17px;
		}
		.h7 {
			font-size: 17px;
		}
		.h8,
		.h8b {
			font-size: 14px;
		}
		.h9 {
			font-size: 12px;
		}
		.h10 {
			font-size: 11px;
		}
		.h11 {
			font-size: 11px;
		}
	}

	/*@media screen and (max-width: 1360px) and (max-height: 768px)*/
	@media screen and (max-width: 1359px) {
		.h1 {
			font-size: 48px;
		}
		.h2 {
			font-size: 40px;
		}
		.h3 {
			font-size: 40px;
		}
		.h4 {
			font-size: 23px;
		}
		.h5 {
			font-size: 21px;
		}
		.h6 {
			font-size: 16px;
		}
		.h7 {
			font-size: 16px;
		}
		.h8,
		.h8b {
			font-size: 14px;
		}
		.h9 {
			font-size: 12px;
		}
		.h10 {
			font-size: 10px;
		}
		.h11 {
			font-size: 10px;
		}
	}

	@media screen and (max-width: 1279px) {
		.h1 {
			font-size: 43px;
		}
		.h2 {
			font-size: 36px;
		}
		.h3 {
			font-size: 36px;
		}
		.h4 {
			font-size: 21px;
		}
		.h5 {
			font-size: 19px;
		}
		.h6 {
			font-size: 15px;
		}
		.h7 {
			font-size: 15px;
		}
		.h8,
		.h8b {
			font-size: 12px;
		}
		.h9 {
			font-size: 10px;
		}
		.h10 {
			font-size: 9px;
		}
		.h11 {
			font-size: 9px;
		}
	}

	@media screen and (max-width: 1151px) {
		.h1 {
			font-size: 38px;
		}
		.h2 {
			font-size: 32px;
		}
		.h3 {
			font-size: 32px;
		}
		.h4 {
			font-size: 18px;
		}
		.h5 {
			font-size: 17px;
		}
		.h6 {
			font-size: 13px;
		}
		.h7 {
			font-size: 13px;
		}
		.h8,
		.h8b {
			font-size: 11px;
		}
		.h9 {
			font-size: 9px;
		}
		.h10 {
			font-size: 8px;
		}
		.h11 {
			font-size: 8px;
		}
	}

	@media screen and (max-width: 1023px) {
		.h1 {
			font-size: 30px;
		}
		.h2 {
			font-size: 25px;
		}
		.h3 {
			font-size: 25px;
		}
		.h4 {
			font-size: 14px;
		}
		.h5 {
			font-size: 13px;
		}
		.h6 {
			font-size: 10px;
		}
		.h7 {
			font-size: 10px;
		}
		.h8,
		.h8b {
			font-size: 8px;
		}
		.h9 {
			font-size: 7px;
		}
		.h10 {
			font-size: 6px;
		}
		.h11 {
			font-size: 6px;
		}

		.navbar-carousel .react-multi-carousel-dot-list {
			bottom: 5px !important;
		}

		.navbar-carousel .react-multi-carousel-dot button {
			width: 9px;
			height: 9px;
		}
	}

	input {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.Mui-focused {
		border-color: black !important;
	}
	@media (max-width: 570px) {
		.MuiGrid-root.MuiGrid-container.login-container.loginP.css-11lq3yg-MuiGrid-root {
			padding-top: 12vh !important;
		}
		.sc-ksZaOG.igKkHh {
			padding-top: 22vh !important;
		}
		.MuiGrid-root.MuiGrid-container.login-container.css-11lq3yg-MuiGrid-root {
			padding-top: 25vh !important;
		}
		.MuiGrid-root.MuiGrid-container.create_container.css-11lq3yg-MuiGrid-root {
			padding-top: 22vh !important;
		}
		.sc-papXJ.eUzFUH {
			padding-top: 12vh !important;
		}
		.sc-ksZaOG.iMCiLN {
			padding-top: 25vh !important;
		}
		.iFBLFj .login-container {
			padding-top: 25vh !important;
		}

		.khxnHw .create_container {
			padding-top: 22vh !important;
		}
		.cjNrWr .signup-container {
			padding-top: 12vh !important;
		}
		.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-6.library.css-rpybyc {
			padding-left: 0 !important;
		}
		.css-19h33k8-MuiGrid-root.forgot {
			transform: translateY(15vh);
		}
	}
`

export const h1FontSize = `
  	@media screen and (min-width: 1920px) {
    font-size: 72px;
  }
    
  @media screen and (max-width: 1919px) {
    font-size: 63px;
   }
    
  @media screen and (max-width: 1679px) {
    font-size: 60px;
  }
  
  @media screen and (max-width: 1599px) {
    font-size: 54px;
  }
  
  @media screen and (max-width: 1439px) {
    font-size: 52px;
  }
  
  @media screen and (max-width: 1399px){
    font-size: 51px;
  }
  
  @media screen and (max-width: 1365px) {
    font-size: 51px;
  }
  
  @media screen and (max-width: 1359px) {
    font-size: 48px;
  }
  
  @media screen and (max-width: 1279px) {
    font-size: 43px;
  }
  
  @media screen and (max-width: 1151px) {
    font-size: 38px;
  }
  
  @media screen and (max-width: 1023px) {
    font-size: 30px;
  }
  `

export const h2FontSize = `
    @media screen and (min-width: 1920px) {
    font-size: 60px;
  }
    
  @media screen and (max-width: 1919px) {
    font-size: 52px;
   }
    
  @media screen and (max-width: 1679px) {
    font-size: 50px;
  }
  
  @media screen and (max-width: 1599px) {
    font-size: 45px;
  }
  
  @media screen and (max-width: 1439px) {
    font-size: 43px;
  }
  
  @media screen and (max-width: 1399px){
    font-size: 42px;
  }
  
  @media screen and (max-width: 1365px) {
    font-size: 42px;
  }
  
  @media screen and (max-width: 1359px) {
    font-size: 40px;
  }
  
  @media screen and (max-width: 1279px) {
    font-size: 36px;
  }
  
  @media screen and (max-width: 1151px) {
    font-size: 32px;
  }
  
  @media screen and (max-width: 1023px) {
    font-size: 25px;
  }
  `

export const h3_1FontSize = `
    @media screen and (min-width: 1920px) {
    font-size: 54px;
  }
    
  @media screen and (max-width: 1919px) {
    font-size: 47px;
   }
    
  @media screen and (max-width: 1679px) {
    font-size: 45px;
  }
  
  @media screen and (max-width: 1599px) {
    font-size: 40px;
  }
  
  @media screen and (max-width: 1439px) {
    font-size: 39px;
  }
  
  @media screen and (max-width: 1399px){
    font-size: 38px;
  }
  
  @media screen and (max-width: 1365px) {
    font-size: 38px;
  }
  
  @media screen and (max-width: 1359px) {
    font-size: 36px;
  }
  
  @media screen and (max-width: 1279px) {
    font-size: 32px;
  }
  
  @media screen and (max-width: 1151px) {
    font-size: 28px;
  }
  
  @media screen and (max-width: 1023px) {
    font-size: 22px;
  }
  `

export const h3_2FontSize = `
    @media screen and (min-width: 1920px) {
    font-size: 48px;
  }
    
  @media screen and (max-width: 1919px) {
    font-size: 42px;
   }
    
  @media screen and (max-width: 1679px) {
    font-size: 40px;
  }
  
  @media screen and (max-width: 1599px) {
    font-size: 36px;
  }
  
  @media screen and (max-width: 1439px) {
    font-size: 35px;
  }
  
  @media screen and (max-width: 1399px){
    font-size: 34px;
  }
  
  @media screen and (max-width: 1365px) {
    font-size: 34px;
  }
  
  @media screen and (max-width: 1359px) {
    font-size: 32px;
  }
  
  @media screen and (max-width: 1279px) {
    font-size: 28px;
  }
  
  @media screen and (max-width: 1151px) {
    font-size: 25px;
  }
  
  @media screen and (max-width: 1023px) {
    font-size: 20px;
  }
  `

export const h3_3FontSize = `
    @media screen and (min-width: 1920px) {
    font-size: 42px;
  }
    
  @media screen and (max-width: 1919px) {
    font-size: 36px;
   }
    
  @media screen and (max-width: 1679px) {
    font-size: 35px;
  }
  
  @media screen and (max-width: 1599px) {
    font-size: 31px;
  }
  
  @media screen and (max-width: 1439px) {
    font-size: 30px;
  }
  
  @media screen and (max-width: 1399px){
    font-size: 29px;
  }
  
  @media screen and (max-width: 1365px) {
    font-size: 29px;
  }
  
  @media screen and (max-width: 1359px) {
    font-size: 28px;
  }
  
  @media screen and (max-width: 1279px) {
    font-size: 25px;
  }
  
  @media screen and (max-width: 1151px) {
    font-size: 22px;
  }
  
  @media screen and (max-width: 1023px) {
    font-size: 17px;
  }
  `

export const h4FontSize = `
    @media screen and (min-width: 1920px) {
    font-size: 35px;
  }
    
  @media screen and (max-width: 1919px) {
    font-size: 30px;
   }
    
  @media screen and (max-width: 1679px) {
    font-size: 29px;
  }
  
  @media screen and (max-width: 1599px) {
    font-size: 26px;
  }
  
  @media screen and (max-width: 1439px) {
    font-size: 25px;
  }
  
  @media screen and (max-width: 1399px){
    font-size: 24px;
  }
  
  @media screen and (max-width: 1365px) {
    font-size: 24px;
  }
  
  @media screen and (max-width: 1359px) {
    font-size: 23px;
  }
  
  @media screen and (max-width: 1279px) {
    font-size: 21px;
  }
  
  @media screen and (max-width: 1151px) {
    font-size: 18px;
  }
  
  @media screen and (max-width: 1023px) {
    font-size: 14px;
  }
  `

export const h5FontSize = `
    @media screen and (min-width: 1920px) {
    font-size: 32px;
  }
    
  @media screen and (max-width: 1919px) {
    font-size: 28px;
   }
    
  @media screen and (max-width: 1679px) {
    font-size: 26px;
  }
  
  @media screen and (max-width: 1599px) {
    font-size: 24px;
  }
  
  @media screen and (max-width: 1439px) {
    font-size: 23px;
  }
  
  @media screen and (max-width: 1399px){
    font-size: 22px;
  }
  
  @media screen and (max-width: 1365px) {
    font-size: 22px;
  }
  
  @media screen and (max-width: 1359px) {
    font-size: 21px;
  }
  
  @media screen and (max-width: 1279px) {
    font-size: 19px;
  }
  
  @media screen and (max-width: 1151px) {
    font-size: 17px;
  }
  
  @media screen and (max-width: 1023px) {
    font-size: 13px;
  }
  `

export const h7FontSize = `
    @media screen and (min-width: 1920px) {
    font-size: 25px;
  }
    
  @media screen and (max-width: 1919px) {
    font-size: 21px;
   }
    
  @media screen and (max-width: 1679px) {
    font-size: 20px;
  }
  
  @media screen and (max-width: 1599px) {
    font-size: 18px;
  }
  
  @media screen and (max-width: 1439px) {
    font-size: 18px;
  }
  
  @media screen and (max-width: 1399px){
    font-size: 17px;
  }
  
  @media screen and (max-width: 1365px) {
    font-size: 17px;
  }
  
  @media screen and (max-width: 1359px) {
    font-size: 16px;
  }
  
  @media screen and (max-width: 1279px) {
    font-size: 15px;
  }
  
  @media screen and (max-width: 1151px) {
    font-size: 13px;
  }
  
  @media screen and (max-width: 1023px) {
    font-size: 10px;
  }
  `

export const h8FontSize = `
    @media screen and (min-width: 1920px) {
    font-size: 21px;
  }
    
  @media screen and (max-width: 1919px) {
    font-size: 18px;
   }
    
  @media screen and (max-width: 1679px) {
    font-size: 17px;
  }
  
  @media screen and (max-width: 1599px) {
    font-size: 15px;
  }
  
  @media screen and (max-width: 1439px) {
    font-size: 15px;
  }
  
  @media screen and (max-width: 1399px){
    font-size: 14px;
  }
  
  @media screen and (max-width: 1365px) {
    font-size: 14px;
  }
  
  @media screen and (max-width: 1359px) {
    font-size: 14px;
  }
  
  @media screen and (max-width: 1279px) {
    font-size: 12px;
  }
  
  @media screen and (max-width: 1151px) {
    font-size: 11px;
  }
  
  @media screen and (max-width: 1023px) {
    font-size: 8px;
  }
  `

export const h9FontSize = `
    @media screen and (min-width: 1920px) {
    font-size: 18px;
  }
    
  @media screen and (max-width: 1919px) {
    font-size: 15px;
   }
    
  @media screen and (max-width: 1679px) {
    font-size: 15px;
  }
  
  @media screen and (max-width: 1599px) {
    font-size: 13px;
  }
  
  @media screen and (max-width: 1439px) {
    font-size: 13px;
  }
  
  @media screen and (max-width: 1399px){
    font-size: 12px;
  }
  
  @media screen and (max-width: 1365px) {
    font-size: 12px;
  }
  
  @media screen and (max-width: 1359px) {
    font-size: 12px;
  }
  
  @media screen and (max-width: 1279px) {
    font-size: 10px;
  }
  
  @media screen and (max-width: 1151px) {
    font-size: 9px;
  }
  
  @media screen and (max-width: 1023px) {
    font-size: 7px;
  }
  `

export const h11FontSize = `
    @media screen and (min-width: 1920px) {
    font-size: 16px;
  }
    
  @media screen and (max-width: 1919px) {
    font-size: 14px;
   }
    
  @media screen and (max-width: 1679px) {
    font-size: 13px;
  }
  
  @media screen and (max-width: 1599px) {
    font-size: 12px;
  }
  
  @media screen and (max-width: 1439px) {
    font-size: 11px;
  }
  
  @media screen and (max-width: 1399px){
    font-size: 11px;
  }
  
  @media screen and (max-width: 1365px) {
    font-size: 11px;
  }
  
  @media screen and (max-width: 1359px) {
    font-size: 10px;
  }
  
  @media screen and (max-width: 1279px) {
    font-size: 9px;
  }
  
  @media screen and (max-width: 1151px) {
    font-size: 8px;
  }
  
  @media screen and (max-width: 1023px) {
    font-size: 6px;
  }
  `
export const h12FontSize = `
    @media screen and (min-width: 1920px) {
    font-size: 14px;
  }
    
  @media screen and (max-width: 1919px) {
    font-size: 12px;
   }
    
  @media screen and (max-width: 1679px) {
    font-size: 11px;
  }
  
  @media screen and (max-width: 1599px) {
    font-size: 10px;
  }
  
  @media screen and (max-width: 1439px) {
    font-size: 10px;
  }
  
  @media screen and (max-width: 1399px){
    font-size: 19px;
  }
  
  @media screen and (max-width: 1365px) {
    font-size: 19px;
  }
  
  @media screen and (max-width: 1359px) {
    font-size: 19px;
  }
  
  @media screen and (max-width: 1279px) {
    font-size: 8px;
  }
  
  @media screen and (max-width: 1151px) {
    font-size: 7px;
  }
  
  @media screen and (max-width: 1023px) {
    font-size: 5px;
  }
  `
