import styled from '@emotion/styled/macro'

export const EmailPopupCon = styled.div`
	background-image: url(${(props) => props.background});
	background-repeat: no-repeat;
	background-size: cover;
	color: white;

	.alert-dialog-title {
		display: flex;
		justify-content: space-between;
	}
	.dialog-title-text {
		margin-top: auto;
		margin-bottom: auto;
		font-size: 35px;
		font-weight: bold;
	}
	.alert-dialog-description {
		color: white;
		font-size: 25px;
	}

	@media screen and (max-width: 1360px) and (max-height: 768px) {
		.dialog-title-text {
			font-size: 24px;
		}
		.alert-dialog-description {
			font-size: 17px;
		}
	}

	@media screen and (max-width: 1360px) and (max-height: 768px) {
		.dialog-title-text {
			font-size: 24px;
		}
		.alert-dialog-description {
			font-size: 17px;
		}
	}

	@media screen and (max-width: 1024px) {
		.dialog-title-text {
			font-size: 18px;
		}
		.alert-dialog-description {
			font-size: 13px;
		}
	}

	@media screen and (max-width: 800px) {
		.dialog-title-text {
			font-size: 14px;
		}
		.alert-dialog-description {
			font-size: 10px;
		}
	}
`
