import styled from '@emotion/styled/macro'
import { h3_3FontSize, h7FontSize } from '../../appSkin.js'

export const PayoffTwoCon = styled.div`
	.payoff-two-container {
		background-color: #f5f5f5;
	}
	.payoff-two-title {
		font-weight: bold;
		${h3_3FontSize}
		padding-right: 1.5%;
		padding-left: 1.5%;
	}
	.payoff-top-row {
		width: 100%;
		height: 50px;
	}
	.payoff-two-text {
		padding-top: 2%;
		padding-right: 1.5%;
		padding-left: 1.5%;
		text-align: justify;
		${h7FontSize}
	}
`
