import styled from '@emotion/styled'

export const BookAccordionContainer = styled.div`
	color: ${(props) => (props.selected ? '#000' : '#fff')};
	display: flex;
	padding: 4% 1.28% 0 1.28%;
	cursor: pointer;
	background: ${(props) => (props.selected ? '#D9E0F2' : 'transparent')};

	button {
		padding: 0.51%;
		min-width: unset;
	}

	button svg {
		color: ${(props) => (props.selected ? '#000' : '#fff')};
	}

	.book_title {
		width: 100%;
		height: 100%;
		margin-left: 1%;
		line-height: 1.1;
	}

	.bookTitle {
		margin-top: 2%;
	}

	// .accordion button {
	//   padding: 0.51% !important;
	//   margin: 1.28% 1.28% 1.28% 0 !important;
	//   min-width: unset !important;
	// }

	// .accordion button svg {
	//   color: white !important;
	// }

	// .accordion::after,
	// .accordion::before {
	//   display: none;
	// }

	// .accordion {
	//   min-height: 50px !important;
	// }

	// .accordion_summary {
	//   margin: 0 !important;
	//   display: flex;
	//   align-items: center;
	//   min-height: 50px !important;
	//   flex-direction: column !important;
	// }

	// .accordion_summary > div {
	//   flex: 1 !important;
	//   width: 100%;
	//   display: flex;
	//   align-items: center;
	//   margin: 0 !important;
	//   padding-right: 0 !important;
	// }

	// .accordion_summary > div p {
	//   height: 50px !important;
	//   display: flex;
	//   align-items: center;
	// }

	// .accordion ul {
	//   list-style: none;
	//   margin: 0;
	//   padding: 0;
	//   margin-left: 9%;
	// }

	// .accordion ul li {
	//   height: 40px !important;
	//   display: flex;
	//   align-items: center;
	//   padding-left: 0.78%;
	// }
	// .accordion ul li:nth-of-type(even) {
	//   background: #d9e0f2;
	//   color: black;
	// }
`
