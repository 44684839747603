import { Fragment, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import NavbarLanding1 from 'components/NavbaHPEarlyReg'
import PayoffTwoLanding1 from 'components/PayoffTwoLanding'
// import CustomersReview from "components/CustomersReview";
import TutorialVideo from 'components/TutorialVideo'
import LoginFooterLanding from 'components/LoginFooterLanding'
import backgroundImage from 'assets/Background-Image.png'
import ModalVideo from 'react-modal-video'
import { HPearlyRegContainer } from './HPearlyRegistrationSkin'
import 'react-modal-video/css/modal-video.min.css'

const HPearlyRegistration = () => {
	const { t } = useTranslation()
	const [isOpen, setOpen] = useState(false)
	const [url, setUrl] = useState('')
	return (
		<Fragment>
			<ModalVideo
				channel="youtube"
				width="5000"
				autoplay
				isOpen={isOpen}
				videoId={url}
				onClose={() => setOpen(false)}
				data-testid="modal-video"
			/>
			<Helmet>
				<title>{t('PreMktg_title')}</title>
				<meta name="description" content={t('PreMktg_description')} />

				{/* Open Graph tags */}
				<meta property="og:type" content="website" />
				<meta
					property="og:url"
					content={`${process.env.REACT_APP_BASE_URL}/mktg/pmuc01`}
				/>
				<meta property="og:title" content={t('PreMktg_title')} />
				<meta property="og:description" content={t('PreMktg_description')} />
				<meta property="og:image" content="%PUBLIC_URL%/ATutor_OG.jpg" />
				<meta property="og:image:width" content="227" />
				<meta property="og:image:height" content="458" />
			</Helmet>
			<div>
				<HPearlyRegContainer
					background={backgroundImage}
					data-testid="hp-early-reg-container"
				>
					<NavbarLanding1 />
				</HPearlyRegContainer>
				<PayoffTwoLanding1 />
				{/* <CustomersReview /> */}
				<TutorialVideo modalData={{ isOpen, setOpen, setUrl }} />
				<LoginFooterLanding />
			</div>
		</Fragment>
	)
}

export default HPearlyRegistration
