import styled from '@emotion/styled'

export const QnaAccordionContainer = styled.div`
	.book_title {
		width: 100%;
		height: 100%;
		margin-left: 10px !important;
	}

	.accordion::after,
	.accordion::before {
		display: none;
	}

	.accordion {
		// min-height: 50px !important;
	}

	.accordion_summary {
		margin: 0 !important;
		display: flex;
		align-items: center;
		min-height: 0px !important;
		flex-direction: column !important;
		padding: unset;
	}

	.accordion_summary > div {
		flex: 1 !important;
		width: 100%;
		display: flex;
		align-items: center;
		margin: 0 !important;
		padding: 4% 1.28% 0 1.28%;
	}

	.accordion_summary > div p {
		display: flex;
		align-items: center;
		line-height: 1.1;
	}

	.accordion ul {
		list-style: none;
		margin: 0;
		padding: 0;
		margin-left: 9%;
	}

	.accordion ul li {
		height: 40px !important;
		display: flex;
		align-items: center;
		padding-left: 0.78%;
	}
`
