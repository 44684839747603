import QnaPageModal from 'components/QnaPagePopup/QnaPageModal'
import { useStyles } from 'components/QnaPagePopup/style'
import { QnaDisplayContainer } from './qnaDisplaySkin'

const QnaDisplay = ({
	selectedBook,
	showQnaPopup,
	bookTitles,
	expanded,
	maximize,
	handleMinimize,
	handleMaximize,
	handleModalHide,
	setExpanded,
	deleteNoteConfirm,
	refetch,
	data
}) => {
	const classes = useStyles()

	return (
		<QnaDisplayContainer>
			{showQnaPopup && (
				<>
					<QnaPageModal
						expanded={expanded}
						bookTitles={bookTitles}
						value={selectedBook}
						data={data}
						hide={handleModalHide}
						minimize={handleMinimize}
						setExpanded={setExpanded}
						deleteNoteConfirm={deleteNoteConfirm}
						refetch={refetch}
					/>
				</>
			)}

			{!showQnaPopup && maximize && (
				<button
					className={`maximizeButton ${classes.maximizeBtn}`}
					onClick={handleMaximize}
				>
					Maximize
				</button>
			)}
		</QnaDisplayContainer>
	)
}

export default QnaDisplay
