import styled from '@emotion/styled/macro'
import { h5FontSize } from '../../appSkin'

export const ErrorMessage = styled.div`
	${h5FontSize};
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`
