import { useState, useRef, useEffect, useMemo, useCallback } from 'react'
import LoadingIndicator from 'components/LoadingIndicator'
import ArrowCircleRightTwoToneIcon from '@mui/icons-material/ArrowCircleRightTwoTone'
import ArrowCircleLeftTwoToneIcon from '@mui/icons-material/ArrowCircleLeftTwoTone'
import ArrowCircleDownTwoToneIcon from '@mui/icons-material/ArrowCircleDownTwoTone'
import ArrowCircleUpTwoToneIcon from '@mui/icons-material/ArrowCircleUpTwoTone'
import { Document, Page, pdfjs } from 'react-pdf'
import { OPS } from 'pdfjs-dist/build/pdf'
import Comments from './Comments'
import { PdfReaderContainer } from './pdfReaderSkin'
import { insertData } from '../../utils/idbIndexed'
import { findCommonSubstring } from 'utils/highlightSubstring'
import './style.css'
import useAuth from 'hooks/useAuth'
import { useMutation } from 'react-query'
import { base64ToFile } from 'utils/common.util'
import { postBookContent } from 'api/book'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

let pageContexts = []

const PdfReader = ({
	setOnLoadBook,
	book,
	setHandleOnChangePage,
	setNextPageHandle,
	currentPage,
	setCurrentPage,
	setNextPageNumber,
	setBookTotalPage,
	setCurrentOpenBookId,
	setPreviousPageNumber,
	setPreviousPageHandle,
	notes,
	commentsRef,
	pageHeight,
	setPageHeight,
	mouseUpEvent,
	getNoteByBookPage,
	bookIndexedDb,
	setLoadedCompletely,
	activePageStatus,
	commentAdded,
	resetComment,
	drawerToggle
}) => {
	const divRef = useRef(null)
	const pdfSectionRef = useRef(null)
	const isMountedRef = useRef(true)

	const [page, setPage] = useState(book.page)
	const [totalPage, setTotalPage] = useState(book.totalPage)
	const [clickNextPage, setClickNextPage] = useState(false)
	const [clickPeviousPage, setClickPeviousPage] = useState(false)
	const [isPdfLoaded, setIsPdfLoaded] = useState(false)
	const [showComments, setShowComments] = useState(false)
	const [loader, setLoader] = useState(false)
	let pageRef = useRef()
	let scrollIntervalIdValue = null
	const [btnBlock, setBtnBlock] = useState(false)

	const documentOptions = useMemo(
		() => ({
			cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
			cMapPacked: true,
			standardFontDataUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/standard_fonts/`
		}),
		[]
	)

	const bookSrc = useRef()
	const {
		auth: {
			user: { expiration }
		}
	} = useAuth()

	useEffect(() => {
		if (pdfSectionRef.current) {
			pdfSectionRef.current?.focus()
		}
		resetComment()
	}, [commentAdded])

	useEffect(() => {
		setShowComments(true)
	}, [currentPage, commentAdded])

	useEffect(() => {
		if (pdfSectionRef.current) {
			pdfSectionRef.current?.focus()
		}
	}, [pdfSectionRef.current])

	useEffect(() => {
		if (pdfSectionRef.current) {
			pdfSectionRef.current?.focus()
		}
	}, [drawerToggle])

	useEffect(() => {
		if (clickNextPage) {
			setPreviousPageHandle(false)
			setNextPageNumber(page)
			setBookTotalPage(totalPage)
			setCurrentOpenBookId(book.id)
			setClickNextPage(false)
		}
		if (clickPeviousPage) {
			setNextPageHandle(false)
			setPreviousPageNumber(page)
			setBookTotalPage(totalPage)
			setCurrentOpenBookId(book.id)
			setClickPeviousPage(false)
		}
	}, [page, book.id, totalPage, clickPeviousPage, clickNextPage])

	useEffect(() => {
		if (currentPage) {
			setPage(currentPage)
			setShowComments(false)
			setIsPdfLoaded(false)
		}
	}, [currentPage])

	let pageNo = 1
	let loadedPageNum = parseInt(sessionStorage.getItem('loaded-pages'))

	if (!loadedPageNum || loadedPageNum > pageContexts.length) {
		loadedPageNum = 0
		sessionStorage.setItem('loaded-pages', 0)
	}

	if (sessionStorage.getItem('page')) {
		pageNo = parseInt(sessionStorage.getItem('page'))
	}

	let pageNumber = pageNo
	let pageNum = parseInt(sessionStorage.getItem('page'))

	if (!pageNum) {
		pageNum = 1
	}

	const { mutate, isLoading, isError, error } = useMutation(postBookContent)
	const setPageNumber = (num) => {
		pageNumber = num
	}

	const highlightSelected = () => {
		let parent = document.querySelector(
			'.react-pdf__Page__textContent.textLayer'
		)

		if (parent && parent.children.length > 0) {
			for (let d of notes) {
				const start = d?.note_highlight?.start
				const end = d?.note_highlight?.end
				const side_two = d?.note_highlight?.side2Original
					? d?.note_highlight?.side2Original
					: d?.note_side2

				if (start && end) {
					for (let i = start; i <= end; i++) {
						const text = parent?.children[i]?.textContent
						const currentText = findCommonSubstring(text, side_two)
						const modifiedText = text.replace(
							currentText,
							`<span class="highlight">${currentText}</span>`
						)
						parent.children[i].innerHTML = modifiedText
					}
				}
			}
		} else {
			console.error('Parent not found or has no children')
		}
	}

	useEffect(() => {
		let isMounted = true

		const loadPdfSource = async () => {
			try {
				if (!isMounted) return

				const savebookInIndexedDb = (bookUrl) => {
					const xhr = new XMLHttpRequest()
					xhr.open('GET', bookUrl)
					xhr.responseType = 'blob'
					xhr.onload = () => {
						if (xhr.status === 200) {
							const pdfBlob = xhr.response

							// Create a new FileReader instance
							const reader = new FileReader()

							// Define the callback function for when the file is loaded
							reader.onload = async () => {
								if (!isMounted) return // Check if component is still mounted

								// Store the binary data in localStorage
								const base64Content = btoa(reader.result)
								await insertData({ id: book.id, book: base64Content })
								console.log('PDF file saved to localStorage')
							}

							// Read the PDF file as a binary string
							reader.readAsBinaryString(pdfBlob)
						} else {
							console.log('Failed to fetch the PDF file from AWS S3')
						}
					}
					xhr.send()
				}

				if (bookIndexedDb) {
					bookSrc.current = `data:application/pdf;base64,${bookIndexedDb.book}`
				} else {
					// If PDF needs to be fetched
					bookSrc.current = book.book
					// Save to IndexedDB if needed
					await savebookInIndexedDb(book.book)
				}
				setLoader(false)
			} catch (error) {
				console.error('Error loading PDF source:', error)
				setOnLoadBook(true)
			}
		}

		loadPdfSource()

		return () => {
			isMounted = false
		}
	}, [book.book, bookIndexedDb])

	useEffect(() => {
		if (isPdfLoaded) {
			const timer = setTimeout(() => {
				setShowComments(true)
			}, 100)
			return () => clearTimeout(timer)
		}
	}, [isPdfLoaded])

	useEffect(() => {
		commentsRef.current.scrollTop = 0
	}, [])

	function onDocumentLoadSuccess(pdf) {
		setOnLoadBook(true)
		setLoader(true)
		setTotalPage(pdf.numPages)
		setBookTotalPage(pdf.numPages)

		if (sessionStorage.getItem('page')) {
			setPageNumber(parseInt(sessionStorage.getItem('page')))
		} else {
			sessionStorage.setItem('page', pageNumber)
		}
	}

	const onDocumentLoadError = (error) => {
		console.error('Error loading document:', error)
		setOnLoadBook(true)
		setLoader(false)
	}

	const saveContent = (textContent, imagesArray) => {
		try {
			const files = []
			imagesArray.map((item, index) => {
				files.push(base64ToFile(item, `image${index}.png`))
			})
			const formData = new FormData()
			if (files.length > 0) {
				files.map((file) => {
					formData.append(`images`, file)
				})
			} else {
				formData.append(`images`, [])
			}

			formData.append('content', textContent)
			formData.append('pageN', currentPage)
			mutate({ formData, id: book?.id })
		} catch (error) {
			console.log('error', error)
		}
	}

	const pageLoaded = async (page) => {
		window.objs = []
		const SCRAPING = JSON.parse(process.env.REACT_APP_SCRAPE)
		var imageArray = []
		const textContentArray = await page.getTextContent()
		var textContent = textContentArray.items.map((item) => item.str).join('')
		const canvas = document.createElement('canvas')
		let context = canvas.getContext('2d', { willReadFrequently: true })

		const calculateViewport = (page, containerWidth) => {
			if (!containerWidth) {
				return page.getViewport({ scale: 1 })
			}
			const initialScale = containerWidth / page.getViewport({ scale: 1 }).width
			return page.getViewport({ scale: initialScale })
		}

		const viewport = calculateViewport(page, divRef.current?.clientWidth)
		setPageHeight(viewport.height)

		const imageHandler = async (imageData) => {
			try {
				const { left, top, width, height, imgData, viewport } = imageData

				if (imgData && imgData.bitmap) {
					const canvas = document.createElement('canvas')
					canvas.width = width
					canvas.height = height
					const ctx = canvas.getContext('2d')

					await createImageBitmap(imgData.bitmap).then((bitmap) => {
						ctx.drawImage(bitmap, 0, 0, width, height)
						const imageUrl = canvas.toDataURL('image/png')
						imageArray.push(imageUrl)

						const image = document.createElement('img')
						image.src = imageUrl
						image.className = 'pdf-image'

						const viewportScale = viewport.scale

						const scaledWidth = width * viewportScale
						const scaledHeight = height * viewportScale

						const xPos = left * viewportScale
						const yPos = top * viewportScale

						image.style.cssText = `
				  position: absolute; 
				  top: ${yPos}px; 
				  left: ${xPos}px; 
				  width: ${scaledWidth}px; 
				  height: ${scaledHeight}px; 
				  z-index: 1;
				`

						const pageContainer = document.getElementById(
							`page-${page._pageIndex + 1}`
						)
						if (pageContainer) {
							pageContainer.appendChild(image)
							pageContainer.style.position = 'relative'
							pageContainer.style.display = 'block'
						}
					})
				}
			} catch (ex) {
				console.error('Error processing image:', ex)
			}
		}

		const operatorList = await page.getOperatorList()
		const transformMatrices = {}

		for (let i = 0; i < operatorList.fnArray.length; i++) {
			const fnId = operatorList.fnArray[i]

			if (fnId === OPS.transform) {
				const matrix = operatorList.argsArray[i]

				transformMatrices.current = matrix
			}

			if (fnId === OPS.save) {
				transformMatrices.saved = transformMatrices.current
			}

			if (fnId === OPS.restore && transformMatrices.saved) {
				transformMatrices.current = transformMatrices.saved
			}

			if (fnId === OPS.paintImageXObject || fnId === OPS.paintJpegXObject) {
				const imageIndex = operatorList.argsArray[i][0]

				try {
					const imageDict = await page.objs.get(imageIndex)

					if (imageDict) {
						const width = imageDict.width || 0
						const height = imageDict.height || 0

						if (width > 0 && height > 0) {
							let position = {
								left: 0,
								top: 0,
								width: width,
								height: height
							}

							if (transformMatrices.current) {
								const matrix = transformMatrices.current

								const [a, b, c, d, e, f] = matrix

								const pageWidth = viewport.width
								const pageHeight = viewport.height

								let scaleX = Math.abs(a)
								let scaleY = Math.abs(d)

								if (b !== 0 || c !== 0) {
									scaleX = Math.sqrt(a * a + b * b)
									scaleY = Math.sqrt(c * c + d * d)
								}

								const transformedWidth = scaleX
								const transformedHeight = scaleY

								position = {
									left: Math.max(0, Math.min(e, pageWidth)),
									top: Math.max(0, height - pageHeight) + 100,
									width: Math.min(transformedWidth, pageWidth),
									height: Math.min(transformedHeight, pageHeight)
								}
							}

							await imageHandler({
								left: position.left,
								top: position.top,
								width: position.width,
								height: position.height,
								imgData: imageDict,
								viewport,
								objId: imageIndex
							})
						}
					}
				} catch (err) {
					console.error('Error processing image:', err)
				}
			}
		}

		page.render({ canvasContext: context, viewport })

		pageContexts.push({
			canvasContext: context,
			viewport
		})

		const handleLayoutComplete = () => {
			if (SCRAPING && !activePageStatus) {
				saveContent(textContent, imageArray)
			}
		}

		setTimeout(handleLayoutComplete, 100)
		highlightSelected()
		setLoadedCompletely(true)
		setIsPdfLoaded(true)
	}

	const previousPage = () => {
		setClickPeviousPage(true)
		setPreviousPageHandle(true)
		setPage((p) => parseInt(p) - 1)
		setCurrentPage((p) => parseInt(p) - 1)
		setPageNumber((p) => parseInt(p) - 1)
	}

	useEffect(() => {
		return () => {
			isMountedRef.current = false
		}
	}, [])

	const nextPage = useCallback(() => {
		if (!isMountedRef.current) return

		const newPage = parseInt(page) + 1

		if (isMountedRef.current) {
			setBtnBlock(true)
			setPage(newPage)
		}
	}, [page])

	useEffect(() => {
		if (btnBlock && isMountedRef.current) {
			setCurrentPage(page)
			setPageNumber(page)
			setNextPageNumber(page)
			setClickNextPage(true)
			setNextPageHandle(true)
			setBtnBlock(false)
		}
	}, [page, btnBlock])

	const scrollPdfPage = (value) => {
		divRef.current.scrollTop += value
		commentsRef.current.scrollTop += value
	}

	const onKeyDownHandler = (event) => {
		if (event.code === 'ArrowDown') {
			scrollPdfPage(scrollSpeed)
		}
		if (event.code === 'ArrowUp') {
			scrollPdfPage(-scrollSpeed)
		}
	}

	const scrollSpeed = 8

	return (
		<PdfReaderContainer
			tabIndex="0"
			onKeyDown={onKeyDownHandler}
			ref={pdfSectionRef}
		>
			{page !== 1 && loader && (
				<ArrowCircleLeftTwoToneIcon
					className="control control-left"
					onClick={previousPage}
				/>
			)}

			{loader && (
				<ArrowCircleDownTwoToneIcon
					className="control control-down"
					onMouseDown={(e) => {
						scrollIntervalIdValue = setInterval(() => {
							divRef.current.scrollBy(0, scrollSpeed)
							commentsRef.current.scrollBy(0, scrollSpeed)
						}, 0)
					}}
					onMouseUp={() => clearInterval(scrollIntervalIdValue)}
					onMouseLeave={() => clearInterval(scrollIntervalIdValue)}
					onClick={() => scrollPdfPage(scrollSpeed)}
				/>
			)}

			{loader && (
				<ArrowCircleUpTwoToneIcon
					className="control control-up"
					onClick={() => scrollPdfPage(-scrollSpeed)}
					onMouseDown={(e) => {
						scrollIntervalIdValue = setInterval(() => {
							divRef.current.scrollBy(0, -scrollSpeed)
							commentsRef.current.scrollBy(0, -scrollSpeed)
						}, 0)
					}}
					onMouseUp={() => clearInterval(scrollIntervalIdValue)}
					onMouseLeave={() => clearInterval(scrollIntervalIdValue)}
				/>
			)}

			<section
				id="pdf-section"
				className="d-flex flex-column align-items-center w-100"
				style={{ width: '100%', height: 'auto', overflow: 'hidden' }}
				ref={divRef}
				onWheel={(event) => {
					scrollPdfPage(event.deltaY)
				}}
			>
				<Document
					file={bookSrc.current}
					options={documentOptions}
					onLoadSuccess={onDocumentLoadSuccess}
					onLoadError={onDocumentLoadError}
					loading={() => <LoadingIndicator marginTop={'25%'} />}
					onLayoutCallback={() => {
						console.log('PDF layout completed')
					}}
				>
					<div id={`page-${page}`} key={page} className="pdf-page">
						<div onMouseUp={mouseUpEvent}>
							<Page
								pageNumber={currentPage}
								width={divRef.current?.clientWidth}
								renderTextLayer={true}
								renderAnnotationLayer={true}
								renderInteractiveForms={true}
								onRenderSuccess={pageLoaded}
								onRenderError={(error) =>
									console.error('Page render error:', error)
								}
								canvasRef={pageRef}
							/>
						</div>
					</div>
				</Document>
			</section>

			<div id="image-layer" />

			{page !== totalPage && !btnBlock && loader && (
				<ArrowCircleRightTwoToneIcon
					className="control control-right"
					style={{
						right: !JSON.parse(localStorage.getItem('closed')) ? '17%' : '22%'
					}}
					onClick={nextPage}
				/>
			)}
			{showComments && isPdfLoaded && (
				<Comments
					notes={notes}
					currentPage={currentPage}
					pageHeight={pageHeight}
					getNoteByBookPage={getNoteByBookPage}
					bookTitle={book.bookTitle}
					expiration={expiration}
				/>
			)}
		</PdfReaderContainer>
	)
}

export default PdfReader
