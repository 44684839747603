import { Button, Grid, Typography } from '@mui/material'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import Carousel from 'react-multi-carousel'
import { useQuery } from 'react-query'
import { getPublicTuts } from '../../api/tutorial'
import LoadingIndicator from './../LoadingIndicator'
import { TutorialVideoCon } from './skins'
// import vbImage from "assets/thumbnail/thumbnail-2.png";
import { getVideoIDByUrl } from 'utils/getYoutubeId'

export function TutorialCarousel(props) {
	const { videos } = props
	const { responsive } = props
	const { modalProps } = props
	const { isOpen, setOpen, setUrl } = modalProps.modalData

	const videosItems = videos.map((video, i) => {
		return (
			<div key={`videos-${i}`} className="videos-item">
				<Grid container className="containerSize">
					<Grid item xs={12}>
						<img
							src={`../assets/thumbnail/thumbnail-${video.tut_displayOrder}.png`}
							className="videos-image"
							alt=""
						/>
					</Grid>
					<Grid
						item
						xs={12}
						sx={{ padding: '0 15px', bgcolor: 'white', height: '123px' }}
					>
						<Typography
							// variant="h7"
							className="videos-name tutorials-video-title h7"
						>
							{video.tut_title}
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography
							variant="body2"
							className="tutorial-videos-text tutorials-video-duration h8"
						>
							{video.tut_duration}
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Button
							variant="contained"
							className="start-button tutorials-video-button h11"
							onClick={() => {
								const url = getVideoIDByUrl(video.tut_videoURL)
								setUrl(url)
								setOpen(!isOpen)
							}}
						>
							Start Now
						</Button>
					</Grid>
				</Grid>
			</div>
		)
	})

	return (
		<Carousel
			swipeable={false}
			draggable={false}
			responsive={responsive}
			ssr // means to render carousel on server-side.
			infinite
			autoPlaySpeed={3000}
			keyBoardControl
			transitionDuration={500}
			renderButtonGroupOutside
		>
			{videosItems}
		</Carousel>
	)
}

const TutorialVideo = (props) => {
	const { t } = useTranslation()

	const { isLoading, data } = useQuery('public-tutorial', getPublicTuts)

	const responsive = {
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 5
		},
		tablet: {
			breakpoint: { max: 1024, min: 750 },
			items: 4
		},
		mobile: {
			breakpoint: { max: 750, min: 465 },
			items: 3
		},
		smallMobile: {
			breakpoint: { max: 465, min: 0 },
			items: 1
		}
	}

	return (
		<TutorialVideoCon data-testid="tutorial-video-container">
			<Grid container>
				<Grid item xs={0.5} />
				<Grid item xs={11.5} sx={{ mt: '2%' }}>
					<Typography
						variant="h2"
						align="left"
						sx={{ fontWeight: 'bold', mt: '2%' }}
						className="tutorial-videos-title tutorials-title h2"
					>
						{t('tutorial')}
					</Typography>
				</Grid>
				{isLoading ? (
					<Grid
						container
						spacing={0}
						direction="column"
						alignItems="center"
						justifyContent="center"
						data-testid="tutorial-video-loading"
					>
						<LoadingIndicator />
					</Grid>
				) : (
					<Fragment>
						<Grid item xs={0.25} />
						<Grid item xs={11.5}>
							<TutorialCarousel
								style={{ width: '80%' }}
								videos={data}
								responsive={responsive}
								modalProps={props}
								data-testid="tutorial-carousel"
							/>
						</Grid>
						<Grid item xs={0.25} />
					</Fragment>
				)}
			</Grid>
		</TutorialVideoCon>
	)
}

export default TutorialVideo
