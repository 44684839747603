// /src/components/EmailPopup/index.js

import CloseIcon from '@mui/icons-material/Close'
import {
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import backgroundImage from '../../images/Path 845.png'
import { EmailPopupCon } from './skins'

const EmailPopup = ({ isopen, onClose }) => {
	const { t } = useTranslation()
	return (
		<Dialog
			open={isopen}
			onClose={() => {
				onClose()
			}}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<EmailPopupCon background={backgroundImage}>
				<DialogTitle
					id="alert-dialog-title"
					className="alert-dialog-title h4"
					sx={{ display: 'flex', justifyContent: 'space-between' }}
				>
					<div className="dialog-title-text">{t('wel_msg')}</div>
					{onClose ? (
						<IconButton
							aria-label="close"
							onClick={onClose}
							sx={{
								position: 'absolute',
								right: 8,
								top: 8,
								color: (theme) => theme.palette.grey[500]
							}}
						>
							<CloseIcon />
						</IconButton>
					) : null}
				</DialogTitle>
				<DialogContent>
					<DialogContentText
						className="alert-dialog-description h7"
						id="alert-dialog-description"
					>
						{t('confirn_msg')}
					</DialogContentText>
					<DialogContentText
						className="alert-dialog-description h7"
						id="alert-dialog-description"
					>
						{t('confirm_msg2')}
					</DialogContentText>
				</DialogContent>
			</EmailPopupCon>
		</Dialog>
	)
}

export default EmailPopup
