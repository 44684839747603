import { makeStyles } from '@mui/styles'
import backgroundImage from '../../images/Path 845.png'
import { h8FontSize } from 'appSkin'

export const useStyles = makeStyles({
	modalBar: {
		justifyContent: 'center !important'
	},
	modalContainer: {
		// rowGap: "10px",
		marginTop: '0 !important'
	},
	modalWeb: {
		position: 'fixed',
		top: '20%',
		left: '20%',
		right: '20%',
		width: '60%',
		// margin:'auto',
		background: '#2A333A',
		border: '1px solid #55657f',
		borderRadius: '5px',
		paddingTop: '1.58%'
	},
	modalPhone: {
		position: 'absolute',
		top: '20%',
		left: '5%',
		right: '5%',
		width: '90%',
		// margin:'auto',
		background: '#2A333A',
		padding: '0% 1% 0%',
		border: '1px solid #55657f',
		borderRadius: '5px',
		paddingTop: '1.58%'
	},

	input: {
		width: '100%',
		textAlign: 'left',
		// marginBottom: 17,
		background: 'transparent',
		border: '1px solid white',
		borderRadius: 10,
		color: '#ffffff',
		height: '100%',
		fontFamily: 'Segoe UI',
		'&:active': {
			outline: 'none'
		}
	},
	inputStep: {
		width: '100%',
		// marginBottom: 17,
		background: 'transparent',
		border: '1px solid white',
		borderRadius: 5,
		color: '#ffffff',
		height: '100%',
		fontFamily: 'Segoe UI',
		'&:active': {
			outline: 'none'
		}
	},
	iconBtn: {
		borderRadius: '5px !important',
		height: 'auto',
		width: '100%'
	},
	white: {
		background: '#6a7ae9 !important',
		color: 'white !important'
	},
	black: {
		color: 'white !important',
		background: 'transparent'
	},
	borderWhite: {
		border: '1px solid white'
	},
	modalFooter: {},

	stepNavigation: {
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'flex-start'
	},
	steps: {
		background: 'transparent',
		borderTopRightRadius: 5,
		borderTopLeftRadius: 5,
		fontWeight: 'bold',
		color: 'white',
		border: 'none',
		width: '100%',
		padding: '19.68% 15%'
	},

	stepNotSelected: {
		background: '#6A7AE9',
		color: 'black',
		width: '100%',
		padding: '19.68% 15%',
		borderRadius: '10px 10px 0 0',
		cursor: 'pointer'
	},

	textarea: {
		width: '99%',
		'&:focus-visible': {
			outline: 'none'
		},
		fontFamily: 'Segoe UI'
	},
	scrollBtnTop: {
		background:
			'linear-gradient(0deg, rgba(37,55,167,1) 35%, rgba(73,90,202,1) 57%, rgba(106,122,233,1) 97%)',
		borderTopLeftRadius: 25,
		borderTopRightRadius: 25,
		color: 'white'
	},
	scrollBtnBottom: {
		background:
			'linear-gradient(180deg, rgba(37,55,167,1) 35%, rgba(73,90,202,1) 57%, rgba(106,122,233,1) 97%)',
		borderBottomLeftRadius: 25,
		borderBottomRightRadius: 25,
		color: 'white'
	},
	step: {
		width: '100%',
		height: '100%',
		borderRadius: 5,
		color: 'white',
		background: 'transparent'
	},
	answerBtnCont: {
		textAlign: 'right',
		display: 'flex',
		justifyContent: 'end'
	},
	answerBtn: {
		cursor: 'pointer',
		background: '#6a7ae9',
		color: 'white',
		fontWeight: 'bold',
		borderRadius: 10,
		border: 'none',
		justifyContent: 'center',
		display: 'initial',
		textAlign: 'center',
		padding: '6.2% 16.8%',
		marginBottom: '5.5%',
		marginTop: '5.5%',
		width: '100%'
	},
	maximizeBtn: {
		position: 'absolute',
		right: 50,
		bottom: 20,
		width: '20%',
		backgroundImage: `url(${backgroundImage})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		color: 'white',
		border: 'none',
		height: '40px',
		borderRadius: 5
	},

	top: {
		width: '100%',
		height: '11%',
		backgroundImage:
			'linear-gradient(\r\n    180deg,\r\n    #6a7ae9 0%,\r\n    #4253c3 83%,\r\n    #2335a5 100%\r\n  )',
		borderTopLeftRadius: '25px',
		borderTopRightRadius: '25px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	bottom: {
		width: '100%',
		height: '12%',
		backgroundImage:
			'linear-gradient(\r\n    180deg,\r\n    #6a7ae9 0%,\r\n    #4253c3 83%,\r\n    #2335a5 100%\r\n  )',
		borderBottomLeftRadius: '25px',
		borderBottomRightRadius: '25px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},

	scrollbar: {
		overflow: 'hidden'
	},
	text_box: {
		padding: '2% 2.9%',
		border: '1px solid #6A7AE9',
		background: 'transparent',
		width: '100%',
		resize: 'none',
		color: '#fff',
		outline: 'none'
	},
	font: {
		// "fontFamily": "\"Times New Roman\" !important"
		fontFamily: 'Segoe UI'
	},
	ans_btn: {
		textAlign: 'center',
		color: '#fff',
		cursor: 'pointer',
		width: '100%',
		marginTop: '5.5%',
		marginBottom: '5.5%',
		borderRadius: 10
	},

	btn_wrong: {
		background: '#be0f11'
	},

	btn_difficult: {
		background: '#502a5a'
	},

	btn_normal: {
		background: '#253481'
	},

	btn_easy: {
		background: '#5866b8'
	},

	closeIconCont: {
		alignSelf: 'center'
	},

	whiteColor: {
		color: '#ffff'
	},

	cursorPointer: {
		cursor: 'pointer'
	},

	cursorPointerNotAllowed: {
		cursor: 'not-allowed'
	},

	ans_btn_height: {
		marginTop: '-10px'
	},

	stepInputWeb: {
		paddingLeft: '30px'
	},

	stepInputMobo: {
		paddingLeft: '25px'
	},

	gridContainer: {
		width: 'auto',
		margin: 'unset'
	},

	heading: {
		height: 'auto',
		justifyContent: 'center',
		display: 'flex',
		alignItems: 'center'
	},
	heading800: {
		height: 'auto',
		justifyContent: 'center',
		display: 'flex',
		alignItems: 'center'
	},

	minimizeBtn: {
		width: '1.086em',
		height: '1.086em'
	},

	minimizeBtnContainer: {
		width: '77.6%'
	},

	gridMargin: {
		marginTop: '3%'
	},

	fontP: {
		fontSize: '35px',
		color: '#fefefe'
	},

	iconDelEditUpdate: {
		height: 'auto',
		width: '100%'
	},

	note: {
		display: 'flex',
		flexDirection: 'column'
	},

	textAreaContainer: {},

	marginGrid: {
		marginTop: '2.64%'
	},

	deleteIcon: {
		marginRight: '20%'
	},
	nonePointerEvent: {
		pointerEvents: 'none'
	},
	Lowopacity: {
		opacity: 0.5
	},
	grabbingHand: {
		cursor: 'grabbing'
	},
	grabHand: {
		cursor: 'grab'
	},
	reviewContainer: {
		display: 'flex',
		alignItems: 'center'
	},
	reviewText: {
		padding: '0 10px',
		fontFamily: 'Segoe UI',
		color: '#e9bb6a',
		fontWeight: 'bold!important'
	}
})
