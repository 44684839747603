// /src/components/NavbarHPOT

// Libraries
import React, { useState, useEffect } from 'react'
import {
	Button,
	CircularProgress,
	Grid,
	Paper,
	Typography
} from '@mui/material'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Carousel from 'react-multi-carousel'
import { useClient } from '@splitsoftware/splitio-react'

// Assets
import footerImage from 'assets/Footer-Image.png'
import payoffOneImage from 'assets/login_image.png'

// APIs
import { register } from 'api/auth'

// Components
import EmailPopup from '../EmailPopup'

// Utils
import { getActionIdFromURL } from '../../utils/common.util'
import { sendEventToGTM } from '../../utils/gtag'
import { consoleLog } from '../../utils/common.util'

// Redux hooks and actions
import { useDispatch, useSelector } from 'react-redux'
import { setShowCarousel } from '../../store/reducers/showCarouselHPOTReducer'

const NavbarHPOT = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	// Retrieve showCarousel from Redux store
	const showCarousel = useSelector(
		(state) => state.showCarouselHPOT.showCarousel
	)

	const FEATURE_FLAG = 'Carousel_NavbarHPot'
	const [email, setEmail] = useState('')
	const [showModal, setShowModal] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [action_id, setActionId] = useState(null)

	const client = useClient()
	const [treatmentWithConfig, setTreatmentWithConfig] = useState(null)

	// Fetch action_id from URL on mount
	useEffect(() => {
		const actionIdFromURL = getActionIdFromURL()
		setActionId(actionIdFromURL)
	}, [])

	// Fetch treatments and update state
	useEffect(() => {
		let isMounted = true

		const fetchTreatment = async () => {
			if (client) {
				try {
					await client.ready() // Wait for the client to be ready
					if (isMounted) {
						const treatment = client.getTreatmentWithConfig(FEATURE_FLAG)
						setTreatmentWithConfig(treatment)
						// Parse config and dispatch to Redux
						let flagConfig
						try {
							flagConfig = JSON.parse(treatment.config || '{}')
						} catch (e) {
							console.error('Error parsing feature flag config:', e)
							return
						}
						const showCarouselValue =
							flagConfig.show_carousel_on_navbar === 'true'
						dispatch(setShowCarousel(showCarouselValue))
					}
				} catch (error) {
					console.error('Error fetching treatments:', error)
				}
			}
		}

		fetchTreatment()

		return () => {
			isMounted = false
		}
	}, [client, dispatch])

	// Handle sign-up button click
	const handleSign = async () => {
		setIsLoading(true)
		try {
			const payload = { email }
			if (action_id != null) {
				payload.action_id = action_id
			}
			await register(payload)
			setIsLoading(false)
			setShowModal(true)

			// Define data object and call gtag here
			const eventData = {
				eventName: showCarousel
					? 'HPOTRegistration-Carousel'
					: 'HPOTRegistration-NoCarousel',
				eventCategory: 'Registration',
				eventAction: 'Submit',
				eventLabel: showCarousel
					? 'Carousel Displayed'
					: 'No Carousel Displayed'
			}

			consoleLog('showCarousel =', showCarousel)
			sendEventToGTM(eventData)
		} catch (error) {
			setEmail('')
			setIsLoading(false)
			if (error.response) {
				toast(error.response.data.message)
			} else if (error.request) {
				toast(error.request)
			} else {
				toast(error.message)
			}
		}
	}

	if (showCarousel === null || treatmentWithConfig === null) {
		// Optionally, render a loading indicator
		return null
	}

	const renderContent = () => {
		const { treatment, config } = treatmentWithConfig

		let flagConfig
		try {
			flagConfig = JSON.parse(config || '{}')
		} catch (e) {
			console.error('Error parsing feature flag config:', e)
			return null
		}

		consoleLog('treatment => ', treatment)
		consoleLog('flagConfig => ', flagConfig)
		consoleLog('showCarousel => ', showCarousel)

		// Responsive settings for Carousel
		const responsive = {
			desktop: {
				breakpoint: { max: 3000, min: 1024 },
				items: 1
			},
			tablet: {
				breakpoint: { max: 1024, min: 750 },
				items: 1
			},
			mobile: {
				breakpoint: { max: 750, min: 465 },
				items: 1
			},
			smallMobile: {
				breakpoint: { max: 465, min: 0 },
				items: 1
			}
		}

		if (showCarousel) {
			// Render carousel content
			return (
				<Grid item xs container direction="row" marginTop={5}>
					<Grid item xs={1} sm={1} md={2}>
						{' '}
					</Grid>

					<Grid item xs={12} sm={12} md={12} marginRight={2}>
						<div className="navbar-carousel">
							<Carousel
								swipeable={true}
								draggable={true}
								responsive={responsive}
								autoPlay={true}
								ssr
								infinite
								autoPlaySpeed={process.env.REACT_APP_CAROUSEL_SPEED}
								arrows={false}
								showDots={true}
								transitionDuration={500}
								renderButtonGroupOutside
							>
								{Array.from(
									{
										length: parseInt(flagConfig.carousel_slides_count)
									},
									(_, i) => (
										<React.Fragment key={i}>
											<Grid item xs container direction="row">
												<Grid item xs={1} sm={1} md={2} />

												<Grid
													item
													xs={11}
													sm={11}
													md={10}
													className="payoff-one-text-container"
												>
													<Typography
														variant="h1"
														align="left"
														className="payoff-one-title h1"
													>
														{t(`home_carousel_1_title${i + 1}`)}
													</Typography>
												</Grid>
											</Grid>

											<Grid item xs container direction="row">
												<Grid item xs={1} sm={1} md={2} />
												<Grid
													item
													xs={11}
													sm={11}
													md={10}
													min-width="0"
													className="payoff-one-text-container"
												>
													<Typography
														variant="h5"
														className="payoff-one-text h7"
														align="justify"
														sx={{ py: '30px' }}
														paragraph
													>
														{t(`heroOT_carousel_1_desc${i + 1}`)}
													</Typography>
												</Grid>
											</Grid>
										</React.Fragment>
									)
								)}
							</Carousel>
						</div>
					</Grid>
				</Grid>
			)
		} else {
			// Render default content
			return (
				<>
					<Grid item xs container direction="row">
						<Grid item xs={1} sm={1} md={2} />

						<Grid
							item
							xs={11}
							sm={11}
							md={10}
							className="payoff-one-text-container"
						>
							<Typography
								variant="h1"
								align="left"
								className="payoff-one-title"
							>
								{t('home_title')}
							</Typography>
						</Grid>
					</Grid>

					<Grid item xs container direction="row">
						<Grid item xs={1} sm={1} md={2} />
						<Grid
							item
							xs={11}
							sm={11}
							md={10}
							min-width="0"
							className="payoff-one-text-container"
						>
							<Typography
								variant="h5"
								className="payoff-one-text"
								align="justify"
								sx={{ py: '30px' }}
								paragraph
							>
								{t('heroOT_desc')}
							</Typography>
						</Grid>
					</Grid>
				</>
			)
		}
	}

	return (
		<>
			<Grid container className="header" direction="row-reverse">
				<Grid
					item
					xs={0}
					sm={0}
					md={5.6}
					align={'right'}
					display={{ xs: 'none', sm: 'none', md: 'block' }}
				>
					<img
						src={payoffOneImage}
						alt="Improve your study"
						className="payoff-one-image"
					/>
				</Grid>

				<Grid
					item
					xs={1}
					sm={1}
					md={0}
					align={'right'}
					display={{ xs: 'block', sm: 'block', md: 'none' }}
				>
					<Paper />
				</Grid>
				<Grid item xs={11} sm={11} md={6.4}>
					<Grid item xs container direction="row">
						<Grid item xs={5.5} height="100%" align="left">
							<img src={footerImage} alt="logo" className="logo-image" />
						</Grid>

						<Grid item xs={6.5} height="100%" className="login-btn-container">
							<Link to="/login">
								<Button
									variant="outlined"
									color="inherit"
									className="login-btn h6"
								>
									Login
								</Button>
							</Link>
						</Grid>
					</Grid>
					<Grid item xs container direction="row">
						<Grid item xs={12} sm={12} md={12}>
							{/* Render content based on showCarousel */}
							{renderContent()}

							<Grid item xs container direction="row">
								<Grid item xs={1} sm={1} md={2}>
									<Paper />
								</Grid>

								<Grid item xs={2}>
									<Button
										disabled={isLoading}
										variant="contained"
										className="join-btn h9"
										style={{ minWidth: 'unset', padding: '1% 3%' }}
										onClick={handleSign}
									>
										{isLoading ? (
											<CircularProgress
												style={{ height: '20px', width: '20px' }}
												color="inherit"
											/>
										) : (
											t('hero_join_btn')
										)}
									</Button>
								</Grid>

								<Grid item xs={9} sm={9} md={8}>
									<input
										onInput={(e) => setEmail(e.target.value)}
										value={email}
										type="email"
										placeholder="Email"
										className="email-input h7"
										id="email"
										name="email"
									/>
								</Grid>
							</Grid>

							<Grid item xs container direction="row">
								<Grid item xs={12} height="20px">
									<Paper />
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<EmailPopup
					isopen={showModal}
					onClose={() => {
						setShowModal(false)
						setEmail('')
					}}
				/>
			</Grid>
		</>
	)
}

export default NavbarHPOT
