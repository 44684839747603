import { Fragment, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import LoadingIndicator from 'components/LoadingIndicator'
import LoginFooter from 'components/LoginFooter'
import { paypalCheck } from 'api/payment'
import useAuth from 'hooks/useAuth'
import { PaymentDoneContainer } from './paymentDonePageSkin'
import { Button, Grid, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import LogoContainer from 'shared/LogoContainer'
import { useCallback } from 'react'

const PaymentDone = () => {
	const { auth } = useAuth()
	const { t } = useTranslation()
	const [loading, setLoading] = useState(true)
	const history = useHistory()

	const payerId = new URLSearchParams(window.location.search).get('PayerID')
	const paymentId = new URLSearchParams(window.location.search).get('paymentId')

	const paypalHandle = useCallback(
		async (payerId, paymentId) => {
			try {
				const result = await paypalCheck({
					token: auth.token,
					body: {
						payerId: payerId,
						paymentId: paymentId
					}
				})
				return result
			} catch (error) {
				console.error('PayPal check failed:', error)
				throw error
			}
		},
		[auth]
	)

	useEffect(() => {
		const processPayment = async () => {
			if (payerId && paymentId) {
				try {
					await paypalHandle(payerId, paymentId)
					const currentUserData = JSON.parse(
						sessionStorage.getItem('user') || '{}'
					)
					currentUserData.expiration = false
					sessionStorage.setItem('user', JSON.stringify(currentUserData))
					setTimeout(() => {
						history.push('/subscription')
					}, 30000)
				} catch (error) {
					console.error('Payment processing failed:', error)
				}
			}
			setLoading(false)
		}

		processPayment()
	}, [payerId, paymentId, paypalHandle, history])

	if (loading) {
		return <LoadingIndicator />
	}

	return (
		<Fragment>
			<PaymentDoneContainer>
				<Grid container className="login-container loginP" columns={36}>
					<Grid item xs={6} sm={9} lg={12} xl={12} />
					<Grid item xs={24} sm={18} lg={12} xl={9} align="center">
						<Link to="/">
							<LogoContainer />
						</Link>
						<div className="login-input-container">
							<Typography
								variant="h4"
								component="h1"
								className="login-title h4"
							>
								{t('payment_confirmation')}
							</Typography>
							<br />
							<br />
							<Typography variant="body1" component="div" className="h7">
								<div className="pargraphs">{t('payment_confirmation_p1')}</div>
								<br />
								<div className="pargraphs">{t('payment_confirmation_p2')}</div>
								<br />
								<div className="pargraphs">{t('payment_confirmation_p3')}</div>
								<br />
							</Typography>

							<Typography
								variant="h4"
								component="h2"
								className="login-title h4"
							>
								{t('payment_success')}
							</Typography>
							<br />

							<Link to="/subscription">
								<div className="input-container">
									<Button variant="contained" className="login-btn h6">
										Go to the Subscriptions page!
									</Button>
								</div>
							</Link>
						</div>
					</Grid>
					<Grid item xs={6} sm={9} lg={12} xl={12} />
				</Grid>
				<br />
				<br />
			</PaymentDoneContainer>
			<LoginFooter />
		</Fragment>
	)
}

export default PaymentDone
