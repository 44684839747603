import styled from '@emotion/styled/macro'

export const PasswordRecoverySkin = styled.div`
	.recovery_container {
		display: flex;
		justify-content: center;
		margin-bottom: 2%;
		margin-top: 1%;
	}

	.logo-image {
		height: auto;
		width: 60%;
	}
	.recovery_input_container {
		border: 1px solid black;
		border-radius: 10px;
		padding: 5% 5% 5% 5%;
		text-align: left;
	}
	.login-labels {
		display: block;
		text-align: left;
		color: gray;
	}
	.change_email {
		display: block;
		text-align: center;
		color: gray;
	}
	.recovery_title {
		color: gray;
		text-align: center;
		margin-top: 2%;
		margin-bottom: 2%;
	}
	.input-container {
		padding-top: 2%;
		padding-bottom: 2%;
	}
	.recovery_input {
		width: 100%;
		padding-top: 7px;
		padding-bottom: 7px;
		border: 1px solid black;
		border-radius: 5px;
	}
	.login-btn-link {
		text-decoration: none;
	}
	.recovery_btn {
		background: #9292ff;
		color: white;
		width: 100%;
		border-radius: 5px;
		text-transform: inherit;
		border: 1px solid gray;
		margin-top: 3%;
	}

	.recovery_btn:hover {
		background: #9292ff;
	}
	.amazon_p {
		max-width: 311px;
		color: #707070;
	}
	.atlhas_p {
		margin: 2% 0;
		text-align: start;
		color: #707070;
	}
	.h6,
	.h4,
	.h7 {
		font-family: 'Arial';
	}
	input {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
`
