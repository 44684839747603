import styled from '@emotion/styled/macro'

export const CustomerReviewCon = styled.div`
	.react-multi-carousel-list {
	}

	.react-multiple-carousel__arrow {
		background: transparent;
	}

	.react-multiple-carousel__arrow:hover {
		background: transparent;
	}
	.react-multiple-carousel__arrow::before {
		display: none;
	}

	.react-multiple-carousel__arrow--left {
		position: absolute !important;
		left: -0.4%;
		z-index: 1;
		border: 1px solid grey;
		border-width: 0 10px 10px 0;
		display: inline-block;
		opacity: 0.8;
		cursor: pointer;
		-webkit-transform: rotate(135deg) scale(0.5);
		transform: rotate(135deg) scale(0.5);
		border-radius: 0px;
	}

	.react-multiple-carousel__arrow--right {
		position: absolute !important;
		z-index: 1;
		right: -0.4%;
		border: 1px solid grey;
		border-width: 0 10px 10px 0;
		display: inline-block;
		opacity: 0.8;
		cursor: pointer;
		-webkit-transform: rotate(-45deg) scale(0.5);
		transform: rotate(-45deg) scale(0.5);
		border-radius: 0px;
	}

	@media screen and (max-width: 800px) {
		.react-multiple-carousel__arrow--right {
			-webkit-transform: rotate(-45deg) scale(0.5);
			transform: rotate(-45deg) scale(0.4);
		}

		.react-multiple-carousel__arrow--left {
			-webkit-transform: rotate(135deg) scale(0.5);
			transform: rotate(135deg) scale(0.4);
		}
	}

	@media screen and (max-width: 600px) {
		.react-multiple-carousel__arrow--right {
			-webkit-transform: rotate(-45deg) scale(0.5);
			transform: rotate(-45deg) scale(0.3);
		}

		.react-multiple-carousel__arrow--left {
			-webkit-transform: rotate(135deg) scale(0.5);
			transform: rotate(135deg) scale(0.3);
		}
	}

	.review-item {
		width: 86%;
		border: 1px dashed gray;
		min-height: 50%;
		margin-top: 7%;
		margin-bottom: 7%;
		margin-left: 7%;
		padding: 1.5% 0.8% 1.5% 0.8%;
		height: 93%;
	}

	.reviewer-image {
		width: 40%;
		height: auto;
		display: flex;
		position: relative;
		justify-content: center;
		margin: auto;
	}

	.customer-name {
		text-align: center;
		margin-top: 8px;
	}
	.customer-country {
		text-align: center;
		padding-bottom: 3%;
	}

	.customer-review-text {
		text-align: center;
		padding-bottom: 3%;
		padding-top: 3%;
	}
`
