import styled from '@emotion/styled/macro'
import { Dialog } from '@mui/material'
import { h6FontSize, h7FontSize, h8FontSize } from '../../appSkin.js'

export const StyledDialog = styled(Dialog)`
	pointer-events: none;
	position: fixed;
	left: 0;
	top: 0;

	& .MuiDialog-paper {
		pointer-events: auto;
		width: 20%;
		max-height: ${(props) => (props.minimized ? '60px' : '80vh')};
		min-height: ${(props) => (props.minimized ? '60px' : '700px')};
		background: #555555;
		border-radius: 10px;
		resize: ${(props) => (props.minimized ? 'none' : 'both')};
		overflow: hidden;
		position: fixed;
		transform: none;
		margin: 0;
		left: 0;
		top: 0;
		bottom: 0;

		@media (max-width: 1536px) {
			width: 20%;
			min-height: ${(props) => (props.minimized ? '60px' : '700px')};
		}

		@media (max-width: 1400px) {
			width: 30%;
			min-height: ${(props) => (props.minimized ? '60px' : '600px')};
		}

		@media (max-width: 1200px) {
			width: 60%;
			min-height: ${(props) => (props.minimized ? '60px' : '500px')};
		}

		@media (max-width: 900px) {
			width: 75%;
			min-height: ${(props) => (props.minimized ? '60px' : '500px')};
		}

		@media (max-width: 600px) {
			width: 90%;
			min-height: ${(props) => (props.minimized ? '60px' : '400px')};
		}

		&::-webkit-scrollbar {
			width: 20px;
		}

		&::-webkit-scrollbar-track {
			box-shadow: inset 0 0 5px grey;
			border-radius: 10px;
			background: #d9d9d9;
		}

		&::-webkit-scrollbar-thumb {
			background: #555555;
			border-radius: 10px;
			border: 3px solid #d9d9d9;

			&:hover {
				background: #383737;
			}
		}
	}

	& .MuiBackdrop-root {
		pointer-events: none;
	}

	& .MuiDialog-container {
		align-items: flex-start;
		justify-content: flex-start;
	}
`

export const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: ${(props) => (props.minimized ? '60px' : '100%')};
	min-height: ${(props) => (props.minimized ? '60px' : '700px')};
	color: white;
	border-radius: 10px;
	cursor: ${(props) => (props.cursorStyle ? 'grabbing' : 'grab')};

	@media (max-width: 1536px) {
		width: 20;
		min-height: ${(props) => (props.minimized ? '60px' : '700px')};
	}

	@media (max-width: 1400px) {
		min-height: ${(props) => (props.minimized ? '60px' : '600px')};
	}

	@media (max-width: 1200px) {
		min-height: ${(props) => (props.minimized ? '60px' : '500px')};
	}

	@media (max-width: 900px) {
		min-height: ${(props) => (props.minimized ? '60px' : '500px')};
	}

	@media (max-width: 600px) {
		min-height: ${(props) => (props.minimized ? '60px' : '400px')};
	}

	.modal-header {
		display: flex;
		justify-content: ${(props) =>
			props.minimized ? 'space-between' : 'flex-end'};
		align-items: center;
		padding: 10px;
		background: #555555;
		border-radius: ${(props) => (props.minimized ? '10px' : '10px 10px 0 0')};
		height: 60px;

		@media (max-width: 600px) {
			height: 50px;
			padding: 8px;
		}

		.modal-title {
			font-weight: bold;
			color: white;
			margin-left: 10px;
			${h7FontSize}
		}

		.control-icons {
			display: flex;
			gap: 10px;

			svg {
				cursor: pointer;
				color: white;
				background: #555555;
				border-radius: 5px;
				padding: 2px;
				height: 45px;
				width: 45px;

				@media (max-width: 600px) {
					height: 30px;
					width: 30px;
				}
			}

			& > :nth-child(2) {
				background: #6a7ae9;
			}
		}
	}

	.modal-content {
		flex: 1;
		padding: 20px;
		overflow-y: auto;
		position: relative;

		@media (max-width: 600px) {
			padding: 15px;
		}

		&::-webkit-scrollbar {
			width: 20px;
		}

		&::-webkit-scrollbar-track {
			box-shadow: inset 0 0 5px grey;
			border-radius: 10px;
			background: #d9d9d9;
		}

		&::-webkit-scrollbar-thumb {
			background: #555555;
			border-radius: 10px;
			border: 3px solid #d9d9d9;

			&:hover {
				background: #383737;
			}
		}

		.message-container {
			display: flex;
			align-items: start;
			gap: 12px;
			margin-bottom: 16px;
			padding: 8px;
			border-radius: 20px;

			@media (max-width: 600px) {
				gap: 8px;
				margin-bottom: 12px;
				padding: 6px;
			}

			&.user-message-container {
				background: transparent;
				border: 1px solid #c0bfbf;
			}

			&.bot-message-container {
				background: #312f2f;
			}
		}

		.avatar {
			width: 32px;
			height: 32px;
			border-radius: 50%;
			background: #6a7ae9;
			display: flex;
			margin-top: 5px;
			align-items: center;
			justify-content: center;
			color: white;
			font-weight: bold;
			flex-shrink: 0;

			@media (max-width: 600px) {
				width: 28px;
				height: 28px;
				margin-top: 3px;
			}
		}

		.message-content {
			flex: 1;
			padding: 5px 12px;
			color: white;
			background: transparent;
			border: none;
			${h8FontSize}

			@media (max-width: 900px) {
				font-size: 16px;
				padding: 4px 10px;
			}

			@media (max-width: 600px) {
				font-size: 14px;
				padding: 3px 8px;
			}
		}
	}

	.modal-footer {
		padding: 20px;
		background: #555555;
		border-radius: 0 0 10px 10px;
		position: relative;

		@media (max-width: 600px) {
			padding: 15px;
		}

		.input-container {
			position: relative;
			width: 100%;
		}

		textarea {
			width: 100%;
			min-height: 104px;
			max-height: 200px;
			background: transparent;
			border: 1px solid #c0bfbf;
			outline: none;
			border-radius: 20px;
			${h8FontSize}
			color: white;
			padding: 10px;
			padding-right: 50px;
			resize: none;
			overflow-y: auto;
			transition: height 0.2s ease;
			font-weight: 400;
			-ms-overflow-style: auto;

			@media (max-width: 900px) {
				min-height: 80px;
				font-size: 14px;
				padding: 8px;
				padding-right: 45px;
			}

			@media (max-width: 600px) {
				min-height: 60px;
				font-size: 13px;
				padding: 6px;
				padding-right: 40px;
			}

			&::placeholder {
				color: #cecece;
				font-weight: 400;
			}

			&::-webkit-scrollbar {
				width: 10px;
				display: block;
			}

			&::-webkit-scrollbar-track {
				box-shadow: inset 0 0 5px grey;
				border-radius: 10px;
				background: #d9d9d9;
			}

			&::-webkit-scrollbar-thumb {
				background: #555555;
				border-radius: 10px;
				border: 2px solid #d9d9d9;

				&:hover {
					background: #383737;
				}
			}
		}

		.submit-button {
			position: absolute;
			right: 15px;
			top: 12px;
			transform: none;
			background: #6a7ae9;
			color: white;
			border: none;
			border-radius: 50%;
			width: 35px;
			height: 35px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			z-index: 1000;
			opacity: 1;
			pointer-events: auto;

			@media (max-width: 600px) {
				width: 30px;
				height: 30px;
				right: 10px;
				top: 10px;
			}

			&:hover {
				background: #4253c3;
			}

			svg {
				width: 20px;
				height: 20px;

				@media (max-width: 600px) {
					width: 16px;
					height: 16px;
				}
			}
		}

		.button-container {
			display: flex;
			gap: 10px;
			margin-top: 10px;
			justify-content: space-between;
		}

		.close-btn,
		.reset-btn {
			padding: 8px 12px;
			background: #6a7ae9;
			color: white;
			border: none;
			${h7FontSize}
			font-weight: bolder;
			border-radius: 10px;
			cursor: pointer;

			@media (max-width: 900px) {
				font-size: 16px;
				padding: 6px 10px;
			}

			@media (max-width: 600px) {
				font-size: 14px;
				padding: 4px 8px;
			}

			&:hover {
				background: #4253c3;
			}
		}
	}
`
