import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpBackend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

const apiKey = process.env.REACT_APP_I18NEXUS_API_KEY
const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`

const lan = navigator.language

if (lan === 'en-US') {
	localStorage.setItem('i18nextLng', 'en')
} else if (lan === 'de') {
	localStorage.setItem('i18nextLng', 'de')
} else if (lan === 'it') {
	localStorage.setItem('i18nextLng', 'it')
} else {
	localStorage.setItem('i18nextLng', 'en')
}

i18next
	.use(HttpBackend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: 'en',

		ns: ['default'],
		defaultNS: 'default',

		supportedLngs: ['en', 'it', 'de'],
		interpolation: {
			escapeValue: false // crucial for JSX elements rendering
		},
		backend: {
			loadPath: loadPath
		}
	})
