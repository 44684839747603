import styled from '@emotion/styled/macro'

export const PdfReaderContainer = styled.div`
	display: flex;
	width: 80%;
	border-right: 2px solid white;
	.control {
		transform: scale(1.5, 1.5);
		z-index: 5000;
		cursor: pointer;
		opacity: 0.2;
		color: #fff;
	}

	.control:hover {
		opacity: 1;
	}

	.control-left {
		position: fixed;
		top: 50%;
	}

	.control-right {
		position: fixed;
		right: 17%;
		top: 50%;
	}

	.control-down {
		position: absolute;
		left: 40%;
		bottom: 2%;
	}
	.control-up {
		position: absolute;
		left: 40%;
		top: 2%;
	}

	.pdf-page {
		// height:67vh;
		// overflow:hidden;
		behavior: 'smooth';
		position: relative;
		width: 100%;
	}
	.footer {
		width: 100%;
		color: #fff;
		text-align: center;
		background-image: linear-gradient(
			180deg,
			#6a7ae9 0%,
			#4253c3 83%,
			#2335a5 100%
		);
		display: flex;

		z-index: 250;
		position: absolute;
		bottom: 0px;
		height: 35px;
		border-radius: 0 0 0 18px;
	}

	.footer > p {
		width: 100%;
		font-weight: bold;
	}

	.footer > input {
		color: #000;
	}
	:focus {
		outline: none !important;
	}
`
