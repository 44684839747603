import { useState, useRef, useEffect } from 'react'
import Draggable from 'react-draggable'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import ArrowUpward from '@mui/icons-material/ArrowUpward'
import { StyledDialog, ModalContainer } from './llmModalSkin'
import { useTranslation } from 'react-i18next'

const DefaultMessages = [
	{
		id: 1,
		message:
			'Ubi major minor cessat, Ubi major minor cessat, Ubi major minor cessat, ...',
		messageType: 'user'
	},
	{
		id: 2,
		message:
			'Vuolsi così colà dove si puote ciò che si vuole, Vuolsi così colà dove si puote ciò che si vuole ...',
		messageType: 'bot'
	}
]

const LLMModal = ({ open, onClose }) => {
	const { t } = useTranslation()

	const [_, setCursorStyle] = useState(false)
	const [minimized, setMinimized] = useState(false)
	const [userInput, setUserInput] = useState('')
	const [messages, setMessages] = useState(DefaultMessages)
	const [position, setPosition] = useState({ x: 5, y: 120 })
	const contentRef = useRef(null)
	const textareaRef = useRef(null)

	useEffect(() => {
		if (open) {
			setMessages(DefaultMessages)
			setUserInput('')
			setMinimized(false)
			setPosition({ x: 5, y: 120 })
		}
	}, [open])

	const handleMouseDown = () => {
		setCursorStyle(true)
	}

	const handleMouseUp = () => {
		setCursorStyle(false)
	}

	const handleMinimize = () => {
		setMinimized(!minimized)
	}

	const handleInputChange = (e) => {
		setUserInput(e.target.value)

		e.target.style.height = 'auto'

		const currentHeight = e.target.scrollHeight

		const newHeight = Math.min(200, currentHeight)
		e.target.style.height = `${newHeight}px`

		if (currentHeight > 200) {
			e.target.style.overflowY = 'auto'
		} else {
			e.target.style.overflowY = 'hidden'
		}
	}

	const handleDragStop = (e, data) => {
		setPosition({ x: data.x, y: data.y })
	}

	const renderMessages = () => {
		return messages.map((message) => (
			<div
				key={message.id}
				className={`message-container ${message.messageType}-message-container`}
			>
				{message.messageType === 'user' && <div className="avatar">S</div>}
				<div className="message-content">{message.message}</div>
			</div>
		))
	}

	const handleReset = () => {
		setMessages([])
		setUserInput('')
	}

	return (
		<Draggable
			handle=".modal-header"
			onStart={handleMouseDown}
			onStop={(e, data) => {
				handleMouseUp()
				handleDragStop(e, data)
			}}
			position={position}
		>
			<StyledDialog
				open={open}
				onClose={onClose}
				hideBackdrop
				disableEnforceFocus
				minimized={minimized}
			>
				<ModalContainer
					open={open}
					onClose={onClose}
					hideBackdrop
					disableEnforceFocus
					disableAutoFocus
					disableEscapeKeyDown
					disablePortal
					onBackdropClick={(e) => e.stopPropagation()}
					onClick={(e) => e.stopPropagation()}
					minimized={minimized}
				>
					<div className="modal-header">
						{minimized && (
							<div className="modal-title">{t('Atlhas Tutor AI')}</div>
						)}
						<div className="control-icons">
							{minimized ? (
								<FullscreenIcon onClick={handleMinimize} />
							) : (
								<FullscreenExitIcon onClick={handleMinimize} />
							)}
						</div>
					</div>

					{!minimized && (
						<>
							<div className="modal-content" ref={contentRef}>
								{renderMessages()}
							</div>

							<div className="modal-footer">
								<div className="input-container">
									<textarea
										ref={textareaRef}
										value={userInput}
										onChange={handleInputChange}
										placeholder={t(
											'Atlhas Tutor at your disposal, how can I help you?'
										)}
									/>
									{userInput && (
										<button className="submit-button" onClick={() => {}}>
											<ArrowUpward fontSize="small" />
										</button>
									)}
								</div>
								<div className="button-container">
									<button className="close-btn" onClick={onClose}>
										{t('Close')}
									</button>
									<button className="reset-btn" onClick={handleReset}>
										{t('Reset')}
									</button>
								</div>
							</div>
						</>
					)}
				</ModalContainer>
			</StyledDialog>
		</Draggable>
	)
}

export default LLMModal
