import styled from '@emotion/styled/macro'

export const UserSkins = styled.div`
	min-height: 100vh;

	.container {
		width: 100%;
		padding: 0 30px !important;
	}

	.container-head {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0px 30px;
	}

	// .css-oxnz7e-MuiGrid-root {
	//   padding: 0 3% !important;
	// }

	// @media (max-width: 700px) {
	//   .css-1o7t51j-MuiGrid-root > .MuiGrid-item {
	//     padding-left: 0 !important;
	//   }
	//   .tutorial_logo {
	//     width: 50px;
	//     margin-left: 15px;
	//   }
	// }
`
