import { useState, useEffect } from 'react'
import { Button, CircularProgress, OutlinedInput } from '@mui/material'
import { useTranslation } from 'react-i18next'
import toast from 'react-hot-toast'

import { register } from '../../api/auth'
import EmailPopup from '../EmailPopup'
import { getActionIdFromURL } from '../../utils/common.util'

const SignUp = () => {
	const { t } = useTranslation()

	const [email, setEmail] = useState('')
	const [showModal, setShowModal] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [action_id, setActionId] = useState(null) // Declare a new state variable

	// Call the utility function inside a useEffect so it runs when the component mounts
	useEffect(() => {
		const actionIdFromURL = getActionIdFromURL() // Get action_id from URL
		setActionId(actionIdFromURL) // Set action_id to state
	}, [])

	const handleSign = async () => {
		setIsLoading(true)
		try {
			const payload = { email }

			if (action_id != null) {
				// Checks for both `null` and `undefined`
				payload.action_id = action_id
			}

			await register(payload)
			setIsLoading(false)
			setShowModal(true)
		} catch (error) {
			setEmail('')
			setIsLoading(false)

			if (error.response) {
				toast(error.response.data.message)
			} else if (error.request) {
				toast(error.request)
			} else {
				toast(error.message)
			}
		}
	}
	return (
		<div style={{ marginTop: '25px' }} className="input-container">
			<span className="login-acc-labels h6">{t('hasntAc')}</span>

			<div className="input-container">
				<span className="login-labels h6">{t('email_addr')}</span>
				<OutlinedInput
					value={email}
					className="login-input h7"
					type="email"
					id="email"
					name="email"
					onInput={(e) => setEmail(e.target.value)}
				/>
			</div>
			<Button
				disabled={email.length === 0}
				variant="contained"
				className="login-btn h6"
				onClick={() => handleSign()}
			>
				{isLoading ? (
					<CircularProgress
						style={{ height: '30px', width: '30px' }}
						color="inherit"
					/>
				) : (
					t('create_ac')
				)}
			</Button>

			{showModal && (
				<EmailPopup
					isopen={showModal}
					onClose={() => {
						setShowModal(false)
						setEmail('')
					}}
				/>
			)}
		</div>
	)
}

export default SignUp
