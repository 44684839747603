import { createContext, useState } from 'react'

const DeleteContext = createContext({})

export const DeleteNoteProvider = ({ children }) => {
	const [noteDeted, setNoteDeted] = useState({})

	return (
		<DeleteContext.Provider value={{ noteDeted, setNoteDeted }}>
			{children}
		</DeleteContext.Provider>
	)
}

export default DeleteContext
