import styled from '@emotion/styled'

export const SidebarContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	font-family: sans-serif;

	.navLink {
		padding: 7%;
		border-radius: 10px;
		background-color: #6a7ae9;
		border: 1px solid #707070;
		color: white;
		text-transform: capitalize;
		text-align: center;
		cursor: pointer;
	}

	.terminate {
		padding: 7% 1%;
	}

	.navLinkPassChange {
		padding: 7%;
		border-radius: 10px;
		background-color: #6a7ae9;
		border: 1px solid #707070;
		color: white;
		text-transform: capitalize;
		text-align: center;
		cursor: pointer;
		padding-left: 2%;
		padding-right: 2%;
	}

	.active {
		background-color: #a6b1ff !important;
	}

	.left {
		padding: 10px 7.69%;
		flex: 1;
		background: #374254;
	}

	.left .top {
		margin-bottom: 72px;
	}

	.left .top .h4 {
		color: #fff;
	}

	.left .bottom {
		display: flex;
		flex-direction: column;
		row-gap: 30px;
	}

	.left .bottom button {
		padding: 7%;
		border-radius: 10px;
		background-color: #6a7ae9;
		border: 1px solid #707070;
		color: white;
		text-transform: capitalize;
	}

	.right {
		flex-basis: 7.143%;
		background-image: linear-gradient(
			270deg,
			#6a7ae9 0%,
			#4253c3 77%,
			#2335a5 100%
		);
		border-radius: 0 18px 18px 0;
	}

	.left .top .h4 {
		text-align: center;
		text-transform: uppercase;
	}

	.left .top img {
		width: 100%;
	}
`
