import { useState } from 'react'
import QnaDrawer from 'components/QnaDrawer'
import QnaDisplay from 'components/QnaDisplay'
import { QnAContainer } from './mainQnaSkin'
import { useQuery } from 'react-query'
import { getQALeft } from 'api/qna'
import useAuth from 'hooks/useAuth'

const MainQnA = (props) => {
	const [showQnaPopup, setShowQnaPopup] = useState(false)
	const [selectedBook, setSelectedBook] = useState()
	const [bookTitles, setBookTitles] = useState()
	const [expanded, setExpanded] = useState()
	const [maximize, setMaximize] = useState(false)
	const {
		auth: { token }
	} = useAuth()

	const handleMinimize = () => {
		setMaximize(true)
		setShowQnaPopup(false)
	}

	const handleMaximize = () => {
		setMaximize(false)
		setShowQnaPopup(true)
	}

	const handleModalHide = () => {
		setShowQnaPopup(false)
		setMaximize(false)
		refetch()
	}

	const { data, isLoading, refetch } = useQuery(
		'qna-list',
		() => getQALeft(token),
		{
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			retry: false
		}
	)

	return (
		<QnAContainer>
			<QnaDrawer
				showQnaPopup={showQnaPopup}
				setShowQnaPopup={setShowQnaPopup}
				selectedBook={selectedBook}
				setSelectedBook={setSelectedBook}
				setBookTitles={setBookTitles}
				expanded={expanded}
				setExpanded={setExpanded}
				hide={handleModalHide}
				data={data}
				isLoading={isLoading}
			/>

			<QnaDisplay
				expanded={expanded}
				bookTitles={bookTitles}
				selectedBook={selectedBook}
				showQnaPopup={showQnaPopup}
				setShowQnaPopup={setShowQnaPopup}
				maximize={maximize}
				data={data}
				handleMinimize={handleMinimize}
				handleMaximize={handleMaximize}
				handleModalHide={handleModalHide}
				setExpanded={setExpanded}
				refetch={refetch}
			/>
		</QnAContainer>
	)
}

export default MainQnA
