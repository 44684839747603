import { Fragment } from 'react'
import CheckIcon from '@mui/icons-material/Check'
import { format, parseISO } from 'date-fns'
import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReferralTableSkin } from './referralTableSkin'

const ReferralTable = ({ refData, weeks }) => {
	const { t } = useTranslation()

	return (
		<Fragment>
			<Grid item xs={12}>
				<Typography variant="h2" className="h2">
					{t('ref_thanks')}
				</Typography>
				<Typography variant="body1" className="message h7">
					{weeks} {t('ref_additional_text_1')}
				</Typography>
			</Grid>

			{refData.length !== 0 && (
				<ReferralTableSkin>
					<thead>
						<tr>
							<th className="h7 left_align" scope="col">
								Invitee email
							</th>
							<th className="h7" scope="col">
								Date
							</th>

							<th className="h7" scope="col">
								Expired?
							</th>

							<th className="h7" colSpan={2} scope="col">
								Subscriptions
								<table width={'100%'} className="border-top">
									<tr className="text-center">
										<td width={'50%'}>Free</td>
										<td className="text-center" width={'50%'}>
											Payment
										</td>
									</tr>
								</table>
							</th>
							<th className="h7" scope="col">
								Weeks
								<br />
								granted
							</th>
						</tr>
					</thead>

					<tbody>
						{refData.map((data) => {
							return (
								<tr>
									<td className="h7 left_align">{data.invitee_email}</td>
									<td className="h7">
										{format(parseISO(data.invitee_startDate), 'dd MMMM yy')}
									</td>
									<td className="h7">
										<CheckIcon
											style={{
												opacity:
													new Date(data.invitee_expDate) < new Date() ? 1 : 0
											}}
										/>
									</td>
									<td className="h7" colSpan={2}>
										<table width="100%">
											<tr className="middle-tr">
												<td className="h7">
													<CheckIcon
														style={{
															opacity: data.invitee_verification ? 1 : 0
														}}
													/>
												</td>
												<td className="h7">
													<CheckIcon
														style={{
															opacity: data.invitee_bonusAwarded ? 1 : 0
														}}
													/>
												</td>
											</tr>
										</table>
									</td>
									<td className="h7">
										{data.invitee_bonusAwarded ? data.invitee_bonus : ' '}
									</td>
								</tr>
							)
						})}
					</tbody>
				</ReferralTableSkin>
			)}
		</Fragment>
	)
}

export default ReferralTable
