import styled from '@emotion/styled'

export const CheckoutModalSkin = styled.div`
	background-image: url(${(props) => props.background});
	background-repeat: no-repeat;
	background-size: cover;
	color: white;

	.alert-dialog-title {
		display: flex;
		justify-content: space-between;
	}
	.dialog-title-text {
		margin-top: auto;
		margin-bottom: auto;
		font-size: 35px;
		font-weight: bold;
	}
	.alert-dialog-description {
		color: white;
		font-size: 25px;
	}

	.content-flex {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	@media screen and (max-width: 1360px) and (max-height: 768px) {
		.dialog-title-text {
			font-size: 24px;
		}
		.alert-dialog-description {
			font-size: 17px;
		}
	}

	@media screen and (max-width: 1360px) and (max-height: 768px) {
		.dialog-title-text {
			font-size: 24px;
		}
		.alert-dialog-description {
			font-size: 17px;
		}
	}

	@media screen and (max-width: 1024px) {
		.dialog-title-text {
			font-size: 18px;
		}
		.alert-dialog-description {
			font-size: 13px;
		}
	}

	@media screen and (max-width: 800px) {
		.dialog-title-text {
			font-size: 14px;
		}
		.alert-dialog-description {
			font-size: 10px;
		}
	}

	.paymentOption {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.paymentOption div {
		text-align: center;
	}

	.subs_btn {
		color: #fff;
		min-width: 150px !important;
		background: #6a7ae9 !important;
		border: 2px solid #55657e !important;
		border-radius: 10px !important;
	}

	.proced {
		margin-top: auto;
		margin-bottom: 0.7%;
	}

	.methods {
		display: flex;
		flex: 1;
		justify-content: space-around;
		align-items: center;
	}

	.alert-dialog-description input {
		display: flex;
		flex: 1;
		justify-content: space-around;
		align-items: center;
	}

	.alert-dialog-description label {
		display: flex;
	}

	.input-center label {
		left: 43px !important;
		transform: translateX(-25px) scale(0.7);
	}

	[type='radio']:checked,
	[type='radio']:not(:checked) {
		display: none;
	}
	[type='radio']:checked + label,
	[type='radio']:not(:checked) + label {
		cursor: pointer;
		color: #666;
	}
	[type='radio']:checked + label:before,
	[type='radio']:not(:checked) + label:before {
		content: '';
		width: 25px;
		height: 25px;
		border: 1px solid #ddd;
		border-radius: 100%;
		background: #fff;
		display: inline-block;
	}
	[type='radio']:checked + label:after,
	[type='radio']:not(:checked) + label:after {
		content: '';
		width: 25px;
		height: 25px;
		display: inline-block;
		background: #f87da9;
		position: absolute;
		left: 0;
		border-radius: 100%;
		-webkit-transition: all 0.2s ease;
		transition: all 0.2s ease;
	}
	[type='radio']:not(:checked) + label:after {
		opacity: 0;
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	[type='radio']:checked + label:after {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(0.7);
	}
	.input-container {
		position: relative;
	}
`
