//Libraries
import ReactDOM from 'react-dom'
import { useParams } from 'react-router-dom'
import { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'

//Hooks
import useBook from 'hooks/useBook'
import useAuth from 'hooks/useAuth'

//Utils
import { getBookByIDB } from 'utils/idbIndexed'
import { findCommonSubstring } from 'utils/highlightSubstring'

//APis
import { getBook } from 'api/book'
import { getBookNoteByPageNumber } from 'api/note'

//Components
import LoadingIndicator from 'components/LoadingIndicator'
import PdfReader from 'components/PdfReader'
import CommentHolder from 'components/CommentHolder/CommentHolder'
import AlertBox from 'components/AlertBox'

//Styled components
import { LibraryDisplayContainer } from './libraryDisplaySkin'

const LibraryDisplay = ({
	book,
	setOnLoadBook,
	onLoadBook,
	bookTotalPage,
	setRefetchBookOnHandleChange,
	refetchBookOnHandleChange,
	currentOpenBookId,
	setHandleOnChangePage,
	setSelectedBook,
	setNextPageHandle,
	currentPage,
	setCurrentPage,
	setNextPageNumber,
	setBookTotalPage,
	setCurrentOpenBookId,
	nextPageNumber,
	setPreviousPageNumber,
	setPreviousPageHandle,
	activePageStatus,
	drawerToggle,
	bookTitleUpdated
}) => {
	const { bookInfo } = useBook()
	const { bookId } = useParams()
	const pdfReaderRef = useRef(null)
	const [bookListById, setBookListById] = useState()
	const [onChangeInput, setOnChangeInput] = useState()
	const [data, setData] = useState()
	const [isLoading, setIsLoading] = useState(false)
	const [notesByBookId, setNotesByBookId] = useState()
	const [pageHeight, setPageHeight] = useState(0)
	const [openAlertBox, setOpenAlertBox] = useState(false)
	const [comment, setComment] = useState('')
	const page = sessionStorage.getItem('page')
	const {
		auth: {
			token,
			user: { expiration }
		}
	} = useAuth()
	const commentsRef = useRef(null)
	const [bookIndexedDb, setbookIndexedDb] = useState()
	const { t } = useTranslation()

	useEffect(() => {
		if (bookId) {
			setSelectedBook(bookId)
			setBookListById(bookId)
			sessionStorage.setItem('selected-book', bookId)
			setCurrentOpenBookId(bookId)
		}

		return () => {
			setBookListById('')
			sessionStorage.removeItem('selected-book')
			setCurrentOpenBookId('')
			setSelectedBook('')
		}
	}, [bookId])

	useEffect(() => {
		if (bookListById) setData({ ...data, bookTitle: bookTitleUpdated })
	}, [bookTitleUpdated])

	useEffect(() => {
		if (bookInfo.bookId) {
			setSelectedBook(bookInfo.bookId)
			setBookListById(bookInfo.bookId)
			sessionStorage.setItem('selected-book', bookInfo.bookId)
			setCurrentOpenBookId(bookInfo.bookId)
		}

		return () => {
			setBookListById('')
			sessionStorage.removeItem('selected-book')
			setCurrentOpenBookId('')
			setSelectedBook('')
		}
	}, [bookInfo.bookId])

	const handleOnChange = (e) => {
		setOnChangeInput(e.target.value)
	}

	const getBookById = async () => {
		setIsLoading(true)
		const { data } = await getBook(bookListById, token)

		setData(data)
	}
	useEffect(() => {
		if (bookListById) {
			getBookById()
		}
	}, [bookListById])

	const setBookIdOnInputChange = async () => {
		await setBookListById(currentOpenBookId)
		await getBook(currentOpenBookId, token)
		setRefetchBookOnHandleChange(false)
		setIsLoading(false)
	}
	useEffect(() => {
		if (refetchBookOnHandleChange) {
			setBookIdOnInputChange()
		}
	}, [refetchBookOnHandleChange])

	const removeHighlight = () => {
		const replaceText = findCommonSubstring(
			sessionStorage.getItem('replaceableText'),
			sessionStorage.getItem('selectedText')
		)

		let parent = document.querySelector(sessionStorage.getItem('parent'))
		let startInd = Number(sessionStorage.getItem('startInd'))
		let endInd = Number(sessionStorage.getItem('endInd'))

		let start = parent.children[startInd]
		let end = parent.children[endInd]
		if (startInd > endInd) {
			let tmp = start
			start = end
			end = tmp
			startInd = Array.prototype.indexOf.call(parent.children, start)
			endInd = Array.prototype.indexOf.call(parent.children, end)
		}

		let current = start
		let currentIndx = startInd
		while (currentIndx <= endInd) {
			currentIndx++
			const text = current.textContent
			const modifiedText = text.replace(
				`<span class="highlight">${replaceText}</span>`,
				`${replaceText}`
			)
			current.innerHTML = modifiedText
			current = current.nextSibling
		}
		sessionStorage.removeItem('replaceableText')
		sessionStorage.removeItem('selectedText')
		sessionStorage.removeItem('start')
		sessionStorage.removeItem('end')
		sessionStorage.removeItem('parent')
		sessionStorage.removeItem('startInd')
		sessionStorage.removeItem('endInd')
	}

	const mouseUpEvent = (event) => {
		let selectedText = window.getSelection().toString()
		if (
			expiration &&
			(event.target.id === 'comments-container' || selectedText !== '')
		) {
			setOpenAlertBox(true)
		} else {
			if (window.getSelection().baseNode && selectedText) {
				let start = window.getSelection().anchorNode.parentElement
				let end = window.getSelection().focusNode.parentElement
				let parent = start.parentElement
				const parentSelector =
					parent.tagName.toLowerCase() +
					(parent.id ? '#' + parent.id : '') +
					(parent.className ? '.' + parent.className.split(' ').join('.') : '')
				let startInd = Array.prototype.indexOf.call(parent.children, start)
				let endInd = Array.prototype.indexOf.call(parent.children, end)
				sessionStorage.setItem('start', start)
				sessionStorage.setItem('end', end)
				sessionStorage.setItem('parent', parentSelector)
				sessionStorage.setItem('startInd', startInd)
				sessionStorage.setItem('endInd', endInd)

				if (parent.className !== 'react-pdf__Page__textContent textLayer') {
					return
				}

				if (
					end.parentElement.className !==
					'react-pdf__Page__textContent textLayer'
				) {
					return
				}

				if (startInd > endInd) {
					let tmp = start
					start = end
					end = tmp
					startInd = Array.prototype.indexOf.call(parent.children, start)
					endInd = Array.prototype.indexOf.call(parent.children, end)
				}

				let current = start
				let currentIndx = startInd

				while (currentIndx <= endInd) {
					currentIndx++
					// if (!current) {
					//   end = parent.children[len - 1];
					//   break;
					// }
					const text = current.textContent

					console.log('current', text)
					sessionStorage.setItem(
						'replaceableText',
						sessionStorage.getItem('replaceableText')
							? sessionStorage.getItem('replaceableText') + text
							: text
					)

					const currentText = findCommonSubstring(text, selectedText)

					const modifiedText = text.replace(
						currentText,
						`<span class="highlight">${currentText}</span>`
					)
					current.innerHTML = modifiedText
					current = current.nextSibling
				}
				sessionStorage.setItem('selectedText', selectedText)
				let deviceHeight =
					document.getElementsByClassName('react-pdf__Page')[0].offsetHeight
				document.getElementsByClassName('react-pdf__Page')[0].style.margin =
					'0 auto'

				let div = document.createElement('div')
				const updatedY = checkOverlapping(start.offsetTop, deviceHeight)
				ReactDOM.render(
					<CommentHolder
						bookTitle={data?.bookTitle}
						sidetwo={selectedText}
						deviceHeight={deviceHeight}
						currentHeight={deviceHeight}
						page={currentPage}
						notes={notesByBookId}
						start={startInd}
						end={endInd}
						x={1129}
						y={updatedY}
						selected={true}
						getNoteByBookPage={getNoteByBookPage}
						expiration={expiration}
						commentAdded={(keyword) => {
							setComment(keyword)
						}}
						removeHighlight={removeHighlight}
					/>,
					div
				)

				document.getElementById('comments-container').appendChild(div)
				window.getSelection().empty()
				window.getSelection().removeAllRanges()
			} else if (
				event.target.id === 'comments' ||
				event.target.id === 'comments-container'
			) {
				let xpos = event.pageX
				let deviceHeight =
					document.getElementsByClassName('react-pdf__Page')[0].offsetHeight
				let ypos = checkOverlapping(event.nativeEvent.offsetY, deviceHeight)
				let container = document.getElementById('comments-container')

				document.getElementsByClassName('react-pdf__Page')[0].style.margin =
					'0 auto'
				let div = document.createElement('div')
				ReactDOM.render(
					<CommentHolder
						x={xpos}
						deviceHeight={deviceHeight}
						currentHeight={deviceHeight}
						y={ypos}
						notes={notesByBookId}
						bookTitle={data?.bookTitle}
						page={currentPage}
						isShowModalOnSimpleNote
						getNoteByBookPage={getNoteByBookPage}
						expiration={expiration}
						commentAdded={(keyword) => {
							setComment(keyword)
						}}
					/>,
					div
				)

				container.appendChild(div)
			}
		}
	}
	const checkOverlapping = (ypos, currentHeight) => {
		let newY = ypos

		notesByBookId.forEach(({ note_anchor }) => {
			const { y, deviceHeight } = note_anchor
			const aboveY = newY - 20
			const belowY = newY + 20
			const responsiveY = y * (currentHeight / deviceHeight)
			if (
				responsiveY === newY ||
				(responsiveY >= aboveY && responsiveY <= belowY)
			) {
				const range = responsiveY + 20
				newY += Math.abs(range - newY)
			}
		})
		return newY
	}

	const getNoteByBookPage = async (page) => {
		try {
			const response = await getBookNoteByPageNumber(bookListById, page)
			setNotesByBookId(response)
		} catch (ex) {
			console.log('message::', ex)
		}
	}

	useEffect(() => {
		if (data) {
			setCurrentPage(data.page)
		}
	}, [data])

	useEffect(() => {
		// setIsLoading(true)
		async function fetchData() {
			if (bookListById) {
				//Moved here 'setNotesByBookId(null)' from the body of function getNoteByBookPage. The purpose is to refresh the information of a page about notes ONLY when the page is changed and NOT at every invocation of getNoteByBookPage (occurring also when running handleSave and handleUpdate
				setNotesByBookId(null)
				await getNoteByBookPage(currentPage)
			}
			if (currentPage) {
				if (currentPage) {
					setOnChangeInput(currentPage)
				}
			}
		}
		fetchData()
	}, [currentPage])

	useEffect(() => {
		const fetchBookFromIndexedDB = async () => {
			if (data) {
				const book = await getBookByIDB(data.id)
				setbookIndexedDb(book)
			}
		}
		fetchBookFromIndexedDB()
	}, [currentPage, data])
	const handleKeyPress = (e) => {
		if (e.keyCode === 13) {
			changePageOnInput(e)
		}
	}

	const handleOnBlur = (e) => {
		changePageOnInput(e)
	}

	const changePageOnInput = (e) => {
		setCurrentPage(
			bookTotalPage > e.target.value
				? parseInt(e.target.value)
				: parseInt(bookTotalPage)
		)
		setHandleOnChangePage(true)
		setBookTotalPage(bookTotalPage)
		setCurrentOpenBookId(book)
	}

	return (
		<LibraryDisplayContainer>
			<header id="header" className="App-header">
				<div className="left">
					<p>{data && data && data ? data.bookTitle : 'No Book Selected'}</p>
				</div>
				<div className="right">
					<p className="right-heading">COMMENTS</p>
					{/* <CreateComments bookId={bookListById} /> */}
				</div>
			</header>

			<div className="main-area">
				{isLoading && (
					<div className="loader">
						<LoadingIndicator display={'contents'} />
					</div>
				)}
				{data && notesByBookId && (
					<PdfReader
						setOnLoadBook={setOnLoadBook}
						setLoadedCompletely={() => setIsLoading(false)}
						book={data}
						notes={notesByBookId}
						pageHeight={pageHeight}
						mouseUpEvent={mouseUpEvent}
						setPageHeight={setPageHeight}
						setHandleOnChangePage={setHandleOnChangePage}
						nextPageNumber={nextPageNumber}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
						setPreviousPageNumber={setPreviousPageNumber}
						setPreviousPageHandle={setPreviousPageHandle}
						setNextPageHandle={setNextPageHandle}
						setNextPageNumber={setNextPageNumber}
						setBookTotalPage={setBookTotalPage}
						setCurrentOpenBookId={setCurrentOpenBookId}
						commentsRef={commentsRef}
						getNoteByBookPage={getNoteByBookPage}
						bookIndexedDb={bookIndexedDb}
						activePageStatus={activePageStatus}
						commentAdded={comment}
						resetComment={() => setComment('')}
						drawerToggle={drawerToggle}
					/>
				)}
				{/* {notesByBookId?.length>0 &&<Comments notes={notesByBookId} currentPage={currentPage} />} */}

				<div
					style={{
						width: '20%',
						cursor: 'copy',
						position: 'relative',
						height: 'auto',
						overflow: 'hidden'
					}}
					ref={commentsRef}
					id="comments"
				>
					<div
						id="comments-container"
						className="comments"
						onMouseUp={mouseUpEvent}
						style={{
							position: 'relative',
							zIndex: 1,
							width: '100%',
							height: pageHeight
						}}
					></div>
				</div>
			</div>

			<div id="tmp-modal-container"></div>
			<div id="footer" key={page} className="footer">
				<p>
					{isLoading ? (
						<>Loading...</>
					) : (
						<>
							Page:
							<input
								type={'number'}
								className="page-input"
								onChange={(e) => handleOnChange(e)}
								value={onChangeInput}
								style={{ width: '25px' }}
								min={1}
								id="page"
								name="page"
								max={bookTotalPage}
								onKeyDown={handleKeyPress}
								onBlur={handleOnBlur}
							/>{' '}
							of {bookTotalPage}
						</>
					)}
				</p>
			</div>
			<AlertBox
				open={openAlertBox}
				description={t('subscriptionNoteAdd')}
				setOpenAlertBox={setOpenAlertBox}
				title="Subscription Expired"
			/>
		</LibraryDisplayContainer>
	)
}

export default LibraryDisplay
