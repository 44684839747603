import { useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
	Button,
	CircularProgress,
	Grid,
	InputAdornment,
	OutlinedInput,
	Typography
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { useTranslation } from 'react-i18next'
import { NavLink, useHistory, Link } from 'react-router-dom'
import { PasswordRecoverySkin } from './skins'
// import SignUp from '../SignUp/SignUp'
import { login } from 'api/auth'
import useAuth from 'hooks/useAuth'
import LogoContainer from 'shared/LogoContainer'
import Toast from 'shared/Toast'

const LoginFormClosedTesting = () => {
	const { t } = useTranslation()
	const history = useHistory()

	const { setAuth } = useAuth()

	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [showPassword, setShowPassword] = useState(false)
	const [toastMessage, setToastMessage] = useState('')
	const [toastType, setToastType] = useState('')
	const [toastOpen, setToastOpen] = useState(false)

	// Snakc
	const [buttonLoading, setButtonLoading] = useState(false)

	const handleLogin = async () => {
		setButtonLoading(true)
		try {
			const { data } = await login({
				email,
				password
			})

			setAuth({
				token: data.token,
				user: data.user
			})

			sessionStorage.setItem('token', data.token)
			sessionStorage.setItem('user', JSON.stringify(data.user))

			history.replace('/dashboard')
		} catch (error) {
			setToastOpen(true)
			setToastType('error')
			if (error.response) {
				setToastMessage(error.response.data.message)
			} else if (error.request) {
				setToastMessage(error.request)
			} else {
				setToastMessage(error.message)
			}

			setEmail('')
			setPassword('')
			setButtonLoading(false)
		}
	}

	const toastClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}

		setToastOpen(false)
	}

	return (
		<PasswordRecoverySkin>
			<Grid container className="login-container loginP" columns={36}>
				<Grid item xs={6} sm={9} lg={12} xl={12} />

				<Grid item xs={24} sm={18} lg={12} xl={9} align="center">
					<Link to="/">
						<LogoContainer />
					</Link>
					<div className="login-input-container">
						<Typography variant="h4" className="login-title h4">
							{t('login_text')}
						</Typography>
						<div className="input-container">
							{/* <span className="login-labels h6">{t("email_addr")}</span> */}
							<span className="login-labels h6">{t('Login_EmailBox')}</span>
							<OutlinedInput
								className="login-input h7"
								type="text"
								id="username"
								name="username"
								value={email}
								onInput={(e) => setEmail(e.target.value)}
								aria-label="Email Address"
								data-testid="email-input"
							/>
						</div>

						<div className="input-container">
							<span className="login-labels h6">{t('pwd')}</span>

							<OutlinedInput
								className="login-input h7"
								type={showPassword ? 'text' : 'password'}
								value={password}
								id="password"
								name="password"
								onInput={(e) => setPassword(e.target.value)}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={() => setShowPassword(!showPassword)}
											edge="end"
										>
											{showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								}
							/>
						</div>

						<div className="input-container">
							<Button
								onClick={handleLogin}
								variant="contained"
								className="login-btn h6"
								disabled={buttonLoading}
							>
								{buttonLoading ? (
									<CircularProgress
										style={{ height: '28px', width: '28px' }}
										color="inherit"
										data-testid="loading-spinner"
									/>
								) : (
									t('login_text')
								)}
							</Button>
						</div>

						<div className="input-container">
							<span className="policy-text h7">{t('policy')}</span>
						</div>
						<div className="input-container">
							<NavLink
								style={{ textDecoration: 'none', cursor: 'pointer' }}
								to="/forgot-password"
							>
								<span className="login-labels h6">{t('forgot')}</span>
							</NavLink>
						</div>
					</div>

					{/* <SignUp /> */}
				</Grid>
				<Grid item xs={6} sm={9} lg={12} xl={12} />
			</Grid>

			<Toast
				toastClose={toastClose}
				open={toastOpen}
				severity={toastType}
				message={toastMessage}
			/>
		</PasswordRecoverySkin>
	)
}

export default LoginFormClosedTesting
