export const setItemInLocalStorage = (key, data) => {
	localStorage.setItem(key, data)
}

export const getItemFromLocalStorage = (key) => {
	return localStorage.getItem(key)
}
export const removeItemFromLocalStorage = (key) => {
	localStorage.removeItem(key)
}
