import { createContext, useState } from 'react'

const BookInfoContext = createContext({})

export const BookInfoProvider = ({ children }) => {
	const [bookInfo, setBookInfo] = useState({})

	return (
		<BookInfoContext.Provider value={{ bookInfo, setBookInfo }}>
			{children}
		</BookInfoContext.Provider>
	)
}

export default BookInfoContext
