import { Fragment, useEffect } from 'react'
import LoginFooter from '../../components/LoginFooter'
import LoginForm from '../../components/LoginForm'
import useAuth from '../../hooks/useAuth'
import { useHistory } from 'react-router-dom'

// * I've to replace it in future....
const LoginPage = () => {
	const { auth } = useAuth()
	const history = useHistory()

	useEffect(() => {
		if (auth.token) {
			history.push('/dashboard')
		}
	}, [auth, history])

	return (
		<Fragment>
			<LoginForm />
			<LoginFooter />
		</Fragment>
	)
}

export default LoginPage
