import styled from 'styled-components/macro'
import logoImage from 'assets/logo.png'

const AtlhasIcon = styled.img`
	width: 50%;
	cursor: pointer;
`

AtlhasIcon.defaultProps = {
	src: logoImage
}

const LogoContainer = () => {
	return <AtlhasIcon />
}

export default LogoContainer
