import styled from '@emotion/styled/macro'
import { h4FontSize, h7FontSize } from '../../appSkin.js'

export const ConsentContainer = styled.div`
	.cookie-consent-banner {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: #f8f9fa;
		box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
		color: black;
		padding: 15px;
		${h7FontSize}
		text-align: center;
		z-index: 1000;
	}

	.cookie-consent-banner p {
		padding: 20px 0;
	}

	.cookie-consent-banner h3 {
		${h4FontSize}
	}

	.cookie-consent-button {
		border: none;
		padding: 8px 16px;
		text-align: center;
		text-decoration: none;
		display: inline-block;

		${h7FontSize}
		margin: 4px 2px;
		cursor: pointer;
		border-radius: 4px;
	}

	.cookie-consent-button:hover {
		box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
	}

	.cookie-consent-button:active {
		opacity: 0.5;
	}

	.cookie-consent-button.btn-success {
		background-color: #34a853;
		color: white;
	}

	.cookie-consent-button.btn-grayscale {
		background-color: #dfe1e5;
		color: black;
	}

	.cookie-consent-button.btn-outline {
		background-color: #e6f4ea;
		color: #34a853;
	}
	.cookie-policy {
		text-decoration: underline;
		cursor: pointer;
	}

	.cookie-consent-options {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin: 10px 0px;
	}

	.cookie-consent-options label {
		margin: 0 10px;
		${h7FontSize}
	}

	.cookie-consent-options input {
		margin-right: 5px;
	}
`
