import styled from '@emotion/styled/macro'

export const PaymentDoneContainer = styled.div`
	min-height: 100vh;

	.login-container {
		display: flex;
		justify-content: center;
		margin-bottom: 2%;
		margin-top: 1%;
	}
	.logo-image {
		height: auto;
		width: 50%;
	}
	.login-input-container {
		border: 1px solid black;
		border-radius: 10px;
		padding: 5% 5% 5% 5%;
		text-align: left;
	}
	.login-labels {
		display: block;
		text-align: left;
		color: gray;
	}
	.login-acc-labels {
		display: block;
		text-align: left;
		color: gray;
		margin-left: 5%;
	}
	.login-title {
		color: gray;
		text-align: center;
		margin-top: 2%;
		margin-bottom: 2%;
	}
	.input-container {
		padding-top: 2%;
		padding-bottom: 2%;
	}
	.login-input {
		width: 100%;
		padding-top: 7px;
		padding-bottom: 7px;
		border: 1px solid black;
		border-radius: 5px;
	}
	.login-btn-link {
		text-decoration: none;
	}
	.login-btn {
		background: #9292ff;
		color: white;
		width: 100%;
		border-radius: 5px;
		text-transform: inherit;
		border: 1px solid gray;
		margin-top: 3%;
	}
	.login-btn:hover {
		background: #9292ff;
	}
	.policy-text {
		display: block;
		text-align: left;
		padding-top: 2%;
		padding-bottom: 2%;
		color: gray;
	}
	.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
	.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.Mui-focused {
		outline: none;
		border-color: white;
	}

	.btnColor {
		background-color: #9ea5dc;
		text-transform: none !important;
		color: white;
		width: 100%;
		text-decoration: none !important;
	}
	.pargraphs {
		text-align: left;
		color: #707070;
	}
`
