import { forwardRef } from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import { AlertContainer } from './toastSkin'

const Alert = forwardRef(function Alert(props, ref) {
	return (
		<AlertContainer>
			<MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
		</AlertContainer>
	)
})

const Toast = ({ open, toastClose, severity, message }) => {
	return (
		<Snackbar open={open} autoHideDuration={6000} onClose={toastClose}>
			<Alert onClose={toastClose} severity={severity} sx={{ width: '100%' }}>
				{message}
			</Alert>
		</Snackbar>
	)
}

export default Toast
