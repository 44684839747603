import styled from '@emotion/styled/macro'

export const UpdateBookModalForm = styled.form`
	padding: 3%;
	background: #2a333a;
	color: #fff;
	overflow: hidden;

	.input-box {
		padding: 1.5% 1.5625%;
		border: 1px solid #fff;
		background: transparent;
		width: 100%;
		margin: 1.6% 0;
		color: #fff;
	}

	.modal_top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		margin-bottom: 0%;
		margin-top: -1.5%;

		.close-icon {
			margin-left: 2.7%;
			cursor: pointer;
			background-color: #6a7ae9;
			border-radius: 5px;
		}

		.custom-file-upload {
			display: inline-block;
			cursor: pointer;
			width: 100%;
			border: 1px solid #fff;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 1.2% 2%;
		}

		input[type='file'] {
			display: none;
		}
	}

	.text-box {
		padding: 2% 2.7%;
		border: 1px solid #6a7ae9;
		background: transparent;
		width: 100%;
		resize: none;
		color: #fff;
		outline: none;
	}

	.top {
		width: 100%;
		height: 2.7%;
		background-image: linear-gradient(
			180deg,
			#6a7ae9 0%,
			#4253c3 83%,
			#2335a5 100%
		);
		border-top-left-radius: 25px;
		border-top-right-radius: 25px;
		margin-top: 1.3%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.bottom {
		width: 100%;
		height: 2.7%;
		background-image: linear-gradient(
			180deg,
			#6a7ae9 0%,
			#4253c3 83%,
			#2335a5 100%
		);
		border-bottom-left-radius: 25px;
		border-bottom-right-radius: 25px;
		margin-bottom: 2.7%;
		margin-top: -1%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.note_buttons_container {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.note {
		color: #e9bb6a;
		margin-right: 10%;
	}

	.font {
		font-family: 'Times New Roman' !important;
	}

	.scrollbar {
		overflow: hidden;
	}

	.modal_bottom {
		.buttons {
			display: flex;
			align-items: center;
			justify-content: right;
		}

		button {
			border-radius: 10px;
			text-transform: capitalize;

			background-image: linear-gradient(
				180deg,
				#6a7ae9 0%,
				#4253c3 83%,
				#2335a5 100%
			);
			color: #fff;
			font-family: sans-serif;
			padding: 50 20.25%;

			height: 5.5%;
			outline: none;
			border: 0;
			margin: 0% 5%;
			cursor: pointer;
		}
	}
`
