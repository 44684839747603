import styled from '@emotion/styled'

export const ReferralTableSkin = styled.table`
	color: white !important;
	overflow: hidden;
	border-radius: 15px 15px 0 0;
	width: 100%;
	border: none;
	border-spacing: 0;

	thead,
	thead th {
		text-align: center;
		background-color: #a6b1ff;
		border-collapse: separate;
		height: 70px !important;
		color: #000;
		font-weight: bold !important;
	}

	tr td,
	tr th,
	.text-center {
		text-align: center;
	}

	.border-top {
		border-top: 2px solid #000;
	}

	tbody tr:nth-of-type(odd) {
		border: none;
		border-spacing: 0;
		height: 50px;
		background: #d9e0f2 !important;
		color: #000;
	}

	tbody tr:nth-of-type(even) {
		border: none;
		border-spacing: 0;
		height: 50px;

		.middle-tr {
			background: #2a333a !important;
			color: #fff;
		}
	}

	.left_align {
		padding-left: 2%;
	}

	.icon-hidden: {
		opacity: 0;
	}
`
