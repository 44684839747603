import { useEffect, useRef, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Button, Typography } from '@mui/material'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import LoadingIndicator from '../LoadingIndicator'
import './style.css'
import { getBookList, deleteBook } from 'api/book'
import useAuth from 'hooks/useAuth'
import { queryClient } from 'App'
import { useStyles } from './style.js'
import { useTranslation } from 'react-i18next'
import { deleteSingleBookFromIndexedDB } from 'utils/idbIndexed'

const VerticalCarouselForBook = ({
	userdashboardData,
	isQuestionBooksLoading
}) => {
	const {
		auth,
		auth: { token }
	} = useAuth()
	const [
		bookDataWithCompletionPercentage,
		setBookDataWithCompletionPercentage
	] = useState([])

	const { isLoading, data, refetch, isFetching } = useQuery(
		['books-list', auth.user.id],
		() => getBookList(auth.token),
		{
			refetchOnWindowFocus: false,
			retry: false,
			enabled: Boolean(auth.user.id)
		}
	)
	const classes = useStyles()
	const { t } = useTranslation()

	const getNewData = () => {
		if (
			userdashboardData &&
			userdashboardData &&
			userdashboardData.length > 0 &&
			data &&
			data.length > 0
		) {
			let newData = []
			data.forEach((x) => {
				userdashboardData.forEach((y) => {
					if (x.book_title === y.title) {
						newData.push({
							book_author: x.book_author,
							book_title: x.book_title,
							_id: x._id,
							completionPercentage: y.completionPercentage.toFixed()
						})
					}
				})
			})

			setBookDataWithCompletionPercentage([...newData])
		}
	}
	useEffect(() => {
		getNewData()
	}, [userdashboardData, setBookDataWithCompletionPercentage, data])

	const handleDelete = async (id) => {
		Swal.fire({
			title: t('Are you sure to Delete?'),
			text: t("You won't be able to revert this!"),
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!',
			preConfirm: () => {
				return deleteBook(id, token)
					.then((response) => {
						if (response.error) {
							throw new Error(response.error.response.data.message)
						}
						return response.data.message
					})
					.catch((error) => {
						Swal.showValidationMessage(`Request failed: ${error}`)
					})
			},
			allowOutsideClick: () => !Swal.isLoading()
		}).then((result) => {
			if (result.isConfirmed) {
				queryClient.invalidateQueries('books-list')
				queryClient.removeQueries(['book', id])

				Swal.fire({
					position: 'center',
					icon: 'success',
					title: 'Deleted!',
					html: 'Your file has been deleted.',
					showConfirmButton: false,
					timer: 1500
				})
				refetch()
			}
		})
		await deleteSingleBookFromIndexedDB(id)
	}

	const bodyRef = useRef()
	function scrollHandler(role) {
		const tBody = bodyRef.current
		if (role === 'pre') {
			tBody.scrollTop -= 48
		} else {
			tBody.scrollTop += 48
		}
	}

	return (
		<div className="container bookTable">
			<table>
				<thead>
					<tr>
						<td className="h4 Book Title">{t('book_title')}</td>
						<td style={{ justifyContent: 'center' }}>
							<Button onClick={() => scrollHandler('pre')}>
								<ExpandLessIcon />
							</Button>
						</td>
						<td className="titleOpt">
							<span className="h7">%</span>
						</td>
					</tr>
				</thead>
				<tbody ref={bodyRef}>
					{isLoading || isQuestionBooksLoading || isFetching ? (
						<tr className="loader-tr">
							<td colSpan={3}>
								<LoadingIndicator
									display="block"
									width="100%"
									marginTop="10px"
								/>
							</td>
						</tr>
					) : bookDataWithCompletionPercentage &&
					  bookDataWithCompletionPercentage.length > 0 ? (
						bookDataWithCompletionPercentage
							?.sort((a, b) => {
								const aTitle = a.book_title.toLowerCase()
								const bTitle = b.book_title.toLowerCase()
								if (aTitle > bTitle) {
									return 1
								} else if (aTitle < bTitle) {
									return -1
								} else {
									return 0
								}
							})
							?.map((book, index) => (
								<tr key={index}>
									<td style={{ flex: 1, gap: '3%' }} className="h7">
										<Button
											onClick={() => handleDelete(book._id)}
											sx={{
												padding: 0,
												minWidth: 'unset',
												color: 'white',
												cursor: 'pointer'
											}}
										>
											<DeleteIcon />
										</Button>
										<Link
											to={`/library/${book._id}`}
											style={{ cursor: 'pointer' }}
										>
											{book.book_title}
										</Link>
									</td>
									<td className="titleOpt">
										<span style={{ width: 'unset' }} className="h7">
											{book.completionPercentage}%
										</span>
									</td>
								</tr>
							))
					) : (
						<tr className="loader-tr empty-message">
							<td>
								<Typography className={`h4 ${classes.font}`}>
									{' '}
									{t('empty_book_title')}{' '}
								</Typography>
							</td>
						</tr>
					)}
				</tbody>
				<tfoot>
					<tr>
						<td></td>
						<td>
							<Button onClick={() => scrollHandler('next')}>
								<ExpandMoreIcon />
							</Button>
						</td>
						<td></td>
					</tr>
				</tfoot>
			</table>
		</div>
	)
}

export default VerticalCarouselForBook
