// /src/store/reducers/index.js
import { combineReducers } from '@reduxjs/toolkit'
import authReducer from './authReducer'
import showCarouselHPOTReducer from './showCarouselHPOTReducer'

// Import additional reducers here
// import videoConfigReducer from './videoConfigSlice';
// import registrationFormReducer from './registrationFormSlice';

const rootReducer = combineReducers({
	auth: authReducer,
	showCarouselHPOT: showCarouselHPOTReducer
	// Add other reducers here
	// videoConfig: videoConfigReducer,
	// registrationForm: registrationFormReducer,
})

export default rootReducer
