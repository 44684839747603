import { Link, useParams } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import { EarlyRegistrationContainer } from './earlyRegPageSkin'
import { Grid, Typography } from '@mui/material'
import LogoContainer from 'shared/LogoContainer'
import { useTranslation } from 'react-i18next'
import { saveUserEmail } from 'api/preMktg'
import { useState } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import { useHistory } from 'react-router-dom'
import LoginFooterLanding from 'components/LoginFooterLanding'

const EarlyRegistrationPage = () => {
	const { t } = useTranslation()
	const { token } = useParams()
	const [isLoading, setIsLoading] = useState(false)
	const history = useHistory()
	const { id, email } = jwt_decode(token)

	const saveEmail = async () => {
		try {
			setIsLoading(true)
			await saveUserEmail(id)
			history.push('/mktg/pmuc01')
		} catch (ex) {
			console.log('something went wrong')
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<div
			data-testid="early-registration-container"
			style={{
				minHeight: '100vh',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between'
			}}
		>
			<EarlyRegistrationContainer>
				<Grid container className="signup-container">
					<Grid item xs={2} sm={4} lg={4} xl={4} />

					<Grid item xs={8} sm={4} lg={4} xl={3} align="center">
						<Link to="/">
							<LogoContainer />
						</Link>

						<div className="signup-input-container">
							<Typography variant="h4" className="signup-title h4">
								{t('confirm_your_email')}
							</Typography>

							<div className="input-container">
								<span className="signup-labels h6">{t('email_address')}</span>
							</div>

							<div className="input-container">
								<span className="policy-text h7">{email}</span>
							</div>

							<div className="input-container">
								<span className="policy-text h7">{t('reg_mktg_text')}</span>
							</div>

							<div className="input-container">
								<LoadingButton
									variant="contained"
									loading={isLoading}
									className="signup-btn h6"
									onClick={saveEmail}
								>
									{t('save_email')}
								</LoadingButton>
							</div>
						</div>
					</Grid>
					<Grid item xs={2} sm={4} lg={4} xl={4} />
				</Grid>
			</EarlyRegistrationContainer>
			<LoginFooterLanding />
		</div>
	)
}

export default EarlyRegistrationPage
