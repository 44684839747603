//Libraries
import React, { useState, useEffect } from 'react'
import { Checkbox, FormControlLabel } from '@mui/material'

//Styled component
import {
	RegisterPolicyConsentSkin,
	RequiredBadge,
	CustomGridBadge,
	CustomGridConsent,
	CustomGrid
} from './RegisterPolicyConsentSkin'

//Components
import AlertBox from '../AlertBox'

//APIs
import { retrieveConsent } from '../../api/consent'

const RegisterPolicyConsent = ({ id, onConsentChange }) => {
	const [consents, setConsents] = useState([])
	const [checkedStates, setCheckedStates] = useState({})
	const [consentIds, setConsentIds] = useState([])
	const [allRequiredConsentsGiven, setAllRequiredConsentsGiven] =
		useState(false)
	const [showAlert, setShowAlert] = useState(false)
	const [hasScrolledToEnd, setHasScrolledToEnd] = useState(false)

	useEffect(() => {
		const handleScroll = () => {
			const bottomReached =
				window.innerHeight + window.scrollY >= document.body.offsetHeight
			if (bottomReached) {
				setHasScrolledToEnd(true)
			}
		}
		window.addEventListener('scroll', handleScroll)

		// Fetch consents from the backend
		const fetchConsents = async (id) => {
			try {
				const data = await retrieveConsent(id)
				//data = [{"consent_i18Value": "...", "consentId": "...", "consentRequirement": true/false}, {...}]

				//************************************** */
				// console.log('RegisterPolicyConsent launched')
				// console.log('from API retrieveConsent, data =', data)
				//************************************** */

				const sortedConsents = data.sort(
					(a, b) => b.consentRequirement - a.consentRequirement
				) // Sorting based on consentRequirement

				setConsents(sortedConsents)
			} catch (error) {
				console.error({
					function: 'fetchconsents',
					message: error.message
				})
			}
		}
		fetchConsents(id)

		return () => window.removeEventListener('scroll', handleScroll)
	}, [id]) // Added dependency array

	// Handles checkbox state changes
	const handleCheckboxChange = (consent, index) => {
		//**************************************************** */
		// console.log('consent = ', consent)
		//**************************************************** */

		const updatedCheckedStates = {
			...checkedStates,
			[index]: !checkedStates[index]
		}

		//**************************************************** */
		// console.log('updatedCheckedStates = ', updatedCheckedStates)
		//**************************************************** */

		// Update the consentIds array
		const updatedConsentIds = updatedCheckedStates[index]
			? [...consentIds, consent.consentId]
			: consentIds.filter((id) => id !== consent.consentId)

		//**************************************************** */
		// console.log('updatedConsentIds = ', updatedConsentIds)
		//**************************************************** */

		// Determine if all required consents given
		const updatedAllRequiredConsentsGiven = consents.every(
			(consent, i) => !consent.consentRequirement || updatedCheckedStates[i]
		)

		//**************************************************** */
		// console.log(
		// 	'updatedAllRequiredConsentsGiven = ',
		// 	updatedAllRequiredConsentsGiven
		// )
		//**************************************************** */

		// Update the state variables
		setConsentIds(updatedConsentIds)
		setCheckedStates(updatedCheckedStates)
		setAllRequiredConsentsGiven(updatedAllRequiredConsentsGiven)

		// Send updated consentIds to parent component
		onConsentChange(updatedAllRequiredConsentsGiven, updatedConsentIds)
	}

	// Render the consent checkboxes dynamically
	const renderConsentCheckboxes = () => {
		return consents.map((consent, index) => (
			<React.Fragment key={index}>
				<CustomGridBadge
					item
					xs={12}
					style={{
						height: consent.consentRequirement ? '2rem' : '0',
						paddingTop: consent.consentRequirement ? '24px' : '0'
					}}
				>
					{/* Increase vertical space for badges */}
					{consent.consentRequirement && (
						<RequiredBadge badgeContent="Required" />
					)}
				</CustomGridBadge>
				<CustomGridConsent item xs={12}>
					<FormControlLabel
						control={
							<Checkbox
								checked={!!checkedStates[index]}
								onChange={() => handleCheckboxChange(consent, index)}
								disabled={!hasScrolledToEnd && consent.consentRequirement}
							/>
						}
						className="h9-label overlay"
						label={consent.consent_i18Value}
						onClick={() => {
							if (!hasScrolledToEnd && consent.consentRequirement) {
								setShowAlert(true)
							}
						}}
					/>
				</CustomGridConsent>
			</React.Fragment>
		))
	}

	return (
		<RegisterPolicyConsentSkin>
			<CustomGrid container spacing={2}>
				{renderConsentCheckboxes()}
			</CustomGrid>
			<AlertBox
				open={showAlert}
				setOpenAlertBox={setShowAlert}
				description="You must scroll to the end of the page to give consent." // This should be adjusted according to your actual content or use a fixed translation string if available.
			/>
		</RegisterPolicyConsentSkin>
	)
}

export default RegisterPolicyConsent
