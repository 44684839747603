import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles({
	modalBar: {
		justifyContent: 'center !important'
	},
	modalContainer: {
		// rowGap: "10px",
		marginTop: '0 !important'
	},

	emptyTableGridMobile: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '25%'
	},

	emptyTableGridWeb: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '10%'
	},
	top: {
		width: '100%',
		height: '15%',
		backgroundImage:
			'linear-gradient(\r\n    180deg,\r\n    #6a7ae9 0%,\r\n    #4253c3 83%,\r\n    #2335a5 100%\r\n  )',
		borderTopLeftRadius: '25px',
		borderTopRightRadius: '25px',
		marginTop: '1.3%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	bottom: {
		width: '100%',
		height: '15%',
		backgroundImage:
			'linear-gradient(\r\n    180deg,\r\n    #6a7ae9 0%,\r\n    #4253c3 83%,\r\n    #2335a5 100%\r\n  )',
		borderBottomLeftRadius: '25px',
		borderBottomRightRadius: '25px',
		marginBottom: '2.7%',
		marginTop: '-1%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},

	scrollbar: {
		overflow: 'hidden'
	},
	text_box: {
		padding: '2% 2.7%',
		border: '1px solid #6A7AE9',
		background: 'transparent',
		width: '100%',
		resize: 'none',
		color: '#fff',
		outline: 'none'
	},
	font: {
		// "fontFamily": "\"Times New Roman\" !important"
		fontFamily: 'Segoe UI'
	},
	ans_btn: {
		textAlign: 'center',
		borderRadius: '5%',
		color: '#fff',
		cursor: 'pointer',
		width: '80%'
	},

	btn_wrong: {
		background: '#be0f11'
	},

	btn_difficult: {
		background: '#502a5a'
	},

	btn_normal: {
		background: '#253481'
	},

	btn_easy: {
		background: '#5866b8'
	},

	closeIconCont: {
		alignSelf: 'center'
	},

	whiteColor: {
		color: '#ffff'
	},

	cursorPointer: {
		cursor: 'pointer'
	},

	cursorPointerNotAllowed: {
		cursor: 'not-allowed'
	},

	ans_btn_height: {
		marginTop: '-10px'
	},

	thBody: {
		display: 'flex',
		alignItems: 'center',
		padding: ' 0 2%'
	}
})
