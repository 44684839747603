// src/utils/toast.js
import toast from 'react-hot-toast'

export const showToastWithVariableDuration = (message, type) => {
	// Define a base duration for the toast.
	const baseDuration = 2000 //  milliseconds
	// Define the additional duration per character.
	const durationPerCharacter = 55 //  milliseconds

	// Calculate the total duration based on the message length.
	const duration = baseDuration + message.length * durationPerCharacter

	//*********************************************** */
	console.log('message length =', message.length)
	console.log('duration =', duration)
	//*********************************************** */

	switch (type) {
		case 'success':
			toast.success(message, { duration })
			break
		case 'error':
			toast.error(message, { duration })
			break
		// ... other cases for different types of toasts
		default:
			toast(message, { duration })
	}
}

// Usage example:
// showToastWithVariableDuration('This is a short message.', 'error')
// showToastWithVariableDuration(
// 	'This is a longer message, which will have a longer duration.', 'success'
// )
