import { Fragment } from 'react'
import AuthNavbar from 'components/AuthNavbar'
import LoginFooter from 'components/LoginFooter'
import { LeftDiv, RightDiv, ReferralsPageContainer } from './referralsPageSkin'
import SideBar from 'shared/Sidebar'
import useAuth from 'hooks/useAuth'
import Referrals from 'components/Referrals'

const ReferralsPage = () => {
	const { auth } = useAuth()

	return (
		<Fragment>
			<AuthNavbar data-testid="auth-navbar" />
			<ReferralsPageContainer data-testid="referrals-container">
				<LeftDiv data-testid="left-div">
					<SideBar data-testid="sidebar" />
				</LeftDiv>

				<RightDiv data-testid="right-div">
					<div className="contextArea">
						<div style={{ marginBottom: 0 }} className="top h4">
							Referrals
						</div>

						<div className="middle_area">
							<Referrals id={auth.user.id} data-testid="referrals" />
						</div>

						<div className="bottom" />
					</div>
				</RightDiv>
			</ReferralsPageContainer>

			<LoginFooter data-testid="login-footer" />
		</Fragment>
	)
}

export default ReferralsPage
