import axios from 'axios'

const URL = process.env.REACT_APP_URL

const api = axios.create({
	baseURL: `${URL}tutorial`
})

export const getPrivateTuts = async () => {
	const result = await api.get('/private')
	return result.data
}

export const getPublicTuts = async () => {
	const result = await api.get('/public')
	return result.data
}
