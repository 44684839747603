import axios from 'axios'

const URL = process.env.REACT_APP_URL

const api = axios.create({
	baseURL: `${URL}suggestions`
})

const createConfig = () => {
	const token = sessionStorage.getItem('token')

	return {
		headers: {
			'content-type': 'multipart/form-data',
			Authorization: `Bearer ${token}`
		}
	}
}

export const postNewSuggestion = async (data) => {
	const config = createConfig()
	const result = await api.post('', data, config)
	return result.data
}
