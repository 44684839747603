import React, { useState, useEffect, useCallback } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import { ErrorMessage } from './PDFRendererSkin'
import { useTranslation } from 'react-i18next'
import './style.css'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const PDFRenderer = ({ file, modalWidth }) => {
	const [numPages, setNumPages] = useState(null)
	const { t } = useTranslation()
	const [scaleRendering, setScaleRendering] = useState(1)

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages)
	}

	// function to capture the original width of a page of the rendered PDF
	const onPageLoadSuccess = useCallback(
		({ width }) => {
			console.log('\nWidth of the original PDF (first page):', width)
			// Calculate the scale here based on modalWidth and the width of the original PDF
			let automaticScale
			if (width && modalWidth) {
				const scaleCoefficient = 0.95
				automaticScale = (modalWidth * scaleCoefficient) / width
				setScaleRendering(automaticScale)
			} else {
				automaticScale = 1
				setScaleRendering(automaticScale)
			}
		},
		[modalWidth, setScaleRendering]
	)

	useEffect(() => {
		console.log('scale =>', scaleRendering)
		console.log('modalWidth =>', modalWidth)
	}, [scaleRendering, modalWidth])

	const handleContentClick = (event) => {
		event.stopPropagation()
	}

	return (
		<Document
			file={file}
			options={{ isEvalSupported: false }}
			onLoadSuccess={onDocumentLoadSuccess}
			loading={false}
			error={<ErrorMessage>{t('PDFLoadingError')}</ErrorMessage>}
			options={{ isEvalSupported: false }}
		>
			{Array.from(new Array(numPages), (el, index) => (
				<div onClick={handleContentClick} key={`page_${index + 1}`}>
					<Page
						key={`page_${index + 1}`}
						pageNumber={index + 1}
						onLoadSuccess={index === 0 ? onPageLoadSuccess : undefined}
						scale={scaleRendering}
						renderAnnotationLayer={true}
						renderTextLayer={false}
						options={{ isEvalSupported: false }}
					/>
				</div>
			))}
		</Document>
	)
}

export default PDFRenderer
