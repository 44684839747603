import CloseIcon from '@mui/icons-material/Close'
import {
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	DialogActions,
	Button
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import backgroundImage from '../../images/Path 845.png'
import { SubscriptionPopup } from './skins'
import { useHistory } from 'react-router-dom'

const SubscriptionInvoicingPopup = ({ open, setOpen }) => {
	const { t } = useTranslation()
	const history = useHistory()

	const onClickHandler = () => {
		history.push('/profile')
	}

	return (
		<Dialog
			open={open}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<SubscriptionPopup background={backgroundImage}>
				<DialogTitle
					id="alert-dialog-title"
					className="alert-dialog-title h4"
					sx={{ display: 'flex', justifyContent: 'space-between' }}
				>
					<div className="dialog-title-text">
						{t('subscription_plan_modal_title')}
					</div>

					<IconButton
						aria-label="close"
						className="cross-icon"
						onClick={() => setOpen(false)}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500]
						}}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<DialogContentText
						className="alert-dialog-description h7"
						id="alert-dialog-description"
					>
						{t('hi')}
					</DialogContentText>
					<DialogContentText
						className="alert-dialog-description h7"
						id="alert-dialog-description"
					>
						{t('subscription_payment_text_1')}
					</DialogContentText>
					<DialogContentText
						className="alert-dialog-description h7"
						id="alert-dialog-description"
					>
						{t('subscription_payment_text_2')}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button className="button" onClick={onClickHandler}>
						{t('personal_data')}
					</Button>
				</DialogActions>
			</SubscriptionPopup>
		</Dialog>
	)
}

export default SubscriptionInvoicingPopup
