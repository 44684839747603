import { useParams } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import CreateAccount from '../../components/CreateAccount'

const SignUpPage = () => {
	const { token } = useParams()
	const { id } = jwt_decode(token)

	return (
		<div
			style={{
				minHeight: '100vh',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between'
			}}
		>
			<CreateAccount id={id} />
		</div>
	)
}

export default SignUpPage
