import axios from 'axios'

const URL = process.env.REACT_APP_URL

const api = axios.create({
	baseURL: `${URL}user-dashboard`
})

export const fetchVideos = async () => {
	return await api.get('/videos')
}

//This AP - located in Postman's User Dashboard folder -  is called "getAllBooks" and retrieves all books saved in DB, both uploaded and not
export const fetchQuestions = async () => {
	const res = await api.get(`/books`)
	return res
}

//This AP - located in Postman's User Dashboard folder -  is called "getAllUserBooks" and returns an array of JSON objects, each associated to a book saved in DB, either uploaded or not, belonging to the user associated to the id passed as argument. The properties of each JSON object are: "title" (a string), "medium", "low", "urgent", "T" and "completionPercentage" (all integer numbers)
export const fetchQuestionsBooks = async (id) => {
	const res = await api.get(`/books/${id}`)
	return res.data
}
