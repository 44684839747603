import { React, Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import LoadingIndicator from './components/LoadingIndicator'
import './utils/i18next.config.js'
import { AuthProvider } from './contexts/AuthProvider'
import { DeleteNoteProvider } from './contexts/DeleteProvider'
import { PersonalDataProvider } from 'contexts/PersonalProvider'
import { parseCookies, setCookie } from 'nookies'
import { Provider } from 'react-redux'
import store from './store/store'

import 'react-multi-carousel/lib/styles.css'

import './index.css'
import { BookInfoProvider } from 'contexts/BookInfoProvider'
import { SplitFactoryProvider } from '@splitsoftware/splitio-react'
//Removed SplitSdk import

function addGoogleTagManager(gtmId) {
	// Add script in head
	const headScript = document.createElement('script')
	headScript.text = `
        (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', '${gtmId}');
    `
	document.head.appendChild(headScript)

	// Add noscript in body
	const bodyNoscript = document.createElement('noscript')
	bodyNoscript.innerHTML = `
        <iframe
            src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
            height="0"
            width="0"
            style="display: none; visibility: hidden">
        </iframe>
    `
	document.body.insertBefore(bodyNoscript, document.body.firstChild)
}

const gtmId = process.env.REACT_APP_GTM
if (gtmId && gtmId.trim() !== '') {
	addGoogleTagManager(gtmId)
}

const getUserKey = () => {
	const cookies = parseCookies()
	let userKey = cookies.splitUserKey

	// If the splitUserKey cookie doesn't exist, generate a new key and save it
	if (!userKey) {
		userKey = Math.random().toString(36).substring(7)
		setCookie(null, 'splitUserKey', userKey, {
			maxAge: 30 * 24 * 60 * 60, // 30 days
			path: '/'
		})
	}

	return userKey
}

// Create the Split factory object with your custom settings, using the re-exported function.
const splitConfig = {
	core: {
		authorizationKey: process.env.REACT_APP_SPLIT_AUTH_KEY,
		key: getUserKey()
	},
	debug: false
}

ReactDOM.render(
	<Provider store={store}>
		<Suspense fallback={<LoadingIndicator />}>
			<AuthProvider>
				<BookInfoProvider>
					<DeleteNoteProvider>
						<PersonalDataProvider>
							<SplitFactoryProvider config={splitConfig}>
								<App />
							</SplitFactoryProvider>
						</PersonalDataProvider>
					</DeleteNoteProvider>
				</BookInfoProvider>
			</AuthProvider>
		</Suspense>
	</Provider>,
	document.getElementById('root')
)
