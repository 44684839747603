import { useState } from 'react'
import { Button, CircularProgress } from '@mui/material'
import toast from 'react-hot-toast'
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { newInvitation, checkInvitation } from '../../api/invite'
import ReferralTable from '../ReferralTable'
import { ReferralSkin } from './referralSkin'
import { getReferral } from 'api/user'
import LoadingIndicator from '../LoadingIndicator'
import { queryClient } from 'App'

const Referrals = ({ id }) => {
	const { t } = useTranslation()

	const [error, setError] = useState({})
	const [loading, setLoading] = useState(false)
	const [refData, setRefData] = useState([])
	const [mktgBonus, setMktgBonus] = useState(0)
	const [weeksGranted, setWeeksGranted] = useState(0)
	const [expiration, setExpiration] = useState(0)
	const [invitationFreePlanWeeksDuration, setInvitationFreePlanWeeksDuration] =
		useState(0)

	const [invitee_email, setInvitee_email] = useState('')
	const [invitee_name, setInvitee_name] = useState('')

	const { isLoading } = useQuery('referrals', () => getReferral(id), {
		onSuccess: (data) => {
			setRefData(data.data.invitee)
			setWeeksGranted(data.data.weeksGranted)
			setMktgBonus(data.data.mktgBonus)
			setExpiration(data.data.invitationExpiration)
			setInvitationFreePlanWeeksDuration(
				data.data.invitationFreePlanWeeksDuration
			)
		}
	})

	const checkInvite = async (ev) => {
		ev.preventDefault()
		const token = sessionStorage.getItem('token')

		let err = { ...error }
		let hasError = false

		if (!invitee_email) {
			err.invitee_email = 'Invitee email is required'
			hasError = true
		}

		if (!invitee_name) {
			err.invitee_name = "Invitee's name is required"
			hasError = true
		}

		if (hasError) {
			setError(err)
			return // Stop the execution if there are errors
		}

		setLoading(true)

		try {
			const result = await checkInvitation({
				token,
				inviteeEmail: invitee_email,
				userId: id
			})

			// Check if the result has data, and proceed accordingly
			if (result && result.data) {
				//In this IF BLOCK goes the success code
				setLoading(false)

				Swal.fire({
					// title: t('ref_popup_warning'),
					title: t(`${result.data.message}`),
					showConfirmButton: true,
					confirmButtonText: 'Send Invite',
					showLoaderOnConfirm: true,
					showCancelButton: true,
					preConfirm: () => {
						return newInvitation({
							token,
							inviteeEmail: invitee_email,
							inviteeName: invitee_name,
							userId: id
						})
							.then((response) => {
								if (response.error) {
									throw new Error(response.error.response.data.message)
								}
								return response.data.message
							})
							.catch((error) => {
								Swal.showValidationMessage(`Request failed: ${error}`)
							})
					},
					allowOutsideClick: () => !Swal.isLoading(),
					backdrop: true
				}).then((btn) => {
					setLoading(false)
					setInvitee_email('')
					setInvitee_name('')
					queryClient.invalidateQueries('referrals')

					if (btn.isConfirmed) {
						Swal.fire({
							con: 'success',
							title: `Invitation sent successfully`
						})
					}
				})
			}
		} catch (error) {
			if (error.response) {
				toast.error(error.response.data.message)
			} else if (error.request) {
				toast.error(error.request)
			} else {
				toast.error(error.message)
			}
		} finally {
			setLoading(false)
		}
	}

	return isLoading ? (
		<LoadingIndicator />
	) : (
		<ReferralSkin data-testid="referrals" id={id}>
			<ReferralTable refData={refData} weeks={weeksGranted} />

			<h4 className="h4 invite_title">
				{t('ref_invite', { invitationFreePlanWeeksDuration })}
			</h4>
			<div className="referral_form">
				<form onSubmit={checkInvite}>
					<div style={{ display: 'block' }} className="private_data">
						<div
							style={{
								display: 'flex',
								alignItems: 'flex-end',
								columnGap: '30px'
							}}
							className="left"
						>
							<div className="inputfiled-container">
								<div className="labels h6">Friend's Email</div>
								{error.invitee_email && <p>{error.invitee_email}</p>}
								<input
									value={invitee_email}
									onChange={(e) => {
										setError({})
										setInvitee_email(e.target.value)
									}}
									className="h7"
									style={{ width: '100%' }}
									name="invitee_email"
									id="outlined-adornment-email"
									type="email"
								/>
							</div>
							<div className="inputfiled-container">
								<div className="labels h6">Friend's Name</div>
								{error.invitee_name && <p>{error.invitee_name}</p>}
								<input
									value={invitee_name}
									onChange={(e) => {
										setError({})
										setInvitee_name(e.target.value)
									}}
									className="h7"
									name="invitee_name"
									style={{ width: '100%' }}
									id="outlined-adornment-name"
									type="text"
								/>
							</div>
							<div style={{ width: '160px' }} className="inputfiled-container">
								<div className="labels h6"></div>
								<Button
									type="submit"
									className="inviteBtn h6"
									variant="contained"
								>
									{loading ? (
										<CircularProgress
											style={{ height: '28px', width: '28px' }}
											color="inherit"
										/>
									) : (
										'Invite'
									)}
								</Button>
							</div>
						</div>
					</div>
				</form>
			</div>
			<div className="ref_footer">
				<h6 className="h6">Email's main body</h6>
				<p className="h7">{t('ref_email_body')}</p>
			</div>

			<div className="notifying h4">
				{t('ref_receive_text_1')} {mktgBonus} {t('ref_receive_text_2')}{' '}
				{expiration} {t('ref_receive_text_3')}
			</div>
		</ReferralSkin>
	)
}

export default Referrals
