import { useState, useRef, useEffect } from 'react'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Dialog, Button, styled } from '@mui/material'
import {
	Form,
	DialogueBox,
	StyledAttachmentIcon
} from './PopupSuggestionsModalSkin'
import LoadingButton from '@mui/lab/LoadingButton'
import { useTranslation } from 'react-i18next'
import { postNewSuggestion } from '../../api/suggestions'

const BootstrapDialog = styled(Dialog)(() => ({
	'& .MuiPaper-root': {
		width: '48.4375%'
	}
}))

const PopupSuggestionsModal = ({ isModalOpen, closeModal, setIsAlertOpen }) => {
	const [suggestionsData, setSuggestionsData] = useState({
		suggestion_comment: '',
		files: []
	})
	const [isLoading, setIsLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const formSubmit = async (ev) => {
		try {
			ev.preventDefault()
			setIsLoading(true)
			const formData = new FormData()
			Array.from(suggestionsData.files).map((file) => {
				formData.append(`files`, file)
			})
			formData.append('suggestion_comment', suggestionsData.suggestion_comment)
			await postNewSuggestion(formData)
			setIsLoading(false)
			setIsAlertOpen(true)
			closeModal()
			cleanData()
		} catch (ex) {
			console.log('something went wrong')
		}
	}

	const onCancel = () => {
		closeModal()
	}

	// reference
	const textArearef = useRef()
	const fileInputRef = useRef(null)

	const goUp = (id) => {
		id.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		})
	}

	const goDown = (id) => {
		id.scrollTo({
			top: id.scrollHeight,
			left: 0,
			behavior: 'smooth'
		})
	}

	const stopScroll = (id) => {
		id.scrollTop = id.scrollTop
	}

	//Call the translation service
	const { t } = useTranslation()

	const handleFileChange = (e) => {
		setErrorMessage('')

		const files = e.target.files
		if (files.length > 0) {
			// You can process the selected files here
			console.log('Selected files:', files)

			//
			const allowedTypes = [
				'image/png',
				'image/jpeg',
				'image/tiff',
				'image/gif'
			]
			const invalidFiles = Array.from(files).filter(
				(file) => !allowedTypes.includes(file.type)
			)

			if (invalidFiles.length > 0) {
				setSuggestionsData({ ...suggestionsData, files: [] })
				setErrorMessage(t('invalid_image_error_message'))
				return
			}
			if (files.length > 3) {
				setSuggestionsData({ ...suggestionsData, files: [] })
				setErrorMessage(t('maximum_image_error_message'))
				return
			}
			setSuggestionsData({ ...suggestionsData, files })
		}
	}
	const handleOnChange = (e) => {
		setSuggestionsData({
			...suggestionsData,
			suggestion_comment: e.target.value
		})
	}

	const uploadFile = () => {
		// Trigger the click event of the file input when the button is clicked
		fileInputRef.current.click()
	}

	const cleanData = () => {
		setSuggestionsData({ suggestion_comment: '', files: [] })
		setErrorMessage('')
	}
	useEffect(() => {
		return () => {
			cleanData()
		}
	}, [closeModal])
	return (
		<BootstrapDialog
			onClose={onCancel}
			aria-labelledby="customized-dialog-title"
			open={isModalOpen}
		>
			<DialogueBox>
				<div className="header">
					<p className="header-text">{t('suggestions_callout1')}</p>
					<p className="header-text second-text">{t('suggestions_callout2')}</p>
				</div>
				<Form onSubmit={formSubmit}>
					<div className="modal-textarea">
						<div
							className="top"
							onPointerDown={(e) => goUp(textArearef.current)}
							onPointerUp={(e) => stopScroll(textArearef.current)}
						>
							<KeyboardArrowUpIcon style={{ cursor: 'pointer' }} />
						</div>

						<textarea
							ref={textArearef}
							rows="7"
							cols="50"
							maxLength="1000"
							className="text-box font scrollbar"
							placeholder={t('suggestions_callout3')}
							onChange={handleOnChange}
							value={suggestionsData.suggestion_comment}
						/>

						<div
							className="bottom"
							onPointerDown={(e) => goDown(textArearef.current)}
							onPointerUp={(e) => stopScroll(textArearef.current)}
						>
							<KeyboardArrowDownIcon style={{ cursor: 'pointer' }} />
						</div>
					</div>
					<div className="upload-images">
						{Array.from(suggestionsData.files).map(({ name }, index) => (
							<p key={index}>{name}</p>
						))}
						<p className="error-message">{errorMessage}</p>
					</div>

					<div className="modal-footer">
						<div>
							<input
								type="file"
								accept="image/png, image/jpeg, image/tiff, image/gif"
								multiple
								onChange={handleFileChange}
								ref={fileInputRef} // Attach the ref to the file input
								style={{ display: 'none' }} // Hide the file input
							/>
							<Button
								onClick={uploadFile}
								variant="contained"
								className="button"
							>
								<StyledAttachmentIcon />
							</Button>
						</div>
						<div className="buttons-container">
							<Button
								onClick={onCancel}
								variant="contained"
								className="button cancel-button"
							>
								{t('cancel')}
							</Button>

							<LoadingButton
								variant="contained"
								// className="button"
								className={`button ${isLoading ? 'loading-btn' : ''}`}
								type="submit"
								disabled={suggestionsData.suggestion_comment === ''}
								loading={isLoading}
							>
								{t('sendButton')}
							</LoadingButton>
						</div>
					</div>
				</Form>
			</DialogueBox>
		</BootstrapDialog>
	)
}

export default PopupSuggestionsModal
