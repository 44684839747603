import axios from 'axios'

const URL = process.env.REACT_APP_URL

const api = axios.create({
	baseURL: `${URL}legaldocs`
})

export const getLegalDocument = async (type) => {
	const {
		data: { url }
	} = await api.get(`/retrieve?docType=${type}`)
	return url
}
