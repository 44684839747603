import { useState, useRef, useEffect, useCallback } from 'react'
import Grid from '@mui/material/Grid'
import EditIcon from '@mui/icons-material/Edit'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useStyles } from './style.js'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import Draggable from 'react-draggable'
import SaveIcon from '@mui/icons-material/Save'
import DeleteIcon from '@mui/icons-material/Delete'
import AlertDialog from 'components/AlertDialog/AlertDialog.jsx'
import { MenuItem, Select, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import useDeleteNote from '../../hooks/useDeleteNote'
import {
	getCloseReviewCreateNewReview,
	getPopulateQnApopup,
	getSimulateIntervals,
	getReviewList
} from 'api/qna.js'
import LoadingIndicator from 'components/LoadingIndicator/index.js'
import useAuth from 'hooks/useAuth.js'
import { makeInputUpperCase, reviewsPriority } from 'utils/common.util.js'

const QnaPageModal = ({
	expanded,
	bookTitles,
	value,
	hide,
	data,
	minimize,
	refetch
}) => {
	console.log('value', data)
	const classes = useStyles()
	const theme = useTheme()
	const matches = useMediaQuery(theme.breakpoints.up('sm'))
	const { t } = useTranslation()
	const [step, setStep] = useState(1)
	const [side, setSide] = useState(1)
	const [loading, setLoading] = useState(true)
	const [count, setCount] = useState(0)
	const [time, setTime] = useState()
	const [allowSwitch, setAllowSwitch] = useState(false)
	const [isEdit, setIsEdit] = useState(false)
	const [isUpdate, setUpdate] = useState(false)
	const [keyword, setKeyword] = useState()
	const [noteSide1, setNoteSide1] = useState()
	const [noteSide2, setNoteSide2] = useState()
	const [isDelete, setIsdelete] = useState(false)
	const [showAnswerBtn, setShowAnswerBtn] = useState(true)
	const deleteTitle = t('delete_note_question')
	const upateTitle = t('update_note_question')
	const successDeleteTitle = t('note_delete_success')
	const successUpateTitle = t('note_update_success')
	const [error, setError] = useState('')
	const [alert, setAlert] = useState(false)
	const [confirmDelete, setConfirmDelete] = useState(false)
	const { setNoteDeted } = useDeleteNote()
	const [cursorStyle, setCursorStyle] = useState(false)
	const [showReviewText, setReviewShowText] = useState(false)
	const [isTextareaFocused, setIsTextareaFocused] = useState(false)
	const [lQARevCluster, setLQARevCluster] = useState([])
	const {
		auth: { token }
	} = useAuth()

	const keywordinput = useRef(null)
	const [isDraggable, setIsDraggable] = useState(false)

	const ref = useRef(null)

	const handleFocus = useCallback(() => {
		setIsTextareaFocused(true)
	}, [])

	const handleBlur = useCallback(() => {
		setIsTextareaFocused(false)
	}, [])

	useEffect(() => {
		const preventScroll = (e) => {
			if (
				['Space', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].indexOf(
					e.code
				) > -1
			) {
				e.preventDefault()
			}
		}

		if (isTextareaFocused) {
			window.addEventListener('keydown', preventScroll)
		} else {
			window.removeEventListener('keydown', preventScroll)
		}

		return () => {
			window.removeEventListener('keydown', preventScroll)
		}
	}, [isTextareaFocused])

	const fetchReviewList = async () => {
		const result = await getReviewList(data.QAExpBookId[expanded], token)
		if (result.LQARevId.length === 0) {
			hide()
		} else {
			refetch()
		}
	}
	const fetchData = async (id) => {
		const result = await getPopulateQnApopup(id, token)
		if (result && result.LnoteSide1 && result.LnoteSide1.includes(':')) {
			let newNote1 = ''
			result.LnoteSide1.replace(/([\s]+)(:)/g, `\n${'$1'.bold()}$2`)
				.split('\n')
				.filter((x) => x)
				.map((x) => x.trim())
				.map((x) => {
					if (x) {
						return (newNote1 = newNote1 + ' ' + x)
					}
					return
				})
			setNoteSide1(newNote1)
		} else {
			setNoteSide1(result.LnoteSide1)
		}

		if (result && result.LnoteSide2 && result.LnoteSide2.includes(':')) {
			let newNote2 = ''
			result.LnoteSide2.replace(/([\s]+)(:)/g, `\n${'$1'.bold()}$2`)
				.split('\n')
				.filter((x) => x)
				.map((x) => x.trim())
				.map((x) => {
					if (x) {
						return (newNote2 = newNote2 + ' ' + x)
					}
					return
				})
			setNoteSide2(result.LnoteSide2)
		} else {
			setNoteSide2(result.LnoteSide2)
		}

		setSide(1)
		setStep(result.LnoteStep)
		setKeyword(result.LnoteKeyword)
		setLoading(false)
	}

	useEffect(() => {
		if (value && value.LQARevNoteId) {
			fetchData(value.LQARevNoteId[count]).catch(console.error)
		}
	}, [count])

	const wrongBtn_click = async () => {
		const res = await getCloseReviewCreateNewReview(
			{
				LQARevId: value.LQARevId[count],
				LQARev_Qgrade: 1
			},
			token
		)

		if (res === 200) {
			if (count + 1 < value.LQARevNoteId.length) {
				setSide(1)
				setAllowSwitch(false)
				setShowAnswerBtn(true)
				setCount(count + 1)
				refetch()
			} else {
				fetchReviewList()
			}
		}
		setReviewShowText(false)
	}

	const difficultBtn_click = async () => {
		const res = await getCloseReviewCreateNewReview(
			{
				LQARevId: value.LQARevId[count],
				LQARev_Qgrade: 3
			},
			token
		)

		if (res === 200) {
			if (count + 1 < value.LQARevNoteId.length) {
				setSide(1)
				setAllowSwitch(false)
				setShowAnswerBtn(true)
				setCount(count + 1)
				refetch()
			} else {
				fetchReviewList()
			}
		}
		setReviewShowText(false)
	}

	const normalBtn_click = async () => {
		const res = await getCloseReviewCreateNewReview(
			{
				LQARevId: value.LQARevId[count],
				LQARev_Qgrade: 4
			},
			token
		)

		if (res === 200) {
			console.log(count, value.LQARevNoteId)
			if (count + 1 < value.LQARevNoteId.length) {
				setSide(1)
				setAllowSwitch(false)
				setShowAnswerBtn(true)
				setCount(count + 1)
				refetch()
			} else {
				fetchReviewList()
			}
		}
		setReviewShowText(false)
	}

	const easyBtn_click = async () => {
		const res = await getCloseReviewCreateNewReview(
			{
				LQARevId: value.LQARevId[count],
				LQARev_Qgrade: 5
			},
			token
		)

		if (res === 200) {
			if (count + 1 < value.LQARevNoteId.length) {
				setSide(1)
				setAllowSwitch(false)
				setShowAnswerBtn(true)
				setCount(count + 1)
				refetch()
			} else {
				fetchReviewList()
			}
		}
		setReviewShowText(false)
	}

	const ansBtn_click = async () => {
		setReviewShowText(true)
		setAllowSwitch(true)
		setShowAnswerBtn(false)
		const result = await getSimulateIntervals({
			QARev_repN_prev: value.LQARevRepN[count],
			QARev_easyFactor_prev: value.LQARevEasyFactor[count],
			QARev_interval_prev: value.LQARevInterval[count]
		})

		if (result) {
			setTime(result)
			setSide(2)
		}
	}

	const goUp = (id) => {
		id.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		})
	}

	const goDown = (id) => {
		id.scrollTo({
			top: id.scrollHeight,
			left: 0,
			behavior: 'smooth'
		})
	}

	const stopScroll = (id) => {
		id.scrollTop = id.scrollTop
	}

	const handleEditQnA = () => {
		setIsEdit(true)
		setUpdate(false)
		setAlert(true)
	}

	const handleUpdate = () => {
		setUpdate(true)
		// setIsEdit(false);
		setAlert(true)
	}

	const handleOnChange = (e) => {
		setAlert(false)
		const name = e.target.name
		if (name.toLowerCase() === 'keyword') {
			setKeyword(makeInputUpperCase(keywordinput, e))
		}

		if (name === 'noteSide1') {
			setNoteSide1(e.target.value)
		}
		if (name === 'noteSide2') {
			setNoteSide2(e.target.value)
		}
		if (name.toLowerCase() === 'step') {
			if (parseInt(e.target.value) > parseInt(e.target.max)) {
				setStep(e.target.max)
			} else {
				setStep(e.target.value)
			}
		}
	}

	const handleDeleteQandA = (id) => {
		if (isDelete) {
			setIsdelete(false)
		} else {
			setIsdelete(true)
		}
		setAlert(true)
	}

	const handleCancel = () => {
		setIsdelete(false)
		setUpdate(false)
	}

	const minimizePopup = async () => {
		minimize()
	}

	const closePopup = async () => {
		hide()
	}

	const moveUp = (id) => {
		const lineHeight =
			parseInt(window.getComputedStyle(id).lineHeight, 10) || 18

		id.scrollTop -= lineHeight * 2
	}

	const moveDown = (id) => {
		const lineHeight =
			parseInt(window.getComputedStyle(id).lineHeight, 10) || 18
		id.scrollTop += lineHeight * 2
	}

	useEffect(() => {
		if (confirmDelete) {
			setNoteDeted({ deleted: true })
		}
	}, [confirmDelete])

	useEffect(() => {
		if (value?.LQARevCluster.length > 0) {
			const inputArray = [...value?.LQARevCluster]
			const resultArray = []

			const distinctValues = new Set()

			for (const item of inputArray) {
				if (!distinctValues.has(item)) {
					distinctValues.add(item)
					resultArray.push(item)
				} else {
					resultArray.push(0)
				}
			}
			setLQARevCluster(resultArray)
		}
	}, [value])

	const disableButton = (isDeleteButton = false) => {
		const _lowopacity = isDeleteButton ? '' : classes.Lowopacity
		return isEdit ? `${_lowopacity} ${classes.nonePointerEvent}` : ''
	}
	const handleMouseDown = () => {
		setCursorStyle(true)
		document.body.classList.add(classes.grabbingHand)
	}

	const handleMouseUp = () => {
		setCursorStyle(false)
		document.body.classList.remove(classes.grabbingHand)
	}

	return (
		<Draggable
			disabled={isDraggable}
			onStart={handleMouseDown}
			onStop={handleMouseUp}
		>
			{loading ? (
				<LoadingIndicator />
			) : (
				<div
					className={`${matches ? classes.modalWeb : classes.modalPhone} ${
						!cursorStyle ? classes.grabHand : ''
					}`}
					style={{
						paddingTop: '1.58%',
						width: 'auto',
						height: 'auto'
					}}
				>
					{(isUpdate && alert) || isDelete ? (
						<AlertDialog
							closePopup={closePopup}
							setConfirmDelete={setConfirmDelete}
							setIsdelete={setIsdelete}
							setIsEdit={setIsEdit}
							isEdit={isEdit}
							setUpdate={setUpdate}
							step={step}
							setError={setError}
							error={error}
							hidePopup={hide}
							isDelete={isDelete}
							isUpdate={isUpdate}
							data={{
								note_step: step,
								note_keyword: keyword,
								note_side1: noteSide1,
								note_side2: noteSide2
							}}
							Id={value.LQARevNoteId[count]}
							handleCancel={handleCancel}
							title={!isDelete ? upateTitle : deleteTitle}
							successTitle={!isDelete ? successUpateTitle : successDeleteTitle}
						/>
					) : null}
					<Grid spacing={3} style={{ height: '100%' }}>
						<Grid
							container
							spacing={3}
							className={classes.gridContainer}
							direction="row"
							columns={31}
						>
							<Grid xs={1}></Grid>
							<Grid xs={24} className={classes.heading}>
								<input
									className={`h8 ${classes.scrollbar} ${classes.input} ${classes.font}`}
									type="text"
									placeholder="TITLE"
									value={t(bookTitles[expanded])}
									id="title"
									name="title"
									style={{ padding: '0 0 0 3%' }}
									readOnly
									onMouseEnter={() => {
										setIsDraggable(true)
									}}
									onMouseLeave={() => {
										setIsDraggable(false)
									}}
								/>
							</Grid>
							<Grid xs={1}></Grid>
							<Grid xs={2}>
								<FullscreenExitIcon
									onClick={minimizePopup}
									aria-label={t('warning')}
									className={`h3 ${classes.cursorPointer}`}
								/>
							</Grid>
							<Grid xs={2}>
								<IconButton
									style={{ padding: '17.7%' }}
									onClick={closePopup}
									className={`${classes.iconBtn} ${classes.white}`}
									aria-label={t('warning')}
									size="small"
								>
									<CloseIcon className={`h5 ${classes.cursorPointer}`} />
								</IconButton>
							</Grid>
							<Grid xs={1}></Grid>
						</Grid>
						<Grid
							container
							spacing={3}
							className={`${classes.gridContainer} ${classes.gridMargin}`}
							direction="row"
							columns={31}
						>
							<Grid xs={1}></Grid>
							<Grid xs={17} className={classes.heading}>
								<input
									className={`h8 ${classes.scrollbar} ${classes.input} ${classes.font}`}
									value={t(keyword).toUpperCase()}
									style={{ padding: '0 0 0 5%' }}
									type="text"
									onChange={(e) => handleOnChange(e)}
									name={t('keyword')}
									id="keyword"
									readOnly={!isEdit}
									ref={keywordinput}
									onMouseEnter={() => {
										setIsDraggable(true)
									}}
									onMouseLeave={() => {
										setIsDraggable(false)
									}}
								/>
							</Grid>
							<Grid xs={1}></Grid>
							<Grid xs={4} className={classes.heading}>
								<input
									type="number"
									className={`h8 ${classes.scrollbar} ${classes.inputStep} ${classes.font}`}
									value={t(step)}
									name={t('step')}
									id="step"
									min={1}
									max={3}
									onChange={(e) => handleOnChange(e)}
									readOnly={!isEdit}
									style={{ padding: '0 0 0 21.14%' }}
									onWheel={(e) => e.target.blur()}
									onMouseEnter={() => {
										setIsDraggable(true)
									}}
									onMouseLeave={() => {
										setIsDraggable(false)
									}}
								/>
							</Grid>
							<Grid xs={1}></Grid>
							<Grid xs={1} className={classes.heading}>
								<IconButton
									style={{ cursor: 'unset' }}
									className={`"h5 ${classes.iconBtn} + " " + ${classes.black}`}
									aria-label={t('warning')}
								>
									{value.LQARevCluster[count] === 3 && (
										<p className={`h5 ${classes.fontP}`}>!!!</p>
									)}
									{value.LQARevCluster[count] === 2 && (
										<p className={`h5 ${classes.fontP}`}>!!</p>
									)}
									{value.LQARevCluster[count] === 1 && (
										<p className={`h5 ${classes.fontP}`}>!</p>
									)}
								</IconButton>
							</Grid>
							<Grid xs={1}></Grid>
							<Grid
								xs={2}
								className={classes.heading}
								style={{
									justifyContent: 'center',
									display: 'flex',
									alignItems: 'center'
								}}
							>
								<DeleteIcon
									onClick={handleDeleteQandA}
									className={`h5 ${classes.cursorPointer} ${
										classes.deleteIcon
									} ${disableButton(true)}`}
								/>
							</Grid>
							<Grid
								xs={2}
								className={classes.heading}
								style={{
									justifyContent: 'center',
									display: 'flex',
									alignItems: 'center'
								}}
							>
								{!isEdit ? (
									<EditIcon
										onClick={!isEdit && handleEditQnA}
										className={`h5 ${classes.cursorPointer}`}
									/>
								) : (
									<SaveIcon
										onClick={isEdit && handleUpdate}
										className={`h5 ${classes.cursorPointer}`}
									/>
								)}
							</Grid>
							<Grid xs={1}></Grid>
						</Grid>

						<Grid
							container
							spacing={3}
							className={classes.gridContainer + ' ' + classes.gridMargin}
							direction="row"
							columns={31}
						>
							<Grid xs={1}></Grid>
							<Grid xs={29} className={classes.note} style={{ height: 'auto' }}>
								<div
									style={{ margin: 'unset' }}
									className={classes.top}
									onPointerDown={(e) => goUp(ref.current)}
									onPointerUp={(e) => stopScroll(ref.current)}
								>
									<KeyboardArrowUpIcon className={classes.cursorPointer} />
								</div>
								<textarea
									style={{
										borderRight: '1px solid #6a7ae9',
										borderLeft: '1px solid #6a7ae9',
										border: '1px solid #6a7ae9'
									}}
									ref={ref}
									rows="8"
									cols="50"
									tabIndex="-1"
									onWheel={(e) => {
										if (e.deltaY < 0) {
											goUp(ref.current)
										} else {
											goDown(ref.current)
										}
									}}
									onKeyDown={(e) => {
										if (e.key === 'ArrowUp') {
											moveUp(ref.current)
										} else if (e.key === 'ArrowDown') {
											moveDown(ref.current)
										}
									}}
									onClick={() => {
										ref.current.focus()
										handleFocus()
									}}
									onBlur={() => {
										ref.current.blur()
										handleBlur()
									}}
									maxLength="3000"
									className={`h8 ${classes.scrollbar} ${classes.textAreaContainer} ${classes.text_box} ${classes.font}`}
									placeholder={t('YOUR NOTES')}
									value={side === 1 ? noteSide1 : noteSide2}
									onChange={(e) => handleOnChange(e)}
									readOnly={!isEdit}
									name={side === 1 ? t('noteSide1') : t('noteSide2')}
									onMouseEnter={() => {
										setIsDraggable(true)
									}}
									onMouseLeave={() => {
										setIsDraggable(false)
									}}
								/>

								<div
									className={classes.bottom}
									//onClick={handleDown}
									onPointerDown={(e) => goDown(ref.current)}
									onPointerUp={(e) => stopScroll(ref.current)}
								>
									<KeyboardArrowDownIcon className={classes.cursorPointer} />
								</div>
							</Grid>
							<Grid xs={1} />
						</Grid>

						<Grid
							className={`${classes.gridContainer} ${classes.gridMargin}`}
							container
							spacing={3}
							direction="row"
							columns={31}
						>
							<Grid xs={1}></Grid>
							<Grid xs={4} className={classes.stepNavigation}>
								<button
									onClick={() => {
										allowSwitch && setSide(1)
									}}
									className={`h6 ${classes.font}
                      ${classes.steps} 
                     ${side !== 1 && classes.stepNotSelected}
                     `}
									style={{
										cursor: allowSwitch ? null : 'not-allowed'
									}}
								>
									{t('Side 1')}
								</button>
							</Grid>
							<Grid xs={4} className={classes.stepNavigation}>
								<button
									onClick={() => {
										allowSwitch && setSide(2)
									}}
									className={`h6 ${classes.font}
                    ${classes.steps} 
                      ${side !== 2 && classes.stepNotSelected}`}
									style={{
										cursor: allowSwitch ? null : 'not-allowed'
									}}
								>
									{t('Side 2')}
								</button>
							</Grid>
							<p>
								{console.log('adf => ', count, lQARevCluster, reviewsPriority)}
							</p>
							{!showReviewText && (
								<Grid xs={15} className={`${classes.reviewContainer}`}>
									<div className={`h8 ${classes.reviewText}`}>
										{t(reviewsPriority[lQARevCluster[count]])}
									</div>
								</Grid>
							)}
							{side === 1 && showAnswerBtn && (
								<>
									<Grid xs={1} />
									<Grid
										xs={5}
										style={{
											justifyContent: 'end',
											display: 'flex'
										}}
									>
										<button
											onClick={ansBtn_click}
											className={classes.answerBtn}
										>
											<Typography className={`h6 ${classes.font}`}>
												Answer
											</Typography>
										</button>
									</Grid>
								</>
							)}
							{(side === 1 && !showAnswerBtn) || side === 2 ? (
								<>
									<Grid xs={1} />
									<Grid xs={4}>
										<div
											style={{ padding: '4.8% 11%' }}
											className={`${classes.ans_btn} ${
												classes.btn_wrong
											} ${disableButton()}`}
											onClick={wrongBtn_click}
										>
											<Typography
												className={`h6 ${classes.font}`}
												style={{ lineHeight: 1.2 }}
											>
												{t('Wrong')}
											</Typography>
											<Typography
												className={`h9 ${classes.font}`}
												style={{ lineHeight: 1 }}
											>
												{t(
													time &&
														time.intervalWrong &&
														time.intervalWrong.timeInterval
												)}{' '}
												{t(
													time &&
														time.intervalWrong &&
														time.intervalWrong.timeUnit
												)}
											</Typography>
										</div>
									</Grid>
									<Grid xs={2} />
									<Grid xs={4}>
										<div
											style={{ padding: '4.8% 11%' }}
											className={`${classes.ans_btn} ${
												classes.btn_difficult
											} ${disableButton()}`}
											onClick={difficultBtn_click}
										>
											<Typography
												className={`h6 ${classes.font}`}
												style={{ lineHeight: 1.2 }}
											>
												{t('Difficult')}
											</Typography>
											<Typography
												className={`h9 ${classes.font}`}
												style={{ lineHeight: 1 }}
											>
												{t(
													time &&
														time.intervalDifficult &&
														time.intervalDifficult.timeInterval
												)}{' '}
												{t(
													time &&
														time.intervalDifficult &&
														time.intervalDifficult.timeUnit
												)}
											</Typography>
										</div>
									</Grid>
									<Grid xs={1} />
									<Grid xs={4}>
										<div
											style={{ padding: '4.8% 11%' }}
											className={` ${classes.ans_btn} ${
												classes.btn_normal
											} ${disableButton()}`}
											onClick={normalBtn_click}
										>
											<Typography
												className={`h6 ${classes.font}`}
												style={{ lineHeight: 1.2 }}
											>
												{t('Normal')}
											</Typography>
											<Typography
												className={`h9 ${classes.font}`}
												style={{ lineHeight: 1 }}
											>
												{t(
													time &&
														time.intervalNormal &&
														time.intervalNormal.timeInterval
												)}{' '}
												{t(
													time &&
														time.intervalNormal &&
														time.intervalNormal.timeUnit
												)}
											</Typography>
										</div>
									</Grid>
									<Grid xs={1} />
									<Grid xs={4}>
										<div
											style={{ padding: '4.8% 11%' }}
											className={` ${classes.ans_btn} ${
												classes.btn_easy
											} ${disableButton()}`}
											onClick={easyBtn_click}
										>
											<Typography
												className={`h6 ${classes.font}`}
												style={{ lineHeight: 1.2 }}
											>
												{t('Easy')}{' '}
											</Typography>
											<Typography
												className={`h9 ${classes.font}`}
												style={{ lineHeight: 1 }}
											>
												{t(
													time &&
														time.intervalEasy &&
														time.intervalEasy.timeInterval
												)}{' '}
												{t(
													time &&
														time.intervalEasy &&
														time.intervalEasy.timeUnit
												)}
											</Typography>
										</div>
									</Grid>
								</>
							) : null}
							<Grid xs={1} />
						</Grid>
					</Grid>
				</div>
			)}
		</Draggable>
	)
}

export default QnaPageModal
