import styled from '@emotion/styled/macro'

export const LeftDiv = styled.div`
	flex-basis: 27.164%;
	max-width: 27.164%;
	overflow: hidden;
	position: relative;

	@media screen and (min-width: 600px) {
		flex-basis: 22.164%;
		max-width: 22.164%;
	}
`

export const RightDiv = styled.div`
	flex-basis: 72.164%;
	max-width: 72.164%;

	@media screen and (min-width: 600px) {
		flex-basis: 77.836%;
		max-width: 77.836%;
	}
`

export const ChangePasswordContainer = styled.div`
min-height: 100vh;
display: flex;
background-color: #2a333a;
padding: 1.5625% 0;

  .input-container {
    padding-top: 2%;
    padding-bottom: 2%;
  }
 .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .Mui-focused {
    outline: none;
    border-color: white;
  }
  .login-input {
    width: 100%;
    padding-top: 0px !important;
    border: 1px solid black;
    border-radius: 8px;
    background: white;
  }
 .sidebar {
  height: 100%;
  display: flex;
}

.sideBar .top .h4 {
  text-align: center;
}

.sideBar .top img {
  width: 100%;
}

.aside_content {
  flex: 1;
  height: 100%;
  background-color: #374254;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.aside_bar {
  height: 100%;
  background-image: linear-gradient(
    270deg,
    #6a7ae9 0%,
    #4253c3 77%,
    #2335a5 100%
  );
  border-radius: 0 18px 18px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aside_bar button {
  color: white;
  min-width: unset !important;
  display: flex;
  justify-content: center;
}

.aside_bar button svg {
  transform: translateX(7px);
  font-size: 30px;
}

.aside_bottom {
  border-top: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aside_bottom button {
  padding: 0;
  margin: o;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aside_bottom svg {
  color: white;
  cursor: pointer;
}

.aside_top {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #fff;
}

.aside_top .top {
  display: flex;
  align-items: flex-start;
  column-gap: 4.62%;
  padding-left: 3.85%;
  padding-top: 20px;
}

.aside_top .top h2 {

  color: white;
  margin: 12px 0;
}

.middle_area {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%;
  border-left: 1px solid #ddd;
  flex: 1;
}

.middle_area button img {
  width: 100%;
}

.aside_top .bottom {
  display: flex;
  align-items: center;
  padding: 0 0 0 4.762%;
  line-height: 36.8px;
  position: relative;
}

.aside_top .bottom h4 {
  color: white;
}

.aside_top .bottom button {
  color: white;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 !important;
}

.aside_bar button {
  padding: 0;
}

.aside_middle {
  flex: 1;
  overflow: auto;
}

.aside_middle::-webkit-scrollbar {
  display: none;
}

.personal_profile {
  background-color: #2a333a;
}

.usr_side_bar a button {
  width: 100%;
}

.sideBar {
  width: 100%;
  height: 100%;
  display: flex;
  font-family: sans-serif;
}

.contextArea {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  padding-left: 2.033%;
}

.sideBar .left {
  padding: 10px 7.69%;
  flex: 1;
  background: #374254;
}

.sideBar .left .top {
  margin-bottom: 72px;
}

.sideBar .left .top .h4 {
  color: #fff;
}

.sideBar .left .bottom {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.sideBar .left .bottom button {
  padding: 7%;
  border-radius: 10px;
  background-color: #6a7ae9;
  border: 1px solid #707070;
  color: white;
  text-transform: capitalize;
}

.sideBar .right {
  flex-basis: 7.143%;
  background-image: linear-gradient(
    270deg,
    #6a7ae9 0%,
    #4253c3 77%,
    #2335a5 100%
  );
  border-radius: 0 18px 18px 0;
}

.contextArea .top {
  height: 90px;
  background-image: linear-gradient(
    180deg,
    #6a7ae9 0%,
    #4253c3 83%,
    #2335a5 100%
  );
  border-top-left-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  color: #fff;
}

.contextArea .bottom {
  border-bottom-left-radius: 18px;
  height: 30px;
  background-image: linear-gradient(
    0deg,
    #2335a5 0%,
    #4253c3 61%,
    #6a7ae9 100%
  );
}

.personalData {
  width: 100%;
  height: 100%;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  margin-bottom: 3.5353%;
}

.subscription {
  width: 100%;
  height: 100%;
}

.referral {
  width: 100%;
  height: 100%;
}



.message {
  color: white;
  margin-bottom: 20px;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

.private_data {
  display: flex;
  justify-content: space-between;
  column-gap: 30px;
  margin-bottom: 20px;
}

.private_data .left {
  flex-basis: 37.5%;
  color: white;
}

.private_data .left75 {
  flex-basis: 75%;
  color: white;
}

.private_field input {
  background: white;
  border-radius: 10px;
  height: 60px;
  border: none;
  outline: none;
}

.private_data input,
.private_data .left input,
.private_data .left75 input,
.private_data .middle input,
.private_data .right input,
.private_data .right select,
.educationalBg {
  background: white;
  border-radius: 10px;
  height: 60px;
  border: none;
  outline: none;
}

.input-filed {
  background: white;
  border-radius: 10px;
  height: 60px;
  border: none;
  outline: none;
}

.private_data .left input:hover,
.private_data .left75 input:hover,
.private_data .middle input:hover,
.private_data .right input:hover {
  background: white;
}

.private_data .middle {
  color: white;
  flex-basis: 37.5%;
}

.private_data .right {
  color: white;
  flex-basis: 25%;
}

.inputfiled-container {
  width: 100%;
  margin-bottom: 20px;
}

.inputfiled-container .labels {
  margin-bottom: 12px;
  width: 100%;
  color: #fff;
}

.inputfiled-container input {
  border: none;
  outline: none;
  display: block;
  width: 100%;
}

.form-group {
  display: block;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
  display: block;
}

.form-group label:before {
  content: '';
  -webkit-appearance: none;
  background-color: #fff;
  border: 2px solid #ffffff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  height: 50px;
  width: 50px;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 30px;
  border-radius: 10px;
}

.form-group input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  left: 15px;
  width: 20px;
  height: 40px;
  border: solid #000;
  border-width: 0 6px 6px 0;
  transform: rotate(45deg);
}

.private_data .check {
  margin-bottom: 30px;
}

.actionButtons {
  display: flex;
  align-items: center;
}

.actionButtons button {
  background: #6a7ae9 !important;
  min-width: unset !important;
  text-transform: capitalize;
  border-radius: 10px;
  margin-right: 8%;
  height: 60px;
  width: 10%;
}

.left_align {
  text-align: left !important;
  padding-left: 30px !important;
}

@media screen and (max-width: 600px) {
  .private_data .left,
  .private_data .middle,
  .private_data .right {
    flex-basis: 100% !important;
  }
  .private_data {
    flex-wrap: wrap;
  }
  .private_data .left input,
  .private_data .middle input,
  .private_data .right input.private_data input {
    height: 30px;
  }
  .private_data .left input,
  .private_data .middle input,
  .private_data .right input {
    height: 30px;
  }
  .actionButtons button {
    background: #6a7ae9 !important;
    min-width: unset !important;
    text-transform: capitalize;
    border-radius: 10px;
    margin-right: 8%;
    height: 60px;
    width: 50%;
  }

  .form-group label:before {
    height: 25px;
    width: 25px;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 15px;
    border-radius: 5px;
  }

  .form-group input:checked + label:after {
    top: 1px;
    left: 7.5px;
    width: 10px;
    height: 20px;
    border-width: 0 3px 3px 0;
  }
  .personalData {
    margin-bottom: 1.7676%;
  }
}

fieldset {
  display: none;
}

.Mui-focused {
  border: 1px solid black !important;
}
.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-adornedEnd.create_pass_input.h7.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  
 

}

@media only screen and (max-width: 768px) {
  .form-group label:before {
    height: 40px !important;
    width: 40px !important;
    margin-right: 15px !important;
  }
  .form-group input:checked + label:after {
    left: 13px !important;
    width: 15px !important;
    height: 30px !important;
  }

  .private_data .left input,
  .private_data .middle input,
  .private_data .right input,
  .private_data .right select,
  .educationalBg {
    height: 40px !important;
  }

  .actionButtons button {
    height: 40px !important;
    width: 100px !important;
  }
  ///commented
  .aside_top .top h12 {

    color: white;
    margin: 12px 0;
  }
  
  .middle_area1 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2%;
    border-left: 1px solid #ddd;
    flex: 1;
  }
  
  .middle_area button1 img {
    width: 100%;
  }
  
  .aside_top .bottom1 {
    display: flex;
    align-items: center;
    padding: 0 0 0 4.762%;
    line-height: 36.8px;
    position: relative;
  }
  
  .aside_top .bottom1 h4 {
    color: white;
  }
  
  .aside_top .bottom1 button {
    color: white;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 !important;
  }
  
  .aside_bar button1w {
    padding: 0;
  }
  
  .aside_middle1w {
    flex: 1;
    overflow: auto;
  }
  
  .aside_middlew::-webkit-scrollbar {
    display: none;
  }
  
  .personal_profile1 {
    background-color: #2a333a;
  }
  
  .usr_side_bar1 a button {
    width: 100%;
  }
  
  .sideBar1 {
    width: 100%;
    height: 100%;
    display: flex;
    font-family: sans-serif;
  }
  
  .contextAreaw {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    padding-left: 2.033%;
  }
  
  .sideBar .leftw {
    padding: 10px 7.69%;
    flex: 1;
    background: #374254;
  }
  
  .sideBar .left .topw {
    margin-bottom: 72px;
  }
  
  .sideBar1 .left .top .h4 {
    color: #fff;
  }
  
  .sideBar1 .left .bottom {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
  }
  
  .sideBar1 .left .bottom button {
    padding: 7%;
    border-radius: 10px;
    background-color: #6a7ae9;
    border: 1px solid #707070;
    color: white;
    text-transform: capitalize;
  }
  
  .sideBar1 .right {
    flex-basis: 7.143%;
    background-image: linear-gradient(
      270deg,
      #6a7ae9 0%,
      #4253c3 77%,
      #2335a5 100%
    );
    border-radius: 0 18px 18px 0;
  }
  
  .contextArea1 .top {
    height: 90px;
    background-image: linear-gradient(
      180deg,
      #6a7ae9 0%,
      #4253c3 83%,
      #2335a5 100%
    );
    border-top-left-radius1: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
    color: #fff;
  }
  
  .contextArea1 .bottom {
    border-bottom-left-radius: 18px;
    height: 30px;
    background-image: linear-gradient(
      0deg,
      #2335a5 0%,
      #4253c3 61%,
      #6a7ae9 100%
    );
  }
  
  .personalData1 {
    width: 100%;
    height: 100%;
    font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
    margin-bottom: 3.5353%;
  }
  
  .subscription1 {
    width: 100%;
    height: 100%;
  }
  
  .referral1 {
    width: 100%;
    height: 100%;
  }
  
  
  
  .message1 {
    color: white;
    margin-bottom: 20px;
    font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  }
  
  .private_data1 {
    display: flex;
    justify-content: space-between;
    column-gap: 30px;
    margin-bottom: 20px;
  }
  
  .private_data .left1 {
    flex-basis: 37.5%;
    color: white;
  }
  
  .private_data .left715 {
    flex-basis: 75%;
    color: white;
  }
  
  .private_field1 input {
    background: white;
    border-radius: 10px;
    height: 60px;
    border: none;
    outline: none;
  }
  
  .private_data input1,
  .private_data .left input,
  .private_data .left75 input,
  .private_data .middle input,
  .private_data .right input,
  .private_data .right select,
  .educationalBg {
    background: white;
    border-radius: 10px;
    height: 60px;
    border: none;
    outline: none;
  }
  
  .input-filed1 {
    background: white;
    border-radius: 10px;
    height: 60px;
    border: none;
    outline: none;
  }
  
  .private_data .left input1:hover,
  .private_data .left75 input:hover,
  .private_data .middle input:hover,
  .private_data .right input:hover {
    background: white;
  }
  
  .private_data1 .middle {
    color: white;
    flex-basis: 37.5%;
  }
  
  .private_data1 .right {
    color: white;
    flex-basis: 25%;
  }
  
  .inputfiled-container1 {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .inputfiled-containe1r .labels {
    margin-bottom: 12px;
    width: 100%;
    color: #fff;
  }
  
  .inputfiled-container1 input {
    border: none;
    outline: none;
    display: block;
    width: 100%;
  }
  
  .form-group1 {
    display: block;
  }
  
  .form-group1 input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  
  .form-group1 label {
    position: relative;
    cursor: pointer;
    display: block;
  }
  
  .form-group1 label:before {
    content: '';
    -webkit-appearance: none;
    background-color: #fff;
    border: 2px solid #ffffff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    height: 50px;
    width: 50px;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 30px;
    border-radius: 10px;
  }
  
  .form-group1 input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 15px;
    width: 20px;
    height: 40px;
    border: solid #000;
    border-width: 0 6px 6px 0;
    transform: rotate(45deg);
  }
  
  .private_dat1a .check {
    margin-bottom: 30px;
  }
  
  .actionButtons1 {
    display: flex;
    align-items: center;
  }
  
  .actionButtons buttons {
    background: #6a7ae9 !important;
    min-width: unset !important;
    text-transform: capitalize;
    border-radius: 10px;
    margin-right: 8%;
    height: 60px;
    width: 10%;
  }
  
  .left_aligns {
    text-align: left !important;
    padding-left: 30px !important;
  }
  
  @media screenq and (max-width: 600px) {
    .private_data .left,
    .private_data .middle,
    .private_data .right {
      flex-basis: 100% !important;
    }
    .private_data {
      flex-wrap: wrap;
    }
    .private_dataq .left input,
    .private_data .middle input,
    .private_data .right input.private_data input {
      height: 30px;
    }
    .private_data1 .left input,
    .private_data .middle input,
    .private_data .right input {
      height: 30px;
    }
    .actionButtons1 button {
      background: #6a7ae9 !important;
      min-width: unset !important;
      text-transform: capitalize;
      border-radius: 10px;
      margin-right: 8%;
      height: 60px;
      width: 50%;
    }
  
    .form-groups label:before {
      height: 25px;
      width: 25px;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 15px;
      border-radius: 5px;
    }
  
    .form-groups input:checked + label:after {
      top: 1px;
      left: 7.5px;
      width: 10px;
      height: 20px;
      border-width: 0 3px 3px 0;
    }
    .personalData {
      margin-bottom: 1.7676%;
    }
  }
  
  fieldset1 {
    display: none;
  }
  
  .Mui-focused1 {
    border: 1px solid black !important;
  }
  .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-adornedEnd.create_pass_input.h7.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    
   
  
  }
  
  @media only screen and (max-width: 768px) {
    .form-group1 label:before {
      height: 40px !important;
      width: 40px !important;
      margin-right: 15px !important;
    }
    .form-groups input:checked + label:after {
      left: 13px !important;
      width: 15px !important;
      height: 30px !important;
    }
  
    .private_data2 .left input,
    .private_data2 .middle input,
    .private_data2 .right input,
    .private_data2 .right select,
    .educationalBgs {
      height: 40px !important;
    }
  
    .actionButtons buttons {
      height: 40px !important;
      width: 100px !important;
    }

    .passwordInput {
      width: '100%',
      background:"white"
    }
} 
  
`
