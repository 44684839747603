import { Fragment } from 'react'
import AuthNavbar from 'components/AuthNavbar'
import LoginFooter from 'components/LoginFooter'
import {
	LeftDiv,
	RightDiv,
	TerminateAccountPageContainer
} from './TerminateAccountPageSkin'
import SideBar from 'shared/Sidebar'
import useAuth from 'hooks/useAuth'
import TerminateAccount from 'components/TerminateAccount'

const TerminateAccountPage = () => {
	const { auth } = useAuth()

	return (
		<Fragment>
			<AuthNavbar data-testid="auth-navbar" />
			<TerminateAccountPageContainer data-testid="page-container">
				<LeftDiv data-testid="left-div">
					<SideBar data-testid="sidebar" />
				</LeftDiv>

				<RightDiv data-testid="right-div">
					<div className="contextArea" data-testid="context-area">
						<div
							style={{ marginBottom: 0 }}
							className="top h4"
							data-testid="page-title"
						>
							Account Termination
						</div>

						<div className="middle_area" data-testid="middle-area">
							<TerminateAccount
								id={auth?.user?.id}
								data-testid="terminate-account"
							/>
						</div>

						<div className="bottom" data-testid="bottom-area" />
					</div>
				</RightDiv>
			</TerminateAccountPageContainer>

			<LoginFooter data-testid="login-footer" />
		</Fragment>
	)
}

export default TerminateAccountPage
