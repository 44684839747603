//Libraries
import { Fragment, useEffect, useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
	Button,
	CircularProgress,
	Grid,
	IconButton,
	InputAdornment,
	OutlinedInput,
	Typography
} from '@mui/material'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useHistory, Link } from 'react-router-dom'
import Swal from 'sweetalert2'

//APIs
import { getUserById } from '../../api/user'
import { confirmRegistration } from '../../api/auth'

//Hooks
import useAuth from '../../hooks/useAuth'

//Utils
import { isPasswordStrong } from '../../utils/isPasswordStrong'

//Components
import LoadingIndicator from '../LoadingIndicator'
import LoginFooter from '../LoginFooter'
import RegisterPolicyConsent from '../RegisterPolicyConsent'

//Styled components
import { CreateAccountCon } from './skins'

//Various (images, ...)
import LogoContainer from 'shared/LogoContainer'

const CreateAccount = ({ id }) => {
	const { t } = useTranslation()
	const history = useHistory()

	const { setAuth } = useAuth()

	const [showPassword, setShowPassword] = useState(false)
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPass] = useState('')
	const [buttonLoading, setButtonLoading] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [consentIds, setConsentIds] = useState([])
	const [allRequiredConsentsGiven, setAllRequiredConsentsGiven] =
		useState(false)

	//State variable to enable the confirmation button, depending on the checkboxes rendered by component RegisterPolicyConsent
	const [buttonEnabled, setButtonEnabled] = useState(false)

	const handleConsentChange = (
		updatedAllRequiredConsentsGiven,
		updatedConsentIds
	) => {
		//*************************************** */
		// console.log('updatedConsentIds from CreateAccount =', updatedConsentIds)
		// console.log(
		// 	'updatedAllRequiredConsentsGiven from CreateAccount =',
		// 	updatedAllRequiredConsentsGiven
		// )
		//*************************************** */

		setButtonEnabled(updatedAllRequiredConsentsGiven === true) // Enable button based on consentIds
		setConsentIds(updatedConsentIds) // Update state with new consentIds
		setAllRequiredConsentsGiven(updatedAllRequiredConsentsGiven)
	}

	//*********************************************** */
	// useEffect(() => {
	// 	console.log('consentIds from CreateAccount= ', consentIds)
	// 	console.log(
	// 		'allRequiredConsentsGiven from CreateAccount= ',
	// 		allRequiredConsentsGiven
	// 	)
	// }, [consentIds, allRequiredConsentsGiven])

	//*********************************************** */

	useEffect(() => {
		fetchUserById(id)
	}, [id])

	const fetchUserById = async (id) => {
		try {
			const { data } = await getUserById(id)

			setName(data.user_name)

			setEmail(data.user_email)
			setIsLoading(false)
		} catch (error) {
			if (error.response) {
				Swal.fire({
					position: 'center',
					icon: 'error',
					title: error.response.data.message,
					text: 'Please try to login or create a account',
					showConfirmButton: false,
					timer: 3000
				})
			} else if (error.request) {
				Swal.fire({
					position: 'center',
					icon: 'error',
					title: error.request,
					text: 'Please try to login or create a account',
					showConfirmButton: false,
					timer: 3000
				})
			} else {
				Swal.fire({
					position: 'center',
					icon: 'error',
					title: error.message,
					text: 'Please try to login or create a account',
					showConfirmButton: false,
					timer: 3000
				})
			}

			setTimeout(() => {
				history.push('/login')
			}, 3005)
		}
	}

	if (isLoading) {
		return <LoadingIndicator />
	}

	const handleRegister = async () => {
		setButtonLoading(true)

		//Set the error messages to be displayed
		const shortName = t('shortName')
		const weakPassword = t('weakPassword')

		if (name.length < 3) {
			toast.error(shortName)
			setButtonLoading(false)
		} else if (!isPasswordStrong(password)) {
			toast.error(weakPassword)
			setButtonLoading(false)
		} else {
			try {
				const { data } = await confirmRegistration({
					id,
					name,
					email,
					password,
					consentIds
				})

				setAuth({
					token: data.token,
					user: data.user
				})

				sessionStorage.setItem('token', data.token)
				sessionStorage.setItem('user', JSON.stringify(data.user))

				setButtonLoading(false)

				history.push('/dashboard')
			} catch (error) {
				if (error.response) {
					toast.error(error.response.data.message)
				} else if (error.request) {
					toast.error(error.request)
				} else {
					toast.error(error.message)
				}
			}
		}
	}

	return (
		<Fragment>
			<CreateAccountCon>
				<Grid container className="signup-container">
					<Grid item xs={2} sm={4} lg={4} xl={4} />

					<Grid item xs={8} sm={4} lg={4} xl={3} align="center">
						<Link to="/">
							<LogoContainer />
						</Link>

						<div className="signup-input-container">
							<Typography variant="h4" className="signup-title h4">
								{t('create_your_ac')}
							</Typography>
							<div className="input-container">
								<span className="policy-text h7">{t('policy_text')}</span>
							</div>

							<div className="input-container">
								<span className="signup-labels h6">{t('your_name')}</span>
								<OutlinedInput
									onChange={(e) => setName(e.target.value)}
									className="signup-input h7"
									id="outlined-adornment-name"
									type="text"
									name="username"
									value={name}
									endAdornment={<InputAdornment position="end" />}
								/>
							</div>

							<div className="input-container">
								<span className="signup-labels h6">{t('email_subs')}</span>

								<OutlinedInput
									className="signup-input h7"
									readOnly
									title={t('email_cant_changed')}
									id="outlined-adornment-email"
									type="email"
									name="email"
									value={email}
									endAdornment={<InputAdornment position="end" />}
								/>
							</div>

							<div className="input-container">
								<span className="signup-labels h6">{t('pwd')}</span>

								<OutlinedInput
									className="signup-input h7"
									id="outlined-adornment-password"
									type={showPassword ? 'text' : 'password'}
									value={password}
									onChange={(e) => setPass(e.target.value)}
									name="password"
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={() => setShowPassword(!showPassword)}
												edge="end"
											>
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									}
								/>
							</div>

							<div className="input-container">
								<Button
									onClick={handleRegister}
									variant="contained"
									className="signup-btn h6"
									disabled={!buttonEnabled}
								>
									{buttonLoading ? (
										<CircularProgress
											style={{ height: '28px', width: '28px' }}
											color="inherit"
										/>
									) : (
										t('create_ac')
									)}
								</Button>
							</div>
							<div className="input-container">
								{/* Here should go component RegisterPolicyConsent */}
								<RegisterPolicyConsent
									id={id}
									onConsentChange={handleConsentChange}
								/>
								{/* <span className="policy-text h7">{t('policy_text2')}</span> */}
							</div>
						</div>
					</Grid>
					<Grid item xs={2} sm={4} lg={4} xl={4} />
				</Grid>
			</CreateAccountCon>
			<LoginFooter />
		</Fragment>
	)
}

export default CreateAccount
