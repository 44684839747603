import { Fragment, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { IoPencilOutline, IoTrashOutline } from 'react-icons/io5'
import Swal from 'sweetalert2'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import { queryClient } from 'App'
import useAuth from 'hooks/useAuth'
import { deleteBook, getBookList } from 'api/book'
import UpdateBookModal from 'components/UpdateBookModal'
import LoadingIndicator from '../LoadingIndicator'
import { BookAccordionContainer } from './bookAccordionSkin'
import useBook from 'hooks/useBook'
import { useParams, useHistory } from 'react-router-dom'
import {
	deleteBooksFromIndexedDB,
	deleteSingleBookFromIndexedDB
} from 'utils/idbIndexed'

const BookAccordion = ({
	setOnLoadBook,
	onLoadBok,
	selectedBook,
	setNextPageHandle,
	setPreviousPageHandle,
	isBookUpdated
}) => {
	const history = useHistory()
	const { bookInfo, setBookInfo } = useBook()
	const {
		auth: { token }
	} = useAuth()
	const [updateModalOpen, setUpdateModalOpen] = useState(false)
	const [selectedDoc, setSelectedDoc] = useState()
	const { bookId } = useParams()

	const { isLoading, data } = useQuery(
		['books-list', token],
		() => getBookList(token),
		{
			staleTime: 'Infinity',
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			retry: false
		}
	)

	const updateModalClick = (doc) => {
		setSelectedDoc(doc)
		setUpdateModalOpen(true)
	}

	const updateModalClose = () => {
		setUpdateModalOpen(false)
	}

	const onDeleteBook = async (id) => {
		Swal.fire({
			title:
				'You are going to irreversibly delete the book, are you sure? We advise against!',
			showConfirmButton: true,
			confirmButtonText: 'Yes',
			showLoaderOnConfirm: true,
			showCancelButton: true,
			preConfirm: () => {
				return deleteBook(id, token)
					.then((response) => {
						if (response.error) {
							throw new Error(response.error.response.data.message)
						}
						return response.data.message
					})
					.catch((error) => {
						Swal.showValidationMessage(`Request failed: ${error}`)
					})
			},
			allowOutsideClick: () => !Swal.isLoading()
		}).then((btn) => {
			if (btn.isConfirmed) {
				Swal.fire({
					icon: 'success',
					title: 'Book deleted successfully'
				})

				queryClient.invalidateQueries('books-list')
				queryClient.removeQueries(['book', id])
				if (bookId === id) {
					history.replace(`/library`)
				}
			}
		})
		await deleteSingleBookFromIndexedDB(id)
	}

	useEffect(() => {
		if (bookId) {
			setBookInfo({ ...bookInfo, bookId: bookId })
		}
		return () => {
			setBookInfo({ ...bookInfo, bookId: '' })
		}
	}, [bookId])

	useEffect(async () => {
		if (data) {
			await deleteBooksFromIndexedDB(data)
		}
	}, [data])

	const handleOnClick = (doc) => {
		setOnLoadBook(false)
		setNextPageHandle(false)
		setPreviousPageHandle(false)
		setBookInfo({
			...bookInfo,
			bookId: doc._id
		})
		history.push(`/library/${doc._id}`)
	}

	return (
		<Fragment>
			{isLoading ? (
				<LoadingIndicator />
			) : (
				data
					?.sort((a, b) => {
						const aTitle = a?.book_title?.toLowerCase()
						const bTitle = b?.book_title?.toLowerCase()
						if (aTitle < bTitle) {
							return -1
						} else if (aTitle > bTitle) {
							return 1
						} else {
							return 0
						}
					})
					?.map((doc) => (
						<BookAccordionContainer
							key={doc._id}
							selected={bookInfo.bookId === doc._id}
						>
							<Button>
								<IoPencilOutline
									className="h8"
									onClick={() => updateModalClick(doc)}
								/>
							</Button>

							<Button>
								<IoTrashOutline
									className="h8"
									onClick={() => onDeleteBook(doc._id)}
								/>
							</Button>

							<Typography
								onClick={() => (onLoadBok ? handleOnClick(doc) : null)}
								className="h8 book_title"
							>
								{doc.book_title}
							</Typography>

							{updateModalOpen && (
								<UpdateBookModal
									book_id={selectedDoc._id}
									book_title={selectedDoc.book_title}
									book_author={selectedDoc.book_author}
									book_issn={selectedDoc.book_issnisbn}
									book_note={selectedDoc.book_notes}
									handleClose={updateModalClose}
									modalOpen={updateModalOpen}
									updatedBookTitle={(title) => isBookUpdated(title)}
								/>
							)}
						</BookAccordionContainer>
					))
			)}
		</Fragment>
	)
}

export default BookAccordion
