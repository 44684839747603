import styled from '@emotion/styled/macro'
import { h5FontSize, h8FontSize } from '../../appSkin'
export const Container = styled.div`
	padding-left: 20px;
	width: 100%;
	height: 100%;
	.loader {
		width: 100%;
		height: 90%;
		display: flex;
		justify-content: center;
	}
	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	h1 {
		margin-top: 6pt;
		${h5FontSize};
		font-family: 'Calibri Light', sans-serif;
		color: #2f5496;
		font-weight: bold;
	}
`
export const LoadingMessage = styled.div`
	${h8FontSize};
`

//The following functions are not in use in the index.js file
export const ModalOverlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
`

export const ModalContainer = styled.div`
	background: white;
	padding: 0;
	border-radius: 10px;
	width: 100%; // Change as per your requirement
	height: 100%; // Change as per your requirement
`

export const PDFContainer = styled.div`
	position: relative;
	z-index: 1000;
	width: 100%;
	height: 100%;
	overflow-y: auto;
`
