import { Grid } from '@mui/material'
import { NavLink } from 'react-router-dom'
import profile from 'assets/profile-head.png'
import { SidebarContainer } from './sidebarSkin'

const Sidebar = ({ userBirth, userAge }) => {
	// Convert environment variable to boolean
	const isSubscriptionOn = process.env.REACT_APP_SUBSCRIPTION_ON === 'true'

	//************************************************** */
	// console.log('Sidebar launched!')
	// console.log('isSubscriptionOn: ', isSubscriptionOn)
	//************************************************** */

	return (
		<SidebarContainer>
			<div className="left">
				<div className="top">
					<Grid container columns={10}>
						<Grid item xs={3}>
							<img src={profile} alt="" className="img" />
						</Grid>
						<Grid
							item
							xs={7}
							display="flex"
							alignItems="center"
							justifyContent="center"
						>
							<h1 className="h4">User Profile</h1>
						</Grid>
					</Grid>
				</div>
				<div className="bottom">
					<NavLink
						to="/profile"
						className={(isActive) => 'h4 navLink' + (isActive ? ' active' : '')}
					>
						Personal Info
					</NavLink>

					{/* Conditional rendering for Subscription based on environment variable */}
					{isSubscriptionOn && (
						<NavLink
							to="/subscription"
							className={(isActive) =>
								'h4 navLink' + (isActive ? ' active' : '')
							}
						>
							Subscription
						</NavLink>
					)}

					<NavLink
						to="/referrals"
						className={(isActive) => 'h4 navLink' + (isActive ? ' active' : '')}
					>
						Referrals
					</NavLink>
					{!userBirth || userAge > 14 ? (
						<NavLink
							to="/review"
							className={(isActive) =>
								'h4 navLink' + (isActive ? ' active' : '')
							}
						>
							Review
						</NavLink>
					) : null}

					<NavLink
						to="/change-password"
						className={(isActive) =>
							'h4 navLinkPassChange' + (isActive ? ' active' : '')
						}
					>
						Change Password
					</NavLink>

					<NavLink
						to="/terminate"
						className={(isActive) =>
							'h4 navLink terminate' + (isActive ? ' active' : '')
						}
					>
						Terminate Account
					</NavLink>
				</div>
			</div>
			<div className="right" />
		</SidebarContainer>
	)
}

export default Sidebar
