import AuthNavbar from '../../components/AuthNavbar'
import LoginFooter from '../../components/LoginFooter/index'

const PaymentCancelled = () => {
	return (
		<div>
			<AuthNavbar />

			<h1 className="h2">PaymentDone Cancelled</h1>
			<LoginFooter />
		</div>
	)
}

export default PaymentCancelled
