import { Grid, Typography } from '@mui/material'
import StarRateIcon from '@mui/icons-material/StarRate'
import Carousel from 'react-multi-carousel'
import LoadingIndicator from 'components/LoadingIndicator'
import { CustomerReviewCon } from './customerReviewSkin'
import MaleAvatar from 'assets/icons/male-avatar-large.png'
import FemaleAvatar from 'assets/icons/female-avatar-large.png'
import { getUserReview } from 'api/review'

const CRCarousel = ({ reviews, responsive }) => {
	const reviewItems =
		reviews &&
		reviews.length > 0 &&
		reviews.map((review) => {
			const starsList = []
			for (let sid = 0; sid < review.userRev_score; sid++) {
				starsList.push(
					<StarRateIcon key={sid} className="h5" style={{ color: '#ccd647' }} />
				)
			}

			return (
				<div key={review._id} className="review-item">
					<Grid container>
						<Grid item xs={12}>
							{review.userRev_user_id.user_photo ? (
								<img
									src={review.userRev_user_id.user_photo}
									className="reviewer-image"
									alt=""
								/>
							) : review.userRev_user_id.user_sex === 'female' ? (
								<img src={FemaleAvatar} className="reviewer-image" alt="" />
							) : (
								<img src={MaleAvatar} className="reviewer-image" alt="" />
							)}
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h5" className="customer-name h5">
								{review.userRev_user_id.user_name +
									' ' +
									review.userRev_user_id.user_surname}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body1" className="customer-country h7">
								{review.userRev_user_id.user_nation}
							</Typography>
						</Grid>
						<Grid item xs={12} display="flex" justifyContent="center">
							{starsList}
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body2" className="customer-review-text h8b">
								{review.userRev_title}
							</Typography>
						</Grid>
					</Grid>
				</div>
			)
		})

	return (
		<div data-testid="carousel-container">
			<Carousel
				swipeable={false}
				draggable={false}
				responsive={responsive}
				ssr={true}
				infinite={true}
				autoPlaySpeed={3000}
				keyBoardControl={true}
				transitionDuration={500}
			>
				{reviewItems}
			</Carousel>
		</div>
	)
}

const CustomersReview = ({ isLoading, data = [] }) => {
	const responsive = {
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 5
		},
		tablet: {
			breakpoint: { max: 1024, min: 750 },
			items: 4
		},
		mobile: {
			breakpoint: { max: 750, min: 465 },
			items: 3
		},
		smallMobile: {
			breakpoint: { max: 465, min: 0 },
			items: 1
		}
	}

	return (
		<CustomerReviewCon data-testid="customer-review-container">
			{isLoading ? (
				<LoadingIndicator />
			) : (
				data?.length !== 0 && (
					<Grid container data-testid="reviews-grid">
						<Grid item xs={0.25} />
						<Grid item xs={11.5}>
							<CRCarousel
								style={{ width: '80%' }}
								reviews={data}
								responsive={responsive}
							/>
						</Grid>
						<Grid item xs={0.25} />
					</Grid>
				)
			)}
		</CustomerReviewCon>
	)
}

export default CustomersReview
