import { useState, useEffect } from 'react'
import { updatePageNumber } from 'api/book'

export default function useBookNavigation(token) {
	const [selectedBook, setSelectedBook] = useState('')
	const [currentPage, setCurrentPage] = useState()
	const [nextPageNumber, setNextPageNumber] = useState()
	const [previousPageNumber, setPreviousPageNumber] = useState()
	const [bookTotalPage, setBookTotalPage] = useState()
	const [currentOpenBookId, setCurrentOpenBookId] = useState()
	const [nextPageHandle, setNextPageHandle] = useState(false)
	const [previousPageHandle, setPreviousPageHandle] = useState(false)
	const [handleOnChangePage, setHandleOnChangePage] = useState(false)
	const [refetchBookOnHandleChange, setRefetchBookOnHandleChange] =
		useState(false)
	const [activePageStatus, setActivePageStatus] = useState(true)

	useEffect(() => {
		const fetchData = async () => {
			if (
				!previousPageHandle &&
				nextPageHandle &&
				nextPageNumber &&
				bookTotalPage &&
				currentOpenBookId
			) {
				setCurrentPage(nextPageNumber)
				const _activePageStatus = await updatePage(
					nextPageNumber,
					bookTotalPage,
					currentOpenBookId
				)
				setActivePageStatus(_activePageStatus)
				// setNextPageHandle(false)
			}
			if (
				previousPageHandle &&
				previousPageNumber &&
				bookTotalPage &&
				currentOpenBookId
			) {
				setCurrentPage(previousPageNumber)
				const _activePageStatus = await updatePage(
					previousPageNumber,
					bookTotalPage,
					currentOpenBookId
				)
				setActivePageStatus(_activePageStatus)
				// setPreviousPageHandle(false)
			}
		}

		fetchData()
	}, [nextPageNumber, previousPageNumber])

	useEffect(() => {
		const fetchData = async () => {
			if ((handleOnChangePage && currentPage) || currentPage === 1) {
				const _activePageStatus = await updatePage(
					currentPage,
					bookTotalPage,
					currentOpenBookId
				)
				setActivePageStatus(_activePageStatus)
				setSelectedBook(currentOpenBookId)
				setHandleOnChangePage(false)
				setRefetchBookOnHandleChange(true)
			}
		}

		fetchData()
	}, [handleOnChangePage, currentPage])

	const updatePage = async (pageNumber, totalPage, id) => {
		const {
			data: { activePageStatus }
		} = await updatePageNumber(id, pageNumber, totalPage, token)
		return activePageStatus
	}

	return {
		selectedBook,
		setSelectedBook,
		currentPage,
		setCurrentPage,
		nextPageNumber,
		setNextPageNumber,
		previousPageNumber,
		setPreviousPageNumber,
		bookTotalPage,
		setBookTotalPage,
		currentOpenBookId,
		setCurrentOpenBookId,
		nextPageHandle,
		setNextPageHandle,
		previousPageHandle,
		setPreviousPageHandle,
		handleOnChangePage,
		setHandleOnChangePage,
		refetchBookOnHandleChange,
		setRefetchBookOnHandleChange,
		activePageStatus
	}
}
