import {
	Button,
	CircularProgress,
	Grid,
	OutlinedInput,
	Typography
} from '@mui/material'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'
import LogoContainer from 'shared/LogoContainer'
import { PasswordRecoverySkin } from './skins'
import { forgotPassword } from 'api/auth'

const PassReco = () => {
	const [email, setEmail] = useState('')
	const [loading, setLoading] = useState(false)

	const validateEmail = (email) => {
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		return regex.test(email)
	}

	const emailSubmit = async (event) => {
		event.preventDefault()

		if (!email || !validateEmail(email)) {
			return
		}

		setLoading(true)

		try {
			const { data } = await forgotPassword({ email })

			setEmail('')
			setLoading(false)

			if (data) {
				Swal.fire({
					position: 'center',
					icon: 'success',
					title: 'Password reset done',
					text: `${data.message}`,
					showConfirmButton: false,
					timer: 2000
				})
			}
		} catch (error) {
			setEmail('')
			setLoading(false)
			if (error.response) {
				toast(error.response.data.message)
			} else if (error.request) {
				toast(error.request)
			} else {
				toast(error.message)
			}
		}
	}
	const { t } = useTranslation()

	return (
		<PasswordRecoverySkin>
			<Grid container className="recovery_container" columns={36}>
				<Grid item xs={6} sm={9} lg={12} xl={12} />

				<Grid
					className="forgot"
					item
					xs={24}
					sm={18}
					lg={12}
					xl={9}
					align="center"
				>
					<Link to="/">
						<LogoContainer />
					</Link>
					<form onSubmit={emailSubmit}>
						<div className="recovery_input_container">
							<Typography variant="h4" className="recovery_title h4">
								{t('recover_pass')}
							</Typography>

							<p className="atlhas_p h7">{t('enter_pass')}</p>

							<div className="input-container">
								<span className="login-labels h6">{t('email_addr')}</span>

								<OutlinedInput
									className="recovery_input h7"
									id="outlined-adornment-password"
									name="email"
									type="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
							<div className="input-container">
								<Button
									type="submit"
									variant="contained"
									className="recovery_btn h6"
									disabled={!email || !validateEmail(email)}
								>
									{loading ? (
										<CircularProgress
											style={{ height: '30px', width: '30px' }}
											color="inherit"
										/>
									) : (
										t('continue')
									)}
								</Button>
							</div>
						</div>

						<br />

						<div className="input-container">
							<h2 className="change_email h4">{t('has_email_changed')} </h2>
							<p className="atlhas_p h7">{t('no_longer_access')}</p>
						</div>
					</form>
				</Grid>
				<Grid item xs={6} sm={9} lg={12} xl={12} />
			</Grid>
		</PasswordRecoverySkin>
	)
}

export default PassReco
