import { useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Menu, MenuItem } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'

import { Link, useHistory } from 'react-router-dom'
import library from './../../images/book1.png'
import trash from './../../images/delete-24px.png'
import logo from './../../images/footerLogo.png'
import QnA from './../../images/qa1.png'
import sendWatch from './../../images/sand-watch.png'
import dashboardIcon from './../../images/dashbaord-icon.png'
import { AuthenticatedNavbarSkin } from './authNavbarSkin'
import useAuth from '../../hooks/useAuth'
import MaleAvatar from 'assets/icons/male-avatar.png'
import FemaleAvatar from 'assets/icons/female-avatar.png'
import Countdown from 'components/Countdown'
import PopupSuggestionsModal from 'components/PopupSuggestionsModal'
import Alert from 'components/NewAlertBox'
import { useTranslation } from 'react-i18next'

const AuthNavbar = () => {
	const history = useHistory()
	const { auth, setAuth } = useAuth()
	const [anchorEl, setAnchorEl] = useState(null)
	const [isSuggestionModalOpen, setIsSuggestionModalOpen] = useState(false)
	const [isAlertOpen, setIsAlertOpen] = useState(false)
	const { t } = useTranslation()

	const open = Boolean(anchorEl)
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	const logout = () => {
		setAuth({})
		sessionStorage.removeItem('token')
		sessionStorage.removeItem('user')
		localStorage.removeItem('timer')
		history.push('/')
	}

	const closeSuggestionModal = () => {
		setIsSuggestionModalOpen(false)
	}

	return (
		<AuthenticatedNavbarSkin id="navbar">
			<Box className="appBar" sx={{ flexGrow: 1 }}>
				<Grid sx={{ alignItems: 'center' }} container>
					<Grid item xs={3}>
						<Box className="logo">
							<Link className="h7" to="/dashboard">
								<img src={logo} alt="logo" />
							</Link>
						</Box>
					</Grid>
					<Grid item xs={6}>
						<Box className="h7 menu-">
							<Countdown />
						</Box>
					</Grid>
					<Grid item xs={3}>
						<Box className="profile">
							<ul style={{ marginBottom: 0 }}>
								<li className="h7 menu-">
									<Button
										onClick={() => setIsSuggestionModalOpen(true)}
										className="h7"
										variant="text"
									>
										Suggestions
									</Button>
								</li>
								<li className="h7 menu-">
									<Button onClick={logout} className="h7" variant="text">
										Logout
									</Button>
								</li>
								<li className="h7 menu-">
									<Avatar alt={auth?.user?.name} src={auth?.user?.photo}>
										{auth.user.name[0]}
									</Avatar>
								</li>
								<li className="h7 menu-">
									<Button
										className="h7 toggler"
										variant="text"
										onClick={handleClick}
									>
										{auth?.user?.name ? auth.user.name : 'User'}
										<KeyboardArrowDownIcon />
									</Button>
								</li>
							</ul>
						</Box>
					</Grid>
				</Grid>
			</Box>

			<Menu
				anchorEl={anchorEl}
				id="account-menu"
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						width: '200px',
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						'& .MuiAvatar-root': {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1
						},
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0
						}
					}
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				<MenuItem>
					<Link to="/dashboard">
						<Box
							style={{
								display: 'flex',
								alignItems: 'center',
								margin: '3px 0'
							}}
							className="h6"
						>
							<div
								style={{
									width: '50px',
									display: 'flex',
									justifyContent: 'center',
									paddingRight: '15px'
								}}
							>
								<img alt="" src={dashboardIcon} />
							</div>
							Dashboard
						</Box>
					</Link>
				</MenuItem>

				{/* Pomodoro timer menu item */}
				{/* <MenuItem>
					<Box
						style={{
							display: 'flex',
							alignItems: 'center',
							margin: '3px 0'
						}}
						className="h6"
					>
						<div
							style={{
								width: '50px',
								display: 'flex',
								justifyContent: 'center',
								paddingRight: '15px'
							}}
						>
							<img alt="" src={sendWatch} />
						</div>
						Timer
					</Box>
				</MenuItem> */}

				<MenuItem>
					<Link to="/library">
						<Box
							style={{
								display: 'flex',
								alignItems: 'center',
								margin: '3px 0'
							}}
							className="h6"
						>
							<div
								style={{
									width: '50px',
									display: 'flex',
									justifyContent: 'center',
									paddingRight: '15px'
								}}
							>
								<img src={library} alt="" />
							</div>
							Library
						</Box>
					</Link>
				</MenuItem>

				<MenuItem>
					<Link to="/qna">
						<Box
							style={{
								display: 'flex',
								alignItems: 'center',
								margin: '3px 0'
							}}
							className="h6"
						>
							<div
								style={{
									width: '50px',
									display: 'flex',
									justifyContent: 'center',
									paddingRight: '15px'
								}}
							>
								<img src={QnA} alt="" />
							</div>
							Q&A
						</Box>
					</Link>
				</MenuItem>

				<MenuItem>
					<Link style={{ textDecoration: 'none' }} to="/profile">
						<Box
							style={{
								display: 'flex',
								alignItems: 'center',
								margin: '3px 0'
							}}
							className="h6"
						>
							<div
								style={{
									width: '50px',
									display: 'flex',
									justifyContent: 'center',
									paddingRight: '15px'
								}}
							>
								{auth.user.sex === 'female' ? (
									<img src={FemaleAvatar} alt="" />
								) : (
									<img src={MaleAvatar} alt="" />
								)}
							</div>
							Profile
						</Box>
					</Link>
				</MenuItem>

				{/* Trash menu item */}
				{/* <MenuItem>
					<Box
						style={{
							display: 'flex',
							alignItems: 'center',
							margin: '3px 0'
						}}
						className="h6"
					>
						<div
							style={{
								width: '50px',
								display: 'flex',
								justifyContent: 'center',
								paddingRight: '15px'
							}}
						>
							<img src={trash} alt="" />
						</div>
						Trash
					</Box>
				</MenuItem> */}
			</Menu>
			<PopupSuggestionsModal
				closeModal={closeSuggestionModal}
				isModalOpen={isSuggestionModalOpen}
				setIsAlertOpen={setIsAlertOpen}
			/>
			<Alert
				isModalOpen={isAlertOpen}
				title={t('suggestion_created_message1')}
				description={t('suggestion_created_message2')}
				setIsModalOpen={setIsAlertOpen}
			/>
		</AuthenticatedNavbarSkin>
	)
}
export default AuthNavbar
