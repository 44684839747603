import styled from '@emotion/styled/macro'
import { h8FontSize, h9FontSize, h11FontSize } from '../../appSkin.js'

export const LibraryDisplayContainer = styled.div`
	width: 100%;

	* {
		margin-block-start: 0px;
	}

	p {
		margin: 0;
		padding: 0;
	}

	.App-logo {
		pointer-events: none;
	}

	@media (prefers-reduced-motion: no-preference) {
		.App-logo {
			animation: App-logo-spin infinite 5s linear;
		}
	}

	@keyframes App-logo-spin {
		from {
			transform: rotate(0deg);
		}

		to {
			transform: rotate(360deg);
		}
	}

	#loader {
		z-index: 1;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		background-color: rgba(155, 155, 155, 0.7);
	}

	.clickable {
		cursor: pointer;
	}

	.react-pdf__Page__textContent {
		color: white !important;
		top: 20% !important;
		position: static !important;
		transform: none !important;
	}

	.react-pdf__Page__canvas {
		display: none !important;
	}

	.react-pdf__Page__annotations {
		display: none !important;
	}

	.pdf-page {
		position: relative;
		width: 100%;
		height: 100%;
		overflow: visible;
	}

	.pdf-image {
		position: absolute;
		max-width: 100%;
		max-height: 100%;
		object-fit: contain;
	}

	.pdf-display {
		width: 80%;
		border-right: 2px solid white;
	}

	.App-header {
		position: sticky;
		top: 0;
		height: 35px;
		z-index: 250;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: 18px 0 0 0;
		background-image: linear-gradient(
			180deg,
			#6a7ae9 0%,
			#4253c3 83%,
			#2335a5 100%
		);
		color: #fff;
		font-weight: bold;
	}

	.left {
		flex: 80;
		margin-left: 20px;
	}

	.right {
		flex: 20;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.comments {
		cursor: copy;
		width: 20%;
	}

	.comments-area {
		// cursor: copy;
		// width: 20%;
		// position: fixed;
		// right: 0;
		// top: 0;
		// bottom: 0;
	}

	.main-area {
		display: flex;
		position: relative;
		height: 62.8vh;
		overflow: hidden;
	}

	@media (min-width: 1650px) {
		.main-area {
			height: 74vh;
		}
	}

	@media (max-width: 780px) {
		.main-area {
			height: 66vh;
		}
	}
	@media (min-width: 770px) and (max-width: 900px) {
		.main-area {
			height: 59.4vh;
		}
	}

	.plus-icon {
		background: #8dd9d1;
		border-radius: 50%;
		height: 100%;
		aspect-ratio: 1 / 1;
	}

	.comment {
		display: flex;
	}

	// .comment-editor > i {
	// 	${h11FontSize}
	// 	text-align: right;
	// 	z-index: 100;
	// 	margin: 0px 5px;
	// 	margin-left: 10px;
	// 	height: 14px;
	// }

	// .comment-editor svg {
	// 	${h9FontSize};
	// }

	.comment-text {
		${h9FontSize};

		width: 200px;

		@media screen and (max-width: 1440px) {
			width: 133px;
		}

		@media screen and (max-width: 1024px) {
			width: 106px;
		}

		@media screen and (max-width: 800px) {
			width: 66px;
		}

		@media screen and (max-width: 700px) {
			width: 53px;
		}

		@media screen and (max-width: 650px) {
			width: 40px;
		}
	}

	.page-input::-webkit-outer-spin-button,
	.page-input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	.page-input[type='number'] {
		-moz-appearance: textfield;
	}

	.imageDisplay {
		transform: scale(0.4) translateX(-60%);
		position: absolute;
	}
	.footer {
		width: 100%;
		color: #fff;
		text-align: center;
		background-image: linear-gradient(
			180deg,
			#6a7ae9 0%,
			#4253c3 83%,
			#2335a5 100%
		);
		display: flex;

		z-index: 250;
		// position: absolute;
		// bottom: 0px;
		height: 35px;
		border-radius: 0 0 0 18px;
	}

	.footer > p {
		width: 100%;
		font-weight: bold;
	}

	.footer > input {
		color: #000;
	}

	.main-area {
		position: relative;
	}

	.main-area .loader {
		display: flex;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		margin: auto;
		background: #2a333a;
		z-index: 9999990;
	}
`
