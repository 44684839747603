import styled from '@emotion/styled/macro'

export const LeftDiv = styled.div`
	flex-basis: 27.164%;
	max-width: 27.164%;
	overflow: hidden;
	position: relative;

	@media screen and (min-width: 600px) {
		flex-basis: 22.164%;
		max-width: 22.164%;
	}
`

export const RightDiv = styled.div`
	flex-basis: 72.164%;
	max-width: 72.164%;

	@media screen and (min-width: 600px) {
		flex-basis: 77.836%;
		max-width: 77.836%;
	}
`

export const ReferralsPageContainer = styled.div`
	min-height: 100vh;
	display: flex;
	background-color: #2a333a;
	padding: 1.5625% 0;

	.middle_area {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 2%;
		border-left: 1px solid #ddd;
		flex: 1;
	}

	.middle_area button img {
		width: 100%;
	}

	.contextArea {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		overflow: hidden;
		padding-left: 2.033%;
	}

	.sideBar .left {
		padding: 10px 7.69%;
		flex: 1;
		background: #374254;
	}

	.sideBar .left .top {
		margin-bottom: 72px;
	}

	.sideBar .left .top .h4 {
		color: #fff;
	}

	.sideBar .left .bottom {
		display: flex;
		flex-direction: column;
		row-gap: 30px;
	}

	.sideBar .left .bottom button {
		padding: 7%;
		border-radius: 10px;
		background-color: #6a7ae9;
		border: 1px solid #707070;
		color: white;
		text-transform: capitalize;
	}

	.sideBar .right {
		flex-basis: 7.143%;
		background-image: linear-gradient(
			270deg,
			#6a7ae9 0%,
			#4253c3 77%,
			#2335a5 100%
		);
		border-radius: 0 18px 18px 0;
	}

	.contextArea .top {
		height: 90px;
		background-image: linear-gradient(
			180deg,
			#6a7ae9 0%,
			#4253c3 83%,
			#2335a5 100%
		);
		border-top-left-radius: 18px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
		color: #fff;
	}

	.contextArea .bottom {
		border-bottom-left-radius: 18px;
		height: 30px;
		background-image: linear-gradient(
			0deg,
			#2335a5 0%,
			#4253c3 61%,
			#6a7ae9 100%
		);
	}

	.personalData {
		width: 100%;
		height: 100%;
		font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
		margin-bottom: 3.5353%;
	}

	.subscription {
		width: 100%;
		height: 100%;
	}

	.referral {
		width: 100%;
		height: 100%;
	}

	.active {
		background: #a6b1ff !important;
	}

	.message {
		color: white;
		margin-bottom: 20px;
		font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	}

	.private_data {
		display: flex;
		justify-content: space-between;
		column-gap: 30px;
		margin-bottom: 20px;
	}

	.private_data .left {
		flex-basis: 37.5%;
		color: white;
	}

	.private_data .left75 {
		flex-basis: 75%;
		color: white;
	}

	.private_field input {
		background: white;
		border-radius: 10px;
		height: 60px;
		border: none;
		outline: none;
	}

	.private_data input,
	.private_data .left input,
	.private_data .left75 input,
	.private_data .middle input,
	.private_data .right input,
	.private_data .right select,
	.educationalBg {
		background: white;
		border-radius: 10px;
		height: 60px;
		border: none;
		outline: none;
	}

	.input-filed {
		background: white;
		border-radius: 10px;
		height: 60px;
		border: none;
		outline: none;
	}

	.private_data .left input:hover,
	.private_data .left75 input:hover,
	.private_data .middle input:hover,
	.private_data .right input:hover {
		background: white;
	}

	.private_data .middle {
		color: white;
		flex-basis: 37.5%;
	}

	.private_data .right {
		color: white;
		flex-basis: 25%;
	}

	.inputfiled-container {
		width: 100%;
		margin-bottom: 20px;
	}

	.inputfiled-container .labels {
		margin-bottom: 12px;
		width: 100%;
		color: #fff;
	}

	.inputfiled-container input {
		border: none;
		outline: none;
		display: block;
		width: 100%;
	}

	.form-group {
		display: block;
	}

	.form-group input {
		padding: 0;
		height: initial;
		width: initial;
		margin-bottom: 0;
		display: none;
		cursor: pointer;
	}

	.form-group label {
		position: relative;
		cursor: pointer;
		display: block;
	}

	.form-group label:before {
		content: '';
		-webkit-appearance: none;
		background-color: #fff;
		border: 2px solid #ffffff;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
			inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
		padding: 10px;
		display: inline-block;
		height: 50px;
		width: 50px;
		position: relative;
		vertical-align: middle;
		cursor: pointer;
		margin-right: 30px;
		border-radius: 10px;
	}

	.form-group input:checked + label:after {
		content: '';
		display: block;
		position: absolute;
		top: 2px;
		left: 15px;
		width: 20px;
		height: 40px;
		border: solid #000;
		border-width: 0 6px 6px 0;
		transform: rotate(45deg);
	}

	.private_data .check {
		margin-bottom: 30px;
	}

	.actionButtons {
		display: flex;
		align-items: center;
	}

	.actionButtons button {
		background: #6a7ae9 !important;
		min-width: unset !important;
		text-transform: capitalize;
		border-radius: 10px;
		margin-right: 8%;
		height: 60px;
		width: 50%;
	}

	.left_align {
		text-align: left !important;
	}

	@media screen and (max-width: 600px) {
		.private_data .left,
		.private_data .middle,
		.private_data .right {
			flex-basis: 100% !important;
		}
		.private_data {
			flex-wrap: wrap;
		}
		.private_data .left input,
		.private_data .middle input,
		.private_data .right input.private_data input {
			height: 30px;
		}
		.private_data .left input,
		.private_data .middle input,
		.private_data .right input {
			height: 30px;
		}
		.actionButtons button {
			background: #6a7ae9 !important;
			min-width: unset !important;
			text-transform: capitalize;
			border-radius: 10px;
			margin-right: 8%;
			height: 60px;
			width: 50%;
		}

		.form-group label:before {
			height: 25px;
			width: 25px;
			position: relative;
			vertical-align: middle;
			cursor: pointer;
			margin-right: 15px;
			border-radius: 5px;
		}

		.form-group input:checked + label:after {
			top: 1px;
			left: 7.5px;
			width: 10px;
			height: 20px;
			border-width: 0 3px 3px 0;
		}
		.personalData {
			margin-bottom: 1.7676%;
		}
	}

	fieldset {
		display: none;
	}

	.Mui-focused {
		border: 1px solid black !important;
	}
	.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-adornedEnd.create_pass_input.h7.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
		border: 1px solid black;
		background-color: white;
	}

	@media only screen and (max-width: 768px) {
		.form-group label:before {
			height: 40px !important;
			width: 40px !important;
			margin-right: 15px !important;
		}
		.form-group input:checked + label:after {
			left: 13px !important;
			width: 15px !important;
			height: 30px !important;
		}

		.private_data .left input,
		.private_data .middle input,
		.private_data .right input,
		.private_data .right select,
		.educationalBg {
			height: 40px !important;
		}

		.actionButtons button {
			height: 40px !important;
			width: 100px !important;
		}
	}
`
