import LoginFooter from '../../components/LoginFooter'
import PassReco from '../../components/passwordRecovery'

const ForgotPasswordPage = () => {
	return (
		<div
			style={{
				minHeight: '100vh',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between'
			}}
		>
			<PassReco />
			<LoginFooter />
		</div>
	)
}

export default ForgotPasswordPage
