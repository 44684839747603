import axios from 'axios'

const URL = process.env.REACT_APP_URL

const api = axios.create({
	baseURL: `${URL}book`
})

const createConfig = () => {
	const token = sessionStorage.getItem('token')

	return {
		headers: {
			'content-type': 'multipart/form-data',
			Authorization: `Bearer ${token}`
		}
	}
}

export const getBookList = async (token) => {
	const result = await api.get(`/list`, {
		headers: {
			Authorization: `Bearer ${token}`
		}
	})
	return result.data
}

export const addBook = async ({ formData }, token) => {
	const config = {
		headers: {
			'content-type': 'multipart/form-data',
			Authorization: `Bearer ${token}`
		}
	}

	return await api.post('/', formData, config)
}

export const updateBook = async (id, bookMetaData, token) => {
	return await api.put(`/${id}`, bookMetaData, {
		headers: {
			Authorization: `Bearer ${token}`
		}
	})
}

export const postBookContent = async ({ formData, id }) => {
	const config = createConfig()
	const result = await api.post(`/page/${id}`, formData, config)
	return result.data
}

export const updatePageNumber = async (id, page, totalPage, token) => {
	return await api.put(
		`/page/${id}`,
		{ page, totalPage },
		{
			headers: {
				Authorization: `Bearer ${token}`
			}
		}
	)
}

export const deleteBook = async (id, token) => {
	return await api.delete(`/${id}`, {
		headers: {
			Authorization: `Bearer ${token}`
		}
	})
}

export const getBook = async (id, token) => {
	return await api.get(`/${id}`, {
		headers: {
			Authorization: `Bearer ${token}`
		}
	})
}
