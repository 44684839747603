import { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { openDB } from 'idb'

const useGeoLocation = () => {
	const [isLoading, setIsLoading] = useState(true)
	const [isAllowedCountry, setIsAllowedCountry] = useState(null)
	const [countryCode, setCountryCode] = useState(null)
	useEffect(() => {
		const fetchGeoLocationData = async () => {
			try {
				// Open or create database
				const db = await openDB('GeoLocationDB', 2, {
					upgrade(db) {
						db.createObjectStore('geoLocation')
					}
				})

				const currentTime = new Date().getTime()
				// Retrieve data from object store
				const cachedData = await db.get('geoLocation', 'geoLocationData')

				// Fetch IP address
				const { data } = await axios.get('https://api.ipify.org?format=json')
				const currentIpAddress = data.ip

				// Check if cachedData is valid
				if (
					cachedData &&
					cachedData.expiryTime > currentTime &&
					cachedData.IPAddress === currentIpAddress
				) {
					//Log use of geoLocationDB data
					console.log('Here is cachedData in GeoLocationDB => ', cachedData)

					setIsAllowedCountry(cachedData.isAllowedCountry)
					setCountryCode(cachedData.countryCode)
				} else {
					// If the cached data is invalid or missing, call the API
					const response = await axios.get(
						`https://ipapi.co/${currentIpAddress}/json/`
					)
					const countryCode = response.data.country

					//Log use of IPAPI
					console.log('Here is countryCode from IPAPI => ', countryCode)

					const allowedCountries =
						process.env.REACT_APP_ALLOWED_COUNTRIES.split(',').map((country) =>
							country.trim()
						)

					const isCountryAllowed = allowedCountries.includes(countryCode)

					setIsAllowedCountry(isCountryAllowed)
					setCountryCode(countryCode)
					// Cache the new data with expiration
					const expiryTime =
						currentTime +
						Number(process.env.REACT_APP_IP_CACHE_TIME) * 60 * 60 * 1000
					const cacheData = {
						isAllowedCountry: isCountryAllowed,
						countryCode: countryCode,
						expiryTime,
						IPAddress: currentIpAddress
					}

					// Insert or update the object store
					await db.put('geoLocation', cacheData, 'geoLocationData')
				}

				setIsLoading(false)
			} catch (error) {
				console.error('Error fetching geolocation data:', error)
				setIsAllowedCountry(false)
				setCountryCode(false)
				setIsLoading(false)
			}
		}

		fetchGeoLocationData()
	}, [])

	return {
		isAllowedCountry,
		countryCode,
		isLoading
	}
}

export default useGeoLocation
