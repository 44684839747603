import { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { format, parseISO } from 'date-fns'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { getSubscription } from '../../api/user'
import LoadingIndicator from '../LoadingIndicator'
import CheckoutModal from '../CheckoutModal'
import { checkUserName } from '../../api/user'
import { SubscriptionSkin } from './subscriptionSkin.js'
import SubscriptionInvoicingPopup from 'components/SubscriptionInvoicingPopup'

const Subscription = ({ id }) => {
	const { t } = useTranslation()

	const [modalOpen, setModalOpen] = useState(false)
	const [subscriptionModelOpen, setSubscriptionModelOpen] = useState(false)

	const [userPlan, setUserPlan] = useState()
	const [user, setUser] = useState()
	const [subPlan, setSubPlan] = useState()
	const [status, setStatus] = useState('')
	const [extendedDate, setExtendedDate] = useState()
	const [isLoading, setIsLoading] = useState(true)
	const [selectedPlan, setSelectedPlan] = useState()

	useEffect(() => {
		const getSub = async () => {
			const data = await getSubscription(id)
			setUser(data.data.user)
			setSubPlan(data.data.subPlans)
			setUserPlan(data.data.currentUserPlan)
			setExtendedDate(data.data.extendedDate)
			setStatus(data.data.status)
			setIsLoading(false)
		}

		getSub()
	}, [id])

	const handleSubscribe = async () => {
		if (selectedPlan) {
			const token = sessionStorage.getItem('token')
			const {
				data: { identityProvided }
			} = await checkUserName(token)
			if (identityProvided) {
				handleOpen()
			} else {
				setSubscriptionModelOpen(true)
			}
		} else {
			toast.error('Select A Plan first')
		}
	}

	const handleOpen = () => {
		setModalOpen(!modalOpen)
	}

	return (
		<SubscriptionSkin>
			{isLoading ? (
				<LoadingIndicator />
			) : (
				<Grid container className="h6 font">
					<Grid item xs={12}>
						<p className="message h7">{t('subscription_top')}</p>
					</Grid>

					<Grid item xs={12} className="header-section">
						<Grid container>
							<Grid item xs={4.5}>
								<Grid container>
									<Grid item xs={6} className="flex-center">
										Description
									</Grid>
									<Grid
										item
										xs={6}
										className="flex-center"
										style={{ whiteSpace: 'nowrap' }}
									>
										LAST PLAN SUBSCRIBED <br />
										{userPlan.splan_plan_id.plan_units} x{' '}
										{userPlan.splan_subMktg_id.subMktg_unit_id.unit_weeks} weeks
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={7.5}>
								<Grid container colums={10} className="flex-center jutify-end">
									<Grid item xs={2}>
										{subPlan[0].plan_units} x{' '}
										{user.user_subMktg_id.subMktg_unit_id.unit_weeks} <br />
										weeks
									</Grid>
									<Grid item xs={2}>
										{subPlan[1].plan_units} x{' '}
										{user.user_subMktg_id.subMktg_unit_id.unit_weeks} <br />
										weeks
									</Grid>
									<Grid item xs={2}>
										{subPlan[2].plan_units} x{' '}
										{user.user_subMktg_id.subMktg_unit_id.unit_weeks} <br />
										weeks
									</Grid>
									<Grid item xs={2}>
										{subPlan[3].plan_units} x{' '}
										{user.user_subMktg_id.subMktg_unit_id.unit_weeks} <br />
										weeks
									</Grid>
									<Grid item xs={2}>
										{subPlan[4].plan_units} x{' '}
										{user.user_subMktg_id.subMktg_unit_id.unit_weeks} <br />
										weeks
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12} className="discount-section">
						<Grid container>
							<Grid item xs={4.5}>
								<Grid container>
									<Grid item xs={6} className="align-center">
										Discount rate on <br /> Base Subscription
									</Grid>
									<Grid item xs={6} className="flex-center">
										{userPlan.splan_cost === 0
											? 'N.A'
											: userPlan.splan_plan_id.plan_discount === 0
											? 'BASE'
											: `${userPlan.splan_plan_id.plan_discount} %`}
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={7.5} className="flex-center">
								<Grid container colums={10} className="flex-center jutify-end">
									<Grid item xs={2} className="flex-center">
										{subPlan[0].plan_discount === 0
											? 'Base'
											: `${subPlan[0].plan_discount}%`}
									</Grid>
									<Grid item xs={2} className="flex-center">
										{subPlan[1].plan_discount === 0
											? 'Base'
											: `${subPlan[1].plan_discount}%`}
									</Grid>
									<Grid item xs={2} className="flex-center">
										{subPlan[2].plan_discount === 0
											? 'Base'
											: `${subPlan[2].plan_discount}%`}
									</Grid>
									<Grid item xs={2} className="flex-center">
										{subPlan[3].plan_discount === 0
											? 'Base'
											: `${subPlan[3].plan_discount}%`}
									</Grid>
									<Grid item xs={2} className="flex-center">
										{subPlan[4].plan_discount === 0
											? 'Base'
											: `${subPlan[4].plan_discount}%`}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12} className="costing-section">
						<Grid container>
							<Grid item xs={4.5}>
								<Grid container>
									<Grid item xs={6} className="align-center">
										{user.user_subMktg_id.subMktg_unit_id.unit_weeks} weeks
										<br /> Cost
									</Grid>
									<Grid item xs={6} className="flex-center">
										{userPlan.splan_cost === 0
											? 'Free'
											: `${(
													userPlan.splan_subMktg_id.subMktg_unit_id.unit_price -
													(userPlan.splan_plan_id.plan_discount / 100) *
														userPlan.splan_subMktg_id.subMktg_unit_id.unit_price
											  ).toFixed(2)} ${
													userPlan.splan_subMktg_id.subMktg_unit_id
														.unit_currency
											  }`}
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={7.5} className="flex-center">
								<Grid container colums={10} className="flex-center jutify-end">
									<Grid item xs={2} className="flex-center">
										{(
											user.user_subMktg_id.subMktg_unit_id.unit_price -
											(subPlan[0].plan_discount / 100) *
												user.user_subMktg_id.subMktg_unit_id.unit_price
										).toFixed(2)}{' '}
										{user.user_subMktg_id.subMktg_unit_id.unit_currency}
									</Grid>

									<Grid item xs={2} className="flex-center">
										{(
											user.user_subMktg_id.subMktg_unit_id.unit_price -
											(subPlan[1].plan_discount / 100) *
												user.user_subMktg_id.subMktg_unit_id.unit_price
										).toFixed(2)}{' '}
										{user.user_subMktg_id.subMktg_unit_id.unit_currency}
									</Grid>

									<Grid item xs={2} className="flex-center">
										{(
											user.user_subMktg_id.subMktg_unit_id.unit_price -
											(subPlan[2].plan_discount / 100) *
												user.user_subMktg_id.subMktg_unit_id.unit_price
										).toFixed(2)}{' '}
										{user.user_subMktg_id.subMktg_unit_id.unit_currency}
									</Grid>
									<Grid item xs={2} className="flex-center">
										{(
											user.user_subMktg_id.subMktg_unit_id.unit_price -
											(subPlan[3].plan_discount / 100) *
												user.user_subMktg_id.subMktg_unit_id.unit_price
										).toFixed(2)}{' '}
										{user.user_subMktg_id.subMktg_unit_id.unit_currency}
									</Grid>
									<Grid item xs={2} className="flex-center">
										{(
											user.user_subMktg_id.subMktg_unit_id.unit_price -
											(subPlan[4].plan_discount / 100) *
												user.user_subMktg_id.subMktg_unit_id.unit_price
										).toFixed(2)}{' '}
										{user.user_subMktg_id.subMktg_unit_id.unit_currency}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12} className="discount-section">
						<Grid container>
							<Grid item xs={4.5}>
								<Grid container>
									<Grid item xs={6} className="align-center">
										Total Cost
									</Grid>
									<Grid item xs={6} className="flex-center">
										{userPlan.splan_cost === 0
											? 'Free'
											: `${userPlan.splan_cost.toFixed(2)} ${
													userPlan.splan_subMktg_id.subMktg_unit_id
														.unit_currency
											  }`}
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={7.5} className="flex-center">
								<Grid container colums={10} className="flex-center jutify-end">
									<Grid item xs={2} className="flex-center">
										{(
											(
												user.user_subMktg_id.subMktg_unit_id.unit_price -
												(subPlan[0].plan_discount / 100) *
													user.user_subMktg_id.subMktg_unit_id.unit_price
											).toFixed(2) * subPlan[0].plan_units
										).toFixed(2)}{' '}
										{user.user_subMktg_id.subMktg_unit_id.unit_currency}
									</Grid>
									<Grid item xs={2} className="flex-center">
										{(
											(
												user.user_subMktg_id.subMktg_unit_id.unit_price -
												(subPlan[1].plan_discount / 100) *
													user.user_subMktg_id.subMktg_unit_id.unit_price
											).toFixed(2) * subPlan[1].plan_units
										).toFixed(2)}{' '}
										{user.user_subMktg_id.subMktg_unit_id.unit_currency}
									</Grid>
									<Grid item xs={2} className="flex-center">
										{(
											(
												user.user_subMktg_id.subMktg_unit_id.unit_price -
												(subPlan[2].plan_discount / 100) *
													user.user_subMktg_id.subMktg_unit_id.unit_price
											).toFixed(2) * subPlan[2].plan_units
										).toFixed(2)}{' '}
										{user.user_subMktg_id.subMktg_unit_id.unit_currency}
									</Grid>
									<Grid item xs={2} className="flex-center">
										{(
											(
												user.user_subMktg_id.subMktg_unit_id.unit_price -
												(subPlan[3].plan_discount / 100) *
													user.user_subMktg_id.subMktg_unit_id.unit_price
											).toFixed(2) * subPlan[3].plan_units
										).toFixed(2)}{' '}
										{user.user_subMktg_id.subMktg_unit_id.unit_currency}
									</Grid>
									<Grid item xs={2} className="flex-center">
										{(
											(
												user.user_subMktg_id.subMktg_unit_id.unit_price -
												(subPlan[4].plan_discount / 100) *
													user.user_subMktg_id.subMktg_unit_id.unit_price
											).toFixed(2) * subPlan[4].plan_units
										).toFixed(2)}{' '}
										{user.user_subMktg_id.subMktg_unit_id.unit_currency}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12} className="costing-section" />

					<Grid item xs={4.5} className="exp-section">
						<Grid container className="intial-section">
							<Grid xs={6} item className="align-center">
								Initial due expiration
							</Grid>
							<Grid xs={6} item className="flex-center text-start">
								{format(parseISO(userPlan.splan_expDate), 'dd MMMM yy')}
							</Grid>
						</Grid>

						<Grid container className="extend-section">
							<Grid xs={6} item className="align-center">
								Extended to
							</Grid>
							<Grid xs={6} item className="flex-center">
								{format(parseISO(extendedDate), 'dd MMMM yy')}
							</Grid>
						</Grid>

						<Grid container className="intial-section">
							<Grid xs={6} item className="align-center">
								Status
							</Grid>
							<Grid xs={6} item className="flex-center">
								{status}
							</Grid>
						</Grid>

						<Grid container columns={10} className="extend-section" />
					</Grid>

					<Grid item xs={12}>
						<p className="message h7">{t('subscription_bottom')}</p>
					</Grid>

					<Grid item xs={12} className="discount-section align-center">
						<Grid container>
							<Grid item xs={4.5}>
								<Grid container>
									<Grid item xs={6} className="h7">
										<button
											className="subs_btn h6"
											onClick={() => {
												handleSubscribe()
											}}
										>
											Subscribe
										</button>

										{modalOpen && (
											<CheckoutModal
												open={modalOpen}
												setOpen={setModalOpen}
												selectedPlan={selectedPlan}
												user={user}
											/>
										)}
									</Grid>
									<Grid item xs={6} />
								</Grid>
							</Grid>

							<Grid item xs={7.5} className="flex-center">
								<Grid container colums={10} className="flex-center jutify-end">
									<Grid item xs={2} className="justify-center h7">
										<input
											onChange={(e) => {
												setSelectedPlan(subPlan[e.target.value])
											}}
											type="radio"
											id="test1"
											value={0}
											name="subscribe"
										/>
										<label htmlFor="test1" />
									</Grid>
									<Grid item xs={2} className="justify-center h7">
										<input
											onChange={(e) => {
												setSelectedPlan(subPlan[e.target.value])
											}}
											type="radio"
											id="test2"
											value={1}
											name="subscribe"
										/>
										<label htmlFor="test2" />
									</Grid>
									<Grid item xs={2} className="justify-center h7">
										<input
											onChange={(e) => {
												setSelectedPlan(subPlan[e.target.value])
											}}
											type="radio"
											id="test3"
											value={2}
											name="subscribe"
										/>
										<label htmlFor="test3" />
									</Grid>
									<Grid item xs={2} className="justify-center h7">
										<input
											onChange={(e) => {
												setSelectedPlan(subPlan[e.target.value])
											}}
											type="radio"
											id="test4"
											value={3}
											name="subscribe"
										/>
										<label htmlFor="test4" />
									</Grid>
									<Grid item xs={2} className="justify-center h7">
										<input
											onChange={(e) => {
												setSelectedPlan(subPlan[e.target.value])
											}}
											type="radio"
											id="test5"
											value={4}
											name="subscribe"
										/>
										<label htmlFor="test5" />
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}
			{subscriptionModelOpen && (
				<SubscriptionInvoicingPopup
					open={subscriptionModelOpen}
					setOpen={setSubscriptionModelOpen}
				/>
			)}
		</SubscriptionSkin>
	)
}

export default Subscription
