import styled from '@emotion/styled/macro'
import { h5FontSize, h7FontSize } from 'appSkin'

export const DialogueBox = styled.div`
	padding: 4.5% 3.5% 6.5% 3.5%;
	background: #6a7ae9;
	color: #fff;
	.header {
		${h7FontSize};
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		.cross-icon {
			${h7FontSize};
			cursor: pointer;
		}
	}
	.description {
		${h5FontSize};
		font-weight: bold;
		text-align: center;
		margin-top: 23px;
	}
`
